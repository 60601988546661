<form autocomplete="off">
	<label>
		<span>First Name</span>
		<input type="text" name="first-name"
			[(ngModel)]="formFirstName"
			[ngModelOptions]="{ updateOn: 'blur' }"
			(blur)="blurTrim( 'firstName' )"
		/>
	</label>
	<label>
		<span>Last Name</span>
		<input type="text" name="last-name"
			[(ngModel)]="formLastName"
			[ngModelOptions]="{ updateOn: 'blur' }"
			(blur)="blurTrim( 'lastName' )"
		/>
	</label>
</form>
<div class="actions">
	<span class="button" (click)="submit();">{{ submitText }}</span>
</div>
