import {Component, EventEmitter, Input, Output} from '@angular/core';
//
@Component({
	selector: 'app-year-picker',
	templateUrl: './year-picker.html',
	styleUrls: [
		'./year-picker.less'
	]
})
export class ComponentYearPicker {
	//
	@Input()
	public year: number = new Date().getFullYear();
	//
	@Input()
	public minYear: number | undefined = undefined;
	//
	@Input()
	public maxYear: number | undefined = undefined;
	//
	@Output()
	public readonly yearChanged: EventEmitter<number> = new EventEmitter<number>();
	//
	public constructor() {
		//
	}

	public yearMinus(): void {
		if ( typeof this.minYear === 'number' ) {
			if ( this.year - 1 < this.minYear ) {
				return;
			}
		}
		--this.year;
		this.yearUpdated();
	}

	public yearPlus(): void {
		if ( typeof this.maxYear === 'number' ) {
			if ( this.year + 1 > this.maxYear ) {
				return;
			}
		}
		++this.year;
		this.yearUpdated();
	}

	public yearUpdated(): void {
		this.yearChanged.emit( this.year );
	}
}
