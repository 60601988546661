import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceSorting { // not sure if this qualifies as a Service file. Issue with naming conventions.
	//
	public constructor() {
		//
	}

	public static naturalSort( A: any, B: any ): number { // sorts ASC
		// .sort( (A, B) => A.localeCompare( B, undefined, { "numeric" : true, "sensitivity" : "base" } ) )
		// .data.price.sort( (A,B) => { return A.date === "default" ? -1 : (B.date === "default" ? -1 : (A.date.localeCompare( B.date, undefined, { "numeric" : true, "sensitivity" : "base" } ))) } )
		return String( A ).toLowerCase().localeCompare( String( B ).toLowerCase(), undefined, {
			numeric: true,
			sensitivity: 'base'
		} );
	}
}
