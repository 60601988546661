import {Component, EventEmitter, Input, Output} from '@angular/core';
// ===== Components ===== //
import {ComponentCheckBox} from '../check-box';
// ===== Interfaces ===== //
import {InterfaceCheckBoxActionLinkAction} from '../../../../interfaces/interfaces';
//
@Component( {
	selector: 'ui-check-box-action-link',
	templateUrl: './action-linked.html',
	styleUrls: [
		'./action-linked.less'
	]
} )
export class ComponentCheckBoxActionLink extends ComponentCheckBox {
	@Input()
	public actions: InterfaceCheckBoxActionLinkAction[] = [];
	//
	@Output()
	public actioned: EventEmitter<number> = new EventEmitter<number>();
	//
	public readonly nbsp: string = String.fromCharCode( 160 ); // \u00A0
	//
	public constructor() {
		super();
		this.text = '';
	}

	public linkActivated( idx: number ): void {
		this.actioned.emit( idx );
	}
}
