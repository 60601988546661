// ===== Interfaces ===== //
import {InterfaceOWAPICardVault} from './card-vault';
import {
	InterfaceEventPassAvailability,
	InterfaceOWDailyAdmissionAvailability, // TODO: make the v1 availability obsolete.
	InterfaceOWSeasonPassAdmissionAvailability
} from '../ryst/event-capacity';
import {InterfaceOWDoclet} from './doclets';
import {
	InterfaceOWAPIOrderResponseData,
	InterfaceOWAPIPromoCodeItems,
	InterfaceOWOrder
} from './orders';
import {InterfaceMemberEntitlements} from '../ryst/entitlement';
import {
	InterfaceOWDocletWithEntitlement,
	InterfaceOWTemplateEventPassPriceV3
} from '../ryst/event-passes';
import {
	InterfaceOWCancelledReservationItems,
	InterfaceOWReservedItem
} from '../ryst/reservations';
import {InterfaceOWTemplate} from './templates';
import {InterfaceOWUser} from './user';
import {
	InterfaceOWWeaveConfig,
	InterfaceOWWeaveV2,
	InterfaceOWWeaveWeaves
} from './weaves';
import {
	InterfaceOWWorkspaceActionList,
	InterfaceOWWorkspaceEntry
} from './workspaces';
import {InterfaceAnyObject} from "../angular/vanilla-js";
//
export interface InterfaceOWUserAuth { // data struct acquired when logging in, or fetching the users profile.
	// the active workspace, and the workspace map, ought to be on the user, not above it at this level...
	active_workspace_id: string | null; // string? ObjectID?
	workspace_map: InterfaceOWWorkspaceEntry[];
	auth_key: string;
	user: InterfaceOWUser;
}

interface InterfaceOWAPIResponseUserInfoIDs { // the data-fields returned when signing up.
	account_id: string; // (ow_user)db.user_auth_profiles.findOne( { "user_profile_id" : ObjectId( account_id ) } );
	doclet_id: string; // (omniweaver)db.doclets.findOne( { "_id" : ObjectId( doclet_id ) } );
	realm_id: string; // (omniweaver)db.realms.findOne( { "_id" : ObjectId( realm_id ) } );
	profile_id: string; // (ow_user)db.user_profiles.findOne( { "_id" : ObjectId( profile_id ) } );
}

// ==================== //
// ===== Core API ===== //
// ==================== //
interface InterfaceOWAPIResponseMessagesContext { // (apiResponse).messages.context
	path: string;
	path_params: {}; // probably key-value-pairs
	query_params: {};
	request_headers: {
		[header: string]: string;
	};
}

interface InterfaceOWAPIResponseMessagesErrors { // (apiResponse).messages.errors
	target: string; // 'login'
	message: string; // 'invalid user id or password'
}

export interface InterfaceOWAPIResponseMessages { // (apiResponse).messages
	context?: InterfaceOWAPIResponseMessagesContext;
	errors: InterfaceOWAPIResponseMessagesErrors[] | any[];
	info: any[];
	warnings: any[];
}

export interface InterfaceOWAPIResponseMeta { // (apiResponse).meta
	time_processed: string; // date // 2023-Mar-14 22:36:02
}

export interface InterfaceOWAPIResponse { // the Core OW API response. the API always returns this structure.
	messages: InterfaceOWAPIResponseMessages;
	meta: InterfaceOWAPIResponseMeta;
	status: number;
	ts: string; // date // 2023-Mar-14 22:36:02
	data: any;
}

export interface InterfaceOWAPIPaginationData { // modules with pagination, have these extra fields in their {data:{}}
	items: any[];
	total: number; // total records in the data-set, not just how many were returned.
	limit: number; // capped at 100. if you send 999, server-side will ignore your number and return 100.
	next: string; // a "magic" ID specific to server-side logic. does not match an ._id from doclets, weaves, templates, etc.
	prev: string; // same as "next"
	sort: string; // the key to sort upon, i think. need examples...
	sort_dir: 1 | -1; // 1: ASC; -1: DESC;
	have_more: boolean;
} // this interface is for the response, not the request... // TODO: rename this.

export interface InterfaceOWAPIPaginationRequest {
	cursorPrev?: string;
	cursorNext?: string;
	sortKey?: string; // sortKey and sortDir are needed, but not when next or prev is supplied.
	sortDir?: 1 | 'ASC' | -1 | 'DESC';
	limit: number;
} // this interface is for the request, not the response.

export interface InterfaceOWAPIBulkRecordRequest<RecordType> {
	success: boolean;
	records: RecordType[];
}

// ================================== //
// ===== Fetch Records, By Type ===== //
// ================================== //
export interface InterfaceOWAPIResponsePaginatedData<RecordType> extends InterfaceOWAPIPaginationData {
	items: RecordType[];
}

export interface InterfaceOWAPIResponseData_T<RecordType> {
	data: {
		items: RecordType[];
	}
}

export interface InterfaceOWAPIGetDocletsResponse extends InterfaceOWAPIResponse { // get doclets
	data: InterfaceOWAPIResponsePaginatedData<InterfaceOWDoclet>;
}

export interface InterfaceOWAPIGetTemplatesResponse extends InterfaceOWAPIResponse { // get templates
	data: InterfaceOWAPIResponsePaginatedData<InterfaceOWTemplate>
}

export interface InterfaceOWAPIGetWeavesResponse extends InterfaceOWAPIResponse { // get weaves
	data: {
		items: InterfaceOWWeaveV2[]; // weaves changed to be an array of potentially many items now.
		// items: InterfaceOWWeave[]; // it's either an array of length zero, or one.
	}
}

export interface InterfaceOWAPIGetWeaveConfigResponse extends InterfaceOWAPIResponse { // get weave config
	data: {
		items: InterfaceOWWeaveConfig[];
	};
}

export interface InterfaceOWAPIGetEventPassActionResponse extends InterfaceOWAPIResponse {
	data: { // this returns an array of ordinary doclets (Event Passes) that are augmented with a new top level field containing the Entitlement Type.
		items: InterfaceOWAPIResponsePaginatedData<InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventPassPriceV3>>;
	}
}

export interface InterfaceOWAPIGetMemberEntitlementsActionResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceMemberEntitlements[];
	};
}

export interface InterfaceOWAPIPaginatedResponse_T<RecordType> {
	data: InterfaceOWAPIResponsePaginatedData<RecordType>;
}

// =================================================== //
// ===== Fetch A Record, Just One, Usually By ID ===== //
// =================================================== //
export interface InterfaceOWAPIGetRecordResponse_T<RecordType> {
	data: RecordType;
}

export interface InterfaceOWAPIGetUserProfileResponse extends InterfaceOWAPIResponse { // fetch profile
	data: InterfaceOWUser;
}

export interface InterfaceOWAPIGetDocletResponse<T = InterfaceAnyObject> extends InterfaceOWAPIResponse { // fetch doclet by ID
	data: InterfaceOWDoclet<T>; // if you passed in an array of doclet IDs to get doclet by ID...
} // then the response type for data is actually InterfaceOWAPIGetDocletsResponse  (apiResponse.data.data.items[]) vs (apiResponse.data.{doclet})

export interface InterfaceOWAPIGetTemplateResponse extends InterfaceOWAPIResponse { // fetch template
	data: InterfaceOWTemplate;
}

// =========================== //
// ===== Create A Record ===== //
// =========================== //
export interface InterfaceOWAPICreateDocletResponse extends InterfaceOWAPIResponse { // create doclet
	data: {
		doclet_id: string;
	};
}

// TODO: create template (response)

export interface InterfaceOWAPICreateWeaveResponse extends InterfaceOWAPIResponse { // create weave
	data: {
		items: InterfaceOWWeaveWeaves[]; // not the weave document, but only the weave-data normally found inside .weaves[]
	};
}

// ============================= //
// ===== User Accounts ===== //
// ============================= //
export interface InterfaceOWAPISignInResponse extends InterfaceOWAPIResponse { // sign-in
	data: InterfaceOWUserAuth;
}

export interface InterfaceOWAPISignUpResponse extends InterfaceOWAPIResponse { // sign-up
	data: InterfaceOWAPIResponseUserInfoIDs;
}

export interface InterfaceOWAPIInviteUserResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWAPIResponseUserInfoIDs[];
	};
}

// ============================ //
// ===== Workspace Things ===== //
// ============================ //
export interface InterfaceOWAPIWorkspaceActionsListResponse extends InterfaceOWAPIResponse { // the result of getActions()
	data: {
		items: InterfaceOWWorkspaceActionList[];
	};
}

// ======================= //
// ===== Custom Junk ===== //
// ======================= //
export interface InterfaceOWAPISeasonPassAdmissionAvailabilityResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWSeasonPassAdmissionAvailability[]; // an array of length 1 or 2.
		/*
		items: [
			InterfaceOWDailyCapacity,
			{ 'max_capacity': boolean } // this only exists when 'max_capacity' is true.
		];
		*/
	};
}

export interface InterfaceOWAPIDailyAdmissionAvailabilityResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWDailyAdmissionAvailability[]; // an array of length 1.
	};
}

export interface InterfaceOWAPIDailyAdmissionAvailabilityResponseV2 extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceEventPassAvailability[];
	}; // items is an array whose length is always 1.
}

export interface InterfaceOWAPIReserveItemResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWReservedItem[]; // an array of length 1.
	};
}

export interface InterfaceOWAPICancelledReservationItemsResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWCancelledReservationItems[]; // an array of length 1.
	};
}

export interface InterfaceOWAPIOrderResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWAPIOrderResponseData[]; // an array of length 1.
	};
}

export interface InterfaceOWAPICreatePlaceholderOrder extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWOrder[]; // an array of length 1.
	};
}

export interface InterfaceOWAPIPromoCodeResponse extends InterfaceOWAPIResponse {
	data: {
		items: InterfaceOWAPIPromoCodeItems[];
	};
}

export interface InterfaceOWAPICardVaultResponse extends InterfaceOWAPIResponse { // setting or getting a card vault.
	data: {
		items: InterfaceOWAPICardVault[];
	};
}
