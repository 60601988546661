export interface InterfaceOWTemplateTransaction {
	action: string;
	billing_address: string;
	billing_zip: string;
	card_number: string;
	card_exp_month: string;
	card_exp_year: string;
	amount_base: string; // sub-total? TODO: what is this for?
	card_cvv: string;
}
