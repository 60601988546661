<div class="calendar-wrapper">
	<div
		[ngClass]="{
			'calendar-header': true
		}"
	>
		<span
			[ngClass]="{
				'button': true,
				'disabled': noGoingBack,
				'prev': true
			}"
			(click)="prevReservationCalendarMonth();"
		><i class="fa-solid fa-chevron-left"></i></span>
		<span class="title">{{ monthLabels[selectedMonth1 - 1] }}&nbsp;{{ selectedYear }}</span>
		<span
			[ngClass]="{
				'button': true,
				'disabled': noGoingForward,
				'next': true
			}"
			(click)="nextReservationCalendarMonth();"
		><i class="fa-solid fa-chevron-right"></i></span>
	</div>
	<div
		[ngClass]="{
			'calendar-body': true
		}"
	>
		<ul class="row labels">
			<li>Sun</li>
			<li>Mon</li>
			<li>Tue</li>
			<li>Wed</li>
			<li>Thu</li>
			<li>Fri</li>
			<li>Sat</li>
		</ul>
		<ul class="calendar">
			<li class="offset" *ngFor="let day of leadingBlanks;"></li>
			<li class="day"
				*ngFor="let day of daysInMonth; let i = index;"
				[ngClass]="{
					'selected': selectedDay === i + 1,
					'is-weekend': (leadingBlanks.length + i) % 7 === 0 || (leadingBlanks.length + i) % 7 === 6,
					'is-boundary-start': boundaryDateStart ? (selectedYear * 10000 + selectedMonth1 * 100 + i === boundaryDateStart) : false,
					'is-boundary-end': boundaryDateEnd ? (selectedYear * 10000 + selectedMonth1 * 100 + i + 1 === boundaryDateEnd) : false,
					'blocked': (boundaryDateStart ? (selectedYear * 10000 + selectedMonth1 * 100 + i + 1 < boundaryDateStart) : false)
					|| (boundaryDateEnd ? (selectedYear * 10000 + selectedMonth1 * 100 + i + 2 > boundaryDateEnd) : false)
				}"
				(click)="setSelectedDay( i + 1 );"
			><b>{{ i + 1 }}</b></li>
			<li class="remainder" *ngFor="let day of trailingBlanks;"></li>
		</ul>
	</div>
</div>
