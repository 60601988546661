// ===== Interfaces ===== //
import {InterfaceOWDoclet} from '../ow/doclets';
//
export interface InterfaceOWTemplateParkVisit {
	date: string;
	note?: string; // the reason for a cancellation.
	status: 'active' | 'canceled';
	is_a_weekend_visit: boolean;
}

export interface InterfaceParkVisitData {
	date: string;
}

export interface InterfaceParkVisitErrors {
	date: boolean;
}

/*
{
	"_id" : ObjectId("629a727529e52941862f4cc3"),
	"name" : "Park Visit",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"realms" : [
		ObjectId("6222b4b8b41f06d49ea56fc6")
	],
	"fields" : [
		{
			"key" : "date",
			"label" : "Date",
			"sort" : 0,
			"type" : "string"
		},
		{
			"label" : "Is A Weekend Visit",
			"key" : "is_a_weekend_visit",
			"sort" : 1,
			"type" : "boolean"
		}
	]
}
*/

export interface InterfaceEventReservation { // see InterfaceScannedTicket
	park_visits: { // they're all for the same day, or should be...
		cabana: InterfaceOWDoclet[];
		consumer_tickets: {
			//consumer: InterfaceOWDoclet;
			ticket: InterfaceOWDoclet;
		}[];
		parking: InterfaceOWDoclet[];
		reservation: InterfaceOWDoclet[];
	}[];
	scanned_ticket_type: string; // will be blank, if the ticket is invalid, or not a ticket, etc.
	season_pass_holder: boolean;
	valid_entry: boolean;
	// the fields below don't appear unless the ticket/device/whatever exists.
	consumer?: InterfaceOWDoclet; // Season Pass Holder info only. if it is not a SPH ticket, this field is missing.
	device_id?: string; // if the ticket is linked to a wristband, this will have a value, or will be missing.
	scanned_ticket?: InterfaceOWDoclet; // what you used to get here...
	visit_date?: string; // YYYY-MM-DD, and MM is 01 to 12.
}

export interface InterfaceUnassignedWristbandResponse {
	unassigned_device: boolean;
}

export interface InterfaceScannedTicket { // see InterfaceEventReservation
	// this entire data structure is created on the fly, by the POS landing page. it does not come from the OW API.
	consumer: InterfaceOWDoclet | undefined; // this won't exist unless it was a season pass holder's ticket or similar that was scanned.
	device_id?: string; // won't exist, unless the band is linked
	ticket: InterfaceOWDoclet;
	ticketFlags: {
		status: string;
		isLinked: boolean;
		isLinkable: boolean;
		isAssigned: boolean;
		isProfileLocked: boolean; // becomes true when somebody on the POS approves an SPH person's info (photo, first/last name, DOB)
		needsFirstLastName: boolean;
		hasFirstLastName: boolean;
		needsDoB: boolean;
		hasDoB: boolean;
		isSeasonPass: boolean;
		isSAP: boolean;
		isSPP: boolean;
		isDailyAdmission: boolean;
		isDailyParking: boolean;
		isCabana: boolean;
		cabanaColor?: string;
		cabanaLocationID?: string;
		isDateForToday: boolean;
		SAPLevel?: number | false; // 1, 2, 3 // silver, gold, diamond. false when it cannot be determined.
	};
	isSPH: boolean; // is a season pass holder (controls photo & profile locking, disables order look up by ticket ID, etc.)
	isValidEntry: boolean;
	visitDate: string; // YYYY-MM-DD
	visits: { // a list of everything going on that day, relating to the ticket.
		admissions: InterfaceOWDoclet[]; // (adult/junior) Daily Admission Ticket, Event Visit
		cabanas: InterfaceOWDoclet[]; // any of the 6 cabana ticket template types.
		parking: InterfaceOWDoclet[]; // the single Parking Ticket template type.
		reservations: InterfaceOWDoclet[]; // Park Visit
	}[];
}
