<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
></app-masthead>
<div class="wrapper">
	<section class="card callout" *ngIf="haveAccountInfo && recycleTB && !primaryAccountHolder.cashlessSpending">
		<span class="icon"><i class="fa-solid fa-nfc-symbol"></i></span>
		<div class="callout-body">
			<b>Cashless Spending</b>
			<p>Activate in venue cashless spending by safely storing your credit card. <span class="bold">No need to reach for your wallet or your smartphone</span>.
				Simply tap your venue issued wristband to make any purchase throughout the venue.</p>
			<ul class="button-strip">
				<li class="button black inline" (click)="cashlessToggle( !primaryAccountHolder.cashlessSpending );">Activate
					Now
				</li>
			</ul>
		</div>
	</section>
	<!--
	<section class="card">
		<h3>Reservations</h3>
		<p class="padding-bottom-20">{{ copyMap['reservationDescription'] }}</p>
		<ul class="button-strip">
			<li class="button inline"><a [routerLink]="[ '/' + routes.tickets ]">Get Tickets</a></li>
			<li class="button inline ghost disabled" (click)="showReservationForm();"><i class="fa-solid fa-lock"></i> New Reservation</li>
		</ul>
	</section>
	-->
	<section class="card past-visits">
		<div class="column">
			<h3>My Passes</h3>
			<div class="fetching-tickets" *ngIf="!consumerEntitlementsLoaded">Fetching your tickets...</div>
			<ng-container *ngIf="consumerEntitlementsLoaded">
				<ul class="visit-list" *ngFor="let eventID of memberEntitlementsByEventDateMember.eventIDs;">
					<li class="meta" *ngIf="memberEntitlementsByEventDateMember.eventData[eventID].visitDates.length < 1">You have no recent visit history.</li>
					<li class="visit-date-wrapper" *ngFor="let visitDate of memberEntitlementsByEventDateMember.eventData[eventID].visitDates;">
						<span class="visit-masthead">
							<div class="visit-photo">
								<img alt="" src="assets/img/earthstar_logo_white.png" />
							</div>
							<span class="visit-header">
								<b>{{ eventDocletCache[eventID] ? eventDocletCache[eventID].data.name : 'Event' }}</b>
								<span>{{ 'October 25-27, 2024' }} | {{ 'Joshua Tree, California' }}</span>
							</span>
							<ul class="visit-actions">
								<li class="date" *ngIf="visitDate !== 'event'">
									<span>{{ visitDate | dateFromYYYYMM1DD : 'short-month' : monthLabels }}</span>
									<b>{{ visitDate | dateFromYYYYMM1DD : 'day' }}</b>
								</li>
								<li class="double-date" *ngIf="visitDate === 'event'">
									<span class="date">
										<span>{{ eventDocletCache[eventID] ? (eventDocletCache[eventID].data.start_date | dateFromYYYYMM1DD : 'short-month' : monthLabels) : '***' }}</span>
										<b>{{ eventDocletCache[eventID] ? (eventDocletCache[eventID].data.start_date | dateFromYYYYMM1DD : 'day') : '**' }}</b>
									</span>
									<em>–</em>
									<span class="date">
										<span>{{ eventDocletCache[eventID] ? (eventDocletCache[eventID].data.stop_date | dateFromYYYYMM1DD :'short-month' : monthLabels) : '***' }}</span>
										<b>{{ eventDocletCache[eventID] ? (eventDocletCache[eventID].data.stop_date | dateFromYYYYMM1DD : 'day') : '**' }}</b>
									</span>
								</li>
								<li class="action"
									(click)="initQRCodeDisplay( memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate] );"
								><i class="fa-light fa-qrcode"></i></li>
							</ul>
						</span>
						<ul class="visit-details">
							<ng-container *ngFor="let consumerID of memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerIDs;">
								<li class="visit-codes mobile-only">
									<ul class="code-swiper">
										<li *ngFor="let admissionQRCode of memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].admissions;">
											<qrcode
												[errorCorrectionLevel]="qrCorrectionLevel"
												[margin]="0"
												[qrdata]="admissionQRCode._id.toString()"
												[width]="600"
											></qrcode>
											<div class="visit-item-details">
												<b>{{ (memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.first_name ?? '') + ' ' + (memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.last_name ?? '') }}</b>
												<span>{{ admissionQRCode.data.name }}</span>
											</div>
										</li>
									</ul>
								</li>
								<li class="visit-item no-mobile"
									*ngFor="let admissionQRCode of memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].admissions;"
								>
									<div class="visit-item-photo">
										<span class="no-photo"
											*ngIf="!memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.photo"
										>{{ (memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.first_name ?? '') + ' ' + (memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.last_name ?? '') | initials }}</span>
										<img alt=""
											*ngIf="memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.photo"
											[src]="memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.photo"
										/>
									</div>
									<div class="visit-item-details">
										<b>{{ (memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.first_name ?? '') + ' ' + (memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].consumerData[consumerID].consumer.data.last_name ?? '') }}</b>
										<span>{{ admissionQRCode.data.name }}</span>
									</div>
								</li>
							</ng-container>
							<li class="shared-entitlement visit-item"
								*ngFor="let entitlement of memberEntitlementsByEventDateMember.eventData[eventID].visitData[visitDate].sharedEntitlements"
							>
								<div class="visit-item-photo">
									<i class="fa-solid fa-plus"></i>
								</div>
								<div class="visit-item-details">
									<b>{{ entitlement.data.type }}</b>
									<span>{{ entitlement.data.date | dateFromYYYYMM1DD : 'event-m/d/y-m/d/y' : eventDocletCache[ entitlement.data.event_id.$oid ] }}</span>
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</ng-container>
		</div>
	</section>
</div>

<div
	[ngClass]="{
		'modal-overlay': true,
		'active': activeQR
	}"
>
	<div class="modal card modal-qrcode">
		<span class="qr-title">{{ activeQRTitle }}&nbsp;</span>
		<qrcode
			*ngIf="activeQR"
			[qrdata]="activeQR"
			[width]="400"
			[margin]="0"
			[errorCorrectionLevel]="qrCorrectionLevel"
		></qrcode>
		<span style="display: none;">{{ activeQR }}</span>
		<span class="button" (click)="hideQRCode();">Close</span>
	</div>
</div>

<div id="viewing-group-qr-codes"
	[ngClass]="{
		'modal-overlay': true,
		'active': viewingAdmissionQRCodes
	}"
>
	<div class="modal card group-qr-codes">
		<div class="overflow-wrapper">
			<div
				*ngIf="viewingAdmissionQRCodes"
				[ngClass]="{
					'qr-member-listing': true,
					'need-to-swipe': admissionQRCodesByConsumer.needToSwipe
				}"
			>
				<ng-container *ngFor="let consumerID of admissionQRCodesByConsumer.consumerIDs;">
					<span class="member-details" *ngFor="let admissionQRDoclet of admissionQRCodesByConsumer.consumerData[consumerID].admissions;">
						<span class="qr-title">{{ admissionQRDoclet.data.name }}</span>
						<qrcode
							[errorCorrectionLevel]="qrCorrectionLevel"
							[margin]="0"
							[qrdata]="buildAdmissionQRCodeData( admissionQRDoclet )"
							[width]="400"
						></qrcode>
						<span class="name">{{ admissionQRCodesByConsumer.consumerData[consumerID].consumer.data.first_name ?? '' }}&nbsp;{{ admissionQRCodesByConsumer.consumerData[consumerID].consumer.data.last_name ?? '' }}</span>
						<span style="display: none;">{{ buildAdmissionQRCodeData( admissionQRDoclet ) }}</span>
					</span>
				</ng-container>
			</div>
		</div>
		<div class="actions">
			<span class="action button" (click)="viewingAdmissionQRCodes = false;">Close</span>
		</div>
	</div>
</div>
