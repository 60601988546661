// ===== Components - Common ===== //
import {ComponentCheckBox} from './inputs/check-box/check-box';
import {ComponentCheckBoxActionLink} from './inputs/check-box/action-linked/action-linked';
import {ComponentCheckBoxLiabilityWaiver} from './inputs/check-box/action-linked/liability-waiver/liability-waiver';
import {ComponentCheckBoxPrivacyPolicy} from './inputs/check-box/action-linked/privacy-policy/privacy-policy';
import {ComponentCheckBoxTermsOfUse} from './inputs/check-box/action-linked/terms-of-use/terms-of-use';
import {ComponentDropDown} from './inputs/drop-down/drop-down';
import {ComponentLanguageDropDown} from './inputs/drop-down/language-drop-down/language-drop-down';
import {ComponentTimeZoneDropDown} from './inputs/drop-down/time-zone-drop-down/time-zone-drop-down';
import {ComponentInput} from './inputs/input/input';
import {ComponentLabeledInput} from './inputs/input/labeled-input/labeled-input';
import {ComponentRadioGroup} from './inputs/radio-group/radio-group';
import {ComponentToggleBox} from './inputs/toggle-box/toggle-box';
import {ComponentNavHorizontal} from './menus/nav-horizontal/nav-horizontal'
import {ComponentNavVertical} from './menus/nav-vertical/nav-vertical';
import {ComponentNavFrame} from './nav-frame/nav-frame';
import {ComponentNotifications} from './notifications/notifications';
// ===== Components - POS //
import {ComponentAvailabilityDatePicker} from './pos/date-picker/availability-date-picker/availability-date-picker';
import {ComponentDatePicker} from './pos/date-picker/date-picker';
import {ComponentDigitPicker} from './pos/digit-picker/digit-picker';
import {ComponentDOBPicker} from './pos/dob-picker/dob-picker';
import {ComponentFirstLastName} from './pos/first-last-name/first-last-name';
import {ComponentLocationSelector} from './pos/location-selector/location-selector';
import {ComponentManualCC} from './pos/manual-cc/manual-cc';
import {ComponentOrderHistory} from './pos/order-history/order-history';
import {ComponentTimePicker} from './pos/time-picker/time-picker';
import {ComponentYearPicker} from './pos/year-picker/year-picker';
// ===== Modules ===== //
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ModulePipes} from '../pipes/pipes.module';
//
const components: any[] = [
	ComponentAvailabilityDatePicker,
	ComponentCheckBox,
	ComponentCheckBoxActionLink,
	ComponentCheckBoxLiabilityWaiver,
	ComponentCheckBoxPrivacyPolicy,
	ComponentCheckBoxTermsOfUse,
	ComponentDatePicker,
	ComponentDigitPicker,
	ComponentDOBPicker,
	ComponentDropDown,
	ComponentFirstLastName,
	ComponentInput,
	ComponentLabeledInput,
	ComponentLanguageDropDown,
	ComponentLocationSelector,
	ComponentManualCC,
	ComponentNavHorizontal,
	ComponentNavVertical,
	ComponentNavFrame,
	ComponentNotifications,
	ComponentOrderHistory,
	ComponentRadioGroup,
	ComponentTimePicker,
	ComponentTimeZoneDropDown,
	ComponentToggleBox,
	ComponentYearPicker
];
@NgModule( {
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ModulePipes
	],
	providers: []
} )
export class ModuleVPComponents {}
