export interface InterfaceOWTemplateVenueLocation {
	// venue locations are the physical address. they're generally not the sub-sections with-in an amusement park, but the address of the park itself.
	name: string;
	street_address: string;
	city: string;
	state: string;
	zip: string;
	latitude: number;
	longitude: number;
	status: 'active' | 'hidden';
}

// TODO: venue sub-sections. (disneyland has sub-sections such as Tomorrowland, Adventureland, Frontierland, etc.

export interface InterfaceOWTemplateVenueEvent {
	// events are held at a venue. they may exist with-in a sub-section instead...
	name: string;
	start_date: string;
	start_time: string;
	stop_date: string;
	stop_time: string;
	latitude: number;
	longitude: number;
	status: 'active' | 'hidden';
}

// TODO: sub-sections' section. (exhibits, ticket gates? entry points?)
