<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
	[serialCode]="serialCode"
></app-masthead>
<div id="page-edit-family" class="page wrapper">
	<div class="note-profile-locked"
		*ngIf="memberDoclet && memberDoclet._id.$oid && !allowedToEdit"
	>This profile has been approved and can no longer be edited.</div>
	<div class="card columned">
		<div class="column form">
			<h3>Editing {{ editingNameHere }}</h3>
			<label>
				<span class="frame-label">First Name <b>*</b></span>
				<input type="text" autocomplete="off"
					[readonly]="!allowedToEdit"
					[class.error]="familyMemberErrors.firstName"
					[(ngModel)]="familyMemberData.firstName"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateData( 'firstName' ); checkFormForErrors();"
				/>
			</label>
			<label>
				<span class="frame-label">Last Name <b>*</b></span>
				<input type="text" autocomplete="off"
					[readonly]="!allowedToEdit"
					[class.error]="familyMemberErrors.lastName"
					[(ngModel)]="familyMemberData.lastName"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateData( 'lastName' ); checkFormForErrors();"
				/>
			</label>
			<hr />
			<label>
				<span class="frame-label">Date of Birth <b>*</b></span>
				<input type="date" autocomplete="off"
					[readonly]="!allowedToEdit"
					[class.error]="familyMemberErrors.dob"
					[(ngModel)]="familyMemberData.dob"
					[ngModelOptions]="{ updateOn: 'change' }"
					(ngModelChange)="validateData( 'dob' ); checkFormForErrors();"
				/>
			</label>
			<hr />
			<span class="button save"
				*ngIf="allowedToEdit"
				[ngClass]="{
					'disabled': formHasErrors || familyMemberData.photo.length < 1,
					'busy': this.formBusy
				}"
				(click)="updateFamilyMember();"
			>Save Changes<i class="busy-icon fa-solid fa-spin fa-circle-notch"></i></span>
		</div>
		<div class="column">
			<h3>Photo ID</h3>
			<div class="photo-id">
				<div class="photo-id-wrapper">
					<image-cropper
						[ngStyle]="{
							'display': allowedToEdit && usingImageCropper ? 'block' : 'none'
						}"
						[imageChangedEvent]="imageChangedEvent"
						(imageCropped)="imageCropperOnChange( $event )"
						(imageLoaded)="imageCropperOnImageLoad( $event )"
						(cropperReady)="cropperReady()"
						(loadImageFailed)="loadImageFailed()"
						[format]="'jpeg'"
						[maintainAspectRatio]="true"
						[aspectRatio]="16 / 16"
						[cropperMinWidth]="150"
						[cropperMinHeight]="150"
						[cropperMaxHeight]="1200"
						[cropperMaxWidth]="1200"
						[imageQuality]="95"
						[alignImage]="'center'"
						[backgroundColor]="'#FFFFFF'"
					></image-cropper>
					<span class="placeholder" *ngIf="!usingImageCropper && familyMemberData.photo.length < 1"><i class="fa-solid fa-user-astronaut"></i></span>
					<span class="profile-picture" *ngIf="!usingImageCropper && familyMemberData.photo.length > 0"><img alt="" [src]="this.familyMemberData.photo" /></span>
				</div>
				<label class="button grey upload file-picker"
					*ngIf="allowedToEdit && !usingImageCropper"
					[ngClass]="{
						'error': familyMemberErrors.photo || familyMemberData.photo.length < 1
					}"
				>
					<input id="file-picker-profile-photo" type="file" accept="image/*" (change)="inputTypeFileOnChange( $event );" />
					<span class="button-display" (click)="clearPictureError();">Upload New Photo</span>
				</label>
				<label class="button green" *ngIf="allowedToEdit && usingImageCropper" (click)="setFamilyMemberPhoto();">
					<span class="button-display">Crop &amp; Save Photo</span>
				</label>
				<label class="button text-only" *ngIf="usingImageCropper" (click)="clearImageCropper();">
					<span class="button-display text-only">Cancel Upload</span>
				</label>
			</div>
		</div>
	</div>
</div>
