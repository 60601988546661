// ===== Interfaces ===== //
import {InterfaceObjectId} from '../ow/mongo/object-id';
//
export interface InterfaceOWTemplateCapacitySettingsCapacityByDateCapacityRoles {
	capacity: number;
	seating?: string[];
	role_id: InterfaceObjectId;
}

export interface InterfaceOWTemplateCapacitySettingsCapacityByDate {
	capacity: number;
	capacity_roles?: InterfaceOWTemplateCapacitySettingsCapacityByDateCapacityRoles[];
	date: string;
	seating?: string[];
}

export interface InterfaceOWTemplateCapacitySettings {
	capacity_by_date: InterfaceOWTemplateCapacitySettingsCapacityByDate[];
}
