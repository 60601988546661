// ===== Interfaces ===== //
import {InterfaceObjectDate} from './mongo/object-date';

export interface InterfaceOWOrder {
	order_id: string;
}

interface commonFieldsOnOrderResponse extends InterfaceOWOrder {
	success: boolean; // only rely upon this key, and if it is false, look for a "payment_reason".
}

interface InterfaceOWAPIOrderResponseWhenThingsGoWrong extends commonFieldsOnOrderResponse { // payment reason isn't on here.
	// this interface shouldn't be used. it exists because the server-side outputs this data (on error) for debug purposes.
	status: 'error' | string;
	phrase: string;
	code: number; // HTTP Status Code
	start_t: {
		$numberLong: number;
	};
	elapse_t: {
		$numberLong: number; // units in milliseconds?
	};
	headers: {
		[key: string]: string;
	};
	body: string; // JSON string
}

export interface InterfaceOWAPIOrderResponseData extends commonFieldsOnOrderResponse { // this is the normal response.
	payment_status: string; // 'declined' | 'approved'. always lowercase...
	payment_status_code: string; // 'AX' ...
	payment_reason: string; // Declined (AVS, no match address and zip),
	captcha_status?: boolean;
	unavailable?: string[]; // [ 'itemID1', 'itemID2' ]
}

export interface InterfaceOWAPIManualCardTransactionResponse { // see also InterfaceOWAPIOrderResponseData
	approval_code?: string; // only exists when approved.
	order_id: string;
	payment_reason: string;
	payment_status: string;
	payment_status_code: string;
	success: boolean;
	transaction_id: string;
}

export interface InterfaceOWAPIOrderDocletData {
	order_date: InterfaceObjectDate;
	status: 'pending' | 'completed' | string; // i think this is about charging the card rather than what the order is up to...
}

export interface InterfaceOWAPIOrderItems { // when submitting an order, these are the items to buy.
	date: 'any' | 'event' | string; // 'any' | YYYY-MM-DD
	items: {
		doclet_id?: string; // the pass's ID i want to buy. but if this is a refunded item, don't send this.
		doclet_id_from?: string; // upgrades - the old pass that was used to buy the ticket being upgraded.
		event_id: string;
		assigned_to_self?: boolean; // only primary entitlements should have this. not add-ons.
		item_id: string | null; // the held ticket.
		item_id_from?: string | null; // upgrades - the old ticket ID that is currently being replaced.
		type?: 'credit' | 'upgrade', // if you're trying to refund an item, send this field in as "type" : "credit"
		original_price?: number; // the price to charge for the item to buy.... i forget why it's called "original" price
		source?: 'pos' | 'web' | string; // the type of pricing used, really. not so much where it was sold from.
		data: {
			assigned_first_name?: string;
			assigned_last_name?: string;
			assigned_dob?: string;
			time?: string; // HH:MM:SS in 00-23 hour format, for when the ticket is allowed to be activated after that time (during whatever day)
			location_id?: string;
			note?: string;
		};
	}[];
}

export interface InterfaceOWAPIOrderPaymentInfo {
	action: 'CHARGE' | 'REFUND' | string;
	billing_address: string;
	billing_suite: string;
	billing_city: string;
	billing_state: string;
	billing_zip: string;
	card_number: string;
	card_exp_month: string;
	card_exp_year: string;
	card_cvv: string;
}

export interface InterfaceOWAPIPromoCodeItemsItem {
	discount_applied: string; // "10.00" // how much to take away from the original price
	discount_type: 'currency' | 'percent' | string;
	doclet_id: string; // the pass ID, not the ticket a purchased pass creates...
	item_id: string | null; // (record)._id.$oid
	item_price_discounted: string; // "50.00" // the new price tag for the item
	item_price_original: number;
}

export interface InterfaceOWAPIPromoCodeItems {
	error?: string; // if it is an invalid code, it's just an object with this one property.
	date: string;
	items: InterfaceOWAPIPromoCodeItemsItem[];
}
