import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIRealmRealms} from './realm/realms';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIRealm {
	private readonly routePrefix: string = 'realm/';
	//
	public constructor(
		public readonly realms: ServiceOWAPIRealmRealms
	) {
		//
	}
}
