import {Injectable} from '@angular/core';
// ===== Config ===== //
import {BaseConfig} from '../../configs/base-config';
//
@Injectable( {
	providedIn: 'root'
} )
export class AppConfig extends BaseConfig {
	private readonly gtagAW: string = 'AW-'; // hard-coded GTagID exist, find them.
	public constructor() {
		super();
	}

	public getGTagID(): string {
		return this.gtagAW;
	}

	public getAppShortName(): string {
		return this.appShortName;
	}

	public getRealmID( byName?: string ): string {
		if ( byName ) {
			if ( !( byName in this.realmIDs ) ) {
				console.log( 'Missing ID for realm:', byName );
			}
			return this.realmIDs[ byName ] ?? '';
		}
		return this.realmIDs['Portal'];
	}

	public getWorkspaceID(): string {
		return this.workspaceID;
	}

	public getRoleID( name: string ): string {
		return this.roleIDs[name] ?? '';
	}

	public getSettingsID( name: string ): string {
		return this.settingsIDs[name] ?? '';
	}

	public getReCaptchaSiteKey(): string {
		return this.reCaptchaSiteKey;
	}
}
