export interface InterfaceOWFileAsset { // the result of a file upload into /edge/assets/upload
	file_id: string;
	file_name: string;
	file_size: number;
	meta_type: string; // "image/jpeg"
}

export interface InterfaceOWFileBase64 { // the result of /edge/assets/file/<file_id>
	image_data: string;
}

export interface InterfaceOWFileUploadResponse {
	file_id: string; // file._id
	file_name: string;
	meta_type: string; // "image/png"
	file_size: number;
}
