import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerNFC {
	//
	public constructor() {
		//
	}

	// Big Endian - the most-significant byte is the first byte... 2F480B
	// Little Endian - the least-significant byte is the first byte... 0B482F

	public static to8H10D( bytes: number[], littleEndian: boolean = true ): number { // min value: 0, max value: 4294967295 (0x00000000 to 0xFFFFFFFF)
		// 8 hex chars to 10 decimal digits. (basically 4 bytes transformed into one bigger number
		const fourBytes: number[] = [];
		if ( littleEndian ) {
			for ( let x: number = bytes.length - 1; x > -1 && fourBytes.length < 4; --x ) { // little endian format
				fourBytes.push( (bytes[x] + 256) % 256 );
			}
		} else {
			for ( let x: number = 0; x < bytes.length && x < 4; ++x ) { // big endian format
				fourBytes.push( (bytes[x] + 256) % 256 );
			}
		}
		return parseInt( fourBytes.map( (num: number): string => {
			return String( '00' + num.toString( 16 ) ).slice( -2 );
		} ).join( '' ), 16 );
	}
}
