<div id="wrapper-root">
	<app-header></app-header>
	<router-outlet></router-outlet>
</div>
<div class="wrapper">
	<app-footer></app-footer>
</div>
<div id="modal-view">
	<modal-credit-card *ngIf="showCreditCard"></modal-credit-card>
	<modal-invite-group-member *ngIf="showInviteGroupMember"></modal-invite-group-member>
	<modal-edit-group-member *ngIf="showEditGroupMember" [memberDoclet]="editGroupMemberDoclet"></modal-edit-group-member>
	<modal-liability-waiver *ngIf="showLiabilityWaiver"></modal-liability-waiver>
	<modal-tiny-sign-in *ngIf="showTinySignIn"></modal-tiny-sign-in>
</div>
<iframe id="download-target" name="download-target" style="background: none no-repeat scroll 0px 0px / cover transparent; border: 0px none transparent; height: 0px; left: 0px; line-height: 0px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; position: absolute; top: 0px; width: 0px; z-index: -1;"></iframe>
