<div class="total">{{ displayDigits | currency }}</div>
<div class="keypad">
	<span (click)="append( '1' );">1</span>
	<span (click)="append( '2' );">2</span>
	<span (click)="append( '3' );">3</span>
	<span (click)="append( '4' );">4</span>
	<span (click)="append( '5' );">5</span>
	<span (click)="append( '6' );">6</span>
	<span (click)="append( '7' );">7</span>
	<span (click)="append( '8' );">8</span>
	<span (click)="append( '9' );">9</span>
	<span class="action" (click)="reset();"><i class="fa-solid fa-arrow-rotate-right"></i></span>
	<span (click)="append( '0' );">0</span>
	<span class="action done" (click)="save();"><i
		[ngClass]="{
			'fa-solid': true,
			'fa-plus': submitIcon === 'add',
			'fa-check': submitIcon === 'check'
		}"></i></span>
</div>
