import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
// ===== Interfaces ===== //
interface InterfaceGroupDetailsData {
	organization: {
		name: string; // org/company name
		address: { // org/company location
			street1: string;
			street2: string;
			city: string;
			state: string;
			zip: string;
		};
		primaryContact: {
			firstName: string;
			lastName: string;
			email: string;
			phone: string;
		};
	};
	size: number; // how many people will be going?
}
interface InterfaceGroupDetailsErrors {
	organization: {
		name: boolean;
		address: {
			street1: boolean;
			city: boolean;
			state: boolean;
			zip: boolean;
		};
		primaryContact: {
			firstName: boolean;
			lastName: boolean;
			email: boolean;
			phone: boolean;
		};
	};
	size: boolean;
}
//
@Component( {
	selector: 'page-create-group',
	templateUrl: './create-group.html',
	styleUrls: [
		'./create-group.less'
	]
} )
export class PageCreateGroup {
	public groupDetailsData: InterfaceGroupDetailsData = {
		organization: {
			name: '',
			address: {
				street1: '',
				street2: '',
				city: '',
				state: '',
				zip: ''
			},
			primaryContact: {
				firstName: '',
				lastName: '',
				email: '',
				phone: ''
			}
		},
		size: 0
	};
	public groupDetailsErrors: InterfaceGroupDetailsErrors = {
		organization: {
			name: false,
			address: {
				street1: false,
				city: false,
				state: false,
				zip: false
			},
			primaryContact: {
				firstName: false,
				lastName: false,
				email: false,
				phone: false
			}
		},
		size: false
	};
	//
	public constructor(
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
	}

	public validateOrganizationFields(
		key: keyof InterfaceGroupDetailsErrors['organization'],
		subKey?: keyof InterfaceGroupDetailsErrors['organization']['address'] | keyof InterfaceGroupDetailsErrors['organization']['primaryContact']
	): void {
		switch ( key ) {
			case 'name': {
				break;
			}
			case 'address': {
				if ( subKey ) {
					switch ( subKey ) {
						case 'street1': {
							break;
						}
						case 'city': {
							break;
						}
						case 'state': {
							break;
						}
						case 'zip': {
							break;
						}
					} // end if subKey
				} // end if subKey was supplied
				break;
			}
			case 'primaryContact': {
				if ( subKey ) {
					switch ( subKey ) {
						case 'firstName': {
							break;
						}
						case 'lastName': {
							break;
						}
						case 'phone': {
							break;
						}
						case 'email': {
							break;
						}
					} // end switch subKey
				} // end if subKey was supplied
				break;
			}
		} // end switch key
	}

	public validateGroupSize(): void {
		this.groupDetailsErrors.size = this.groupDetailsData.size > 0;
	}
}
