import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceAppEvent, InterfaceNotification} from '../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAppEvents} from './app-events';
//
@Injectable( {
	providedIn: 'root',
} )
export class ServiceNotifications {
	//
	public constructor() {
		//
	}

	public show( header: string, body: string, id?: string ): void { // this seeeeems like the places that call this, can just directly call AppEvents
		ServiceAppEvents.broadcast( {
			topic: 'notification',
			data: {
				header: header,
				body: body,
				id: id ? id : undefined
			} as InterfaceNotification
		} as InterfaceAppEvent );
	}
}
