import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIRegisterAccount,
	InterfaceOWAPIRequestCompleteInvite,
	InterfaceOWInviteUser,
	InterfaceOWInviteUserInvites
} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIAccountRegistration {
	private readonly routePrefix: string = 'account/';
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Sign-Up, Forgot Password, Reset Password ========================= //

	public register( appContext: InterfaceAppContext, email: string, password: string, templateID: string, templateProperties: InterfaceAnyObject, profileProperties: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		// returns HTTP status of 201 on success, 409 if email already is in use.
		return this.gateway.fetch( 'put', this.routePrefix + 'registration', JSON.stringify( {
			auth: {
				email: email.toLowerCase(),
				password: password
			},
			realm_id: appContext.realmID,
			profile: profileProperties, // { first_name: string, last_name: string }
			template_id: templateID,
			template: templateProperties, // { first_name: value, last_name: value, ... }
			app_key: appContext.appKey, // db.services.findOne( ... ).app_key -- defines which workspaces are subscribed to this app...
			// TODO: send the RYST workspace ID instead?
			workspace_id: appContext.workspaceID // the instance of the app (the venue, physical location, water park, whatever)
		} as InterfaceOWAPIRegisterAccount ), true );
	}

	public inviteUsers( appContext: InterfaceAppContext, invites: InterfaceOWInviteUserInvites[], customMessage?: string, differentRealm?: string, differentWorkspace?: string ): Observable<InterfaceHTTPGateway> {
		const profileID: string | null = this.auth.getProfileID();
		if ( typeof profileID === 'string' && invites.length > 0 ) {
			const payload: InterfaceOWInviteUser = {
				profile_id: profileID, // the profile ID of the user inviting everyone else.
				realm_id: differentRealm ? differentRealm : appContext.realmID,
				workspace_id: differentWorkspace ? differentWorkspace : appContext.workspaceID,
				invites: invites
			};
			if ( customMessage ) {
				payload.custom_message = customMessage;
			}
			return this.gateway.fetch( 'post', this.routePrefix + 'registration/invite', JSON.stringify( payload ) );
			// the api response returned is an array of user IDs. see: InterfaceOWAPIInviteUserResponse
			// existing users won't be re-invited and will appear in the apiResponse.messages.errors instead of apiResponse.data.items
			// ... "errors":[{"target":"email","message":"Account with email: EMAIL@EMAIL.EMAIL is already registered"}]
		} else {
			return this.gateway.requestDenied();
		}
	}

	public completeInvite( appContext: InterfaceAppContext, token: string, password: string, templateID: string, templateProperties: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		// very similar to the normal registration. is a POST instead of a PUT. has profile_id. does not use email
		// HTTP status of 201 on success.	409 if the user has already completed registering.
		// HTTP status of 400 if the required top level fields are missing, presumably.
		const payload: InterfaceOWAPIRequestCompleteInvite = {
			auth: {
				// email: email.toLowerCase(),
				password: password
			},
			profile_id: token, // TODO: stop using profile IDs.
			realm_id: appContext.realmID,
			template_id: templateID,
			template: templateProperties, // { first_name: value, last_name: value, ... }
			workspace_id: appContext.workspaceID
		};
		return this.gateway.fetch( 'post', this.routePrefix + 'registration', JSON.stringify( payload ), true );
		// TODO: build interfaces for the response...
	}

	public resetPassword( appContext: InterfaceAppContext, email: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + 'registration/password', JSON.stringify( {
			email: email.toLowerCase(),
			realm_id: appContext.realmID
		} ), true ); // returns 400 if the user doesn't exist, or if the email address itself is invalid
	}

	public verifyEmail( verificationID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'registration/email/' + verificationID, undefined, true );
	}

	public resendVerifyEmail( appContext: InterfaceAppContext, email: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + 'registration/email', JSON.stringify( {
			email: email.toLowerCase(),
			realm_id: appContext.realmID
		} ), true );
	}

	public checkIfEmailExists( appContext: InterfaceAppContext, email: string, differentRealm?: string ): Observable<InterfaceHTTPGateway> {
		// returns HTTP status 200, if not taken. else you'll see a 409 conflict.
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( differentRealm ? differentRealm : appContext.realmID ) + '/email/' + encodeURIComponent( email ), undefined, true );
	}
}
