<span class="check-box-wrapper">
	<span [ngClass]="{
		'check-box' : true,
		'checked' : isChecked,
		'error' : error
	}" (click)="toggle();">
		<i class="{{isChecked ? 'fa-solid fa-square-check' : 'fa-light fa-square'}}"></i>
	</span>
	<span class="check-box-display">
		<span
			[class.action-link]="action.isLink"
			*ngFor="let action of actions; index as x"
			(click)="action.isLink ? linkActivated( x ) : toggle();"
		>{{ x > 0 && !action.noNBSP ? nbsp : "" }}{{ action.text }}</span>
	</span>
</span>
