import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIPaginationRequest
} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIRealmRealms {
	protected readonly routePrefix: string = 'realm/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public getRealmByID( appContext: InterfaceAppContext, realmID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( realmID ) );
	}

	public getRealmDocletsByKVP( realmID: string, key: string, value: string, withoutAuth?: boolean ): Observable<InterfaceHTTPGateway> {
		const route: string = this.routePrefix + encodeURIComponent( realmID ) + '/doclets/' + encodeURIComponent( key ) + '/' + encodeURIComponent( value );
		const pageOpts: InterfaceOWAPIPaginationRequest = {
			limit: 9999
		};
		return this.gateway.fetch( 'get', route, undefined, withoutAuth, pageOpts );
	}

	// TODO: other CRUD operations
}
