import {Pipe, PipeTransform} from '@angular/core';
// ===== Transformers ===== //
import {TransformerEventTicket} from '../transformers/event-ticket';
//
@Pipe( {
	name: 'passShortCodes',
	pure: true
} )
export class PipePassShortCodes implements PipeTransform { // obsolete
	public transform( code: string ): string {
		return TransformerEventTicket.getTicketTypeFromShortCode( code );
	}
}
