<span class="check-box-wrapper {{ cssSize ? 'size-' + cssSize : '' }}">
	<span [ngClass]="{
		'check-box' : true,
		'checked' : isChecked,
		'error' : error
	}" (click)="toggle();">
		<img alt="" ngSrc="/ow-imgs/ui-icon/check.png" height="16" width="22" />
	</span>
	<span (click)="toggle();" class="check-box-display">{{ text }}</span>
</span>
