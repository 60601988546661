import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceOWDoclet} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerEventTicket} from '../transformers/event-ticket';
//
@Pipe( {
	name: 'ticketAdmissionTime',
	pure: true
} )
export class PipeTicketAdmissionTime implements PipeTransform { // pulls the .data.time HH:MM:SS out of a doclet, turns it into '4pm'.
	// cartItem[x].ticket | ticketAdmissionTime // '4pm'
	public transform( ticket: InterfaceOWDoclet ): string {
		return TransformerEventTicket.getTicketTimeDisplayFromTicketDoclet( ticket );
	}
}
