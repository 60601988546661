// ===== Interfaces ===== //
import {InterfaceObjectDate} from './mongo/object-date';
import {InterfaceObjectId} from './mongo/object-id';
//

interface InterfaceTasksContextSurvey {
	doclet_id: string;
	timeout: number;
}

export interface InterfaceTasks { // TODO: this needs to extend some basic document. not doclets, just something basic.
	_id: InterfaceObjectId;
	label: string;
	phash?: number;
	title: string;
	description: string;
	actor: 'USER' | 'FLOW';
	to: null | InterfaceObjectId,
	from: null | InterfaceObjectId;
	action: null | InterfaceObjectId;
	workspace_id: InterfaceObjectId;
	group_id: InterfaceObjectId;
	template: null | string;
	context?: InterfaceTasksContextSurvey | any;
	payload?: any;
	status: 'active' | 'confirmed' | 'declined' | 'pending' | 'expired' | 'error' | string;
	tc: string;
	tc_raw: InterfaceObjectDate;
	tc_utc: string;
	tc_utc_raw: InterfaceObjectDate;
}
