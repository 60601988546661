<div id="page-check-email" class="page">
	<div class="content-wrapper" *ngIf="shownFrame === 0">
		<div>We received your registration request.</div>
		<div>Please look for an email at the address you provided and follow the instructions to complete your registration.</div>
		<div>&nbsp;</div>
		<div>Thank You</div>
	</div>
	<div class="action-wrapper" *ngIf="shownFrame === 0 && isSignedIn">
		<div class="actions">
			<span *ngIf="!emailResent" class="button" (click)="resendEmail();">Resend Email</span>
			<div *ngIf="emailResent" class="email-sent align-center">Email Sent!</div>
		</div>
	</div>
	<div class="content-wrapper" *ngIf="shownFrame === 1">
		<div>Verifying your email address...</div>
	</div>
	<div class="content-wrapper" *ngIf="shownFrame === 2">
		<div>Your email is successfully verified.</div>
		<div *ngIf="!isSignedIn">Please <a class="action" [routerLink]="[ '/' + routes.signIn ]">Sign In!</a></div>
		<div *ngIf="isSignedIn">Please continue to the <a class="action" [routerLink]="[ '/' + routes.dashboard ]">Dashboard</a></div>
	</div>
	<div class="content-wrapper" *ngIf="shownFrame === 3">
		<div>Your verification code was not valid.</div>
		<div>You may have tried to use an older code.</div>
		<div>Please try to <a class="action" [routerLink]="[ '/' + routes.signIn ]">sign in</a> and request a new code.</div>
	</div>
</div>
