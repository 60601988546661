import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIOrderItems,
	InterfaceOWAPIResponseData_T,
	InterfaceEventReservation,
	InterfaceUnassignedWristbandResponse,
} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspaceActionsPos { // class methods in ActionsPOS should not trample over function names in actions.ts - this file is simply to organize the POS-specific actions.
	protected readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly auth: ServiceAuthentication, // must leave off the public/protected/private on these two arguments, or it'll create class-properties automatically.
		private readonly gateway: ServiceOWGateway // must leave off the public/protected/private on these two arguments, or it'll create class-properties automatically.
	) {
		//
	}

	public createPlaceholderOrder( appContext: InterfaceAppContext, smartTerminalID?: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b2a143e32a10777957240c', JSON.stringify( {
			data: smartTerminalID
				? {
					terminal_id: smartTerminalID
				}
				: {}
		} ) );
	}

	public createCCTransactionForOrder( appContext: InterfaceAppContext, smartTermDocletID: string, smartTermAPITerminalID: string, actorProfileID: string, orderID: string, amount: number, orderedItems: InterfaceOWAPIOrderItems[], promoCode: string, isLastPayment?: boolean ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b269bde32a107779405eaf', JSON.stringify( {
			data: {
				pos_profile_id: actorProfileID,
				order_id: orderID,
				items: orderedItems,
				promo_code: promoCode.length > 0 ? promoCode : undefined,
				terminal_id: smartTermAPITerminalID,
				location_id: smartTermDocletID,
				payment_info: {
					action: 'CHARGE', // optional, defaults to charge.
					amount_base: amount
				},
				partial: !isLastPayment
			}
		} ) );
	}

	public createManualCCTransactionForOrder( appContext: InterfaceAppContext, smartTermDocletID: string, actorProfileID: string, orderID: string, ccNumber: string, ccExpMM: string, ccExpYY: string, ccCVV: string, billingZip: string, amount: number, orderedItems: InterfaceOWAPIOrderItems[], promoCode: string, isLastPayment?: boolean ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b78d1fe32a1077792911ca', JSON.stringify( {
			data: {
				pos_profile_id: actorProfileID,
				order_id: orderID,
				items: orderedItems,
				promo_code: promoCode.length > 0 ? promoCode : undefined,
				location_id: smartTermDocletID,
				payment_info: {
					action: 'CHARGE', // optional, defaults to charge.
					amount_base: amount,
					// missing name on card. possibly don't need it...
					billing_zip: billingZip,
					card_number: ccNumber,
					card_exp_month: ccExpMM,
					card_exp_year: ccExpYY.length < 4 ? '20' + ccExpYY : ccExpYY,
					card_cvv: ccCVV
				},
				partial: !isLastPayment
			}
		} ) );
	}

	public createCashTransactionForOrder( appContext: InterfaceAppContext, smartTermDocletID: string, actorProfileID: string, orderID: string, amountToPay: number, amountOfCashCollected: number, amountOfChangeDue: number, orderedItems: InterfaceOWAPIOrderItems[], promoCode: string, isLastPayment: boolean, tillID: string | undefined ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b4be24e32a10777910bd43', JSON.stringify( {
			data: {
				pos_profile_id: actorProfileID,
				order_id: orderID,
				items: orderedItems,
				promo_code: promoCode.length > 0 ? promoCode : undefined,
				location_id: smartTermDocletID,
				payment_info: {
					action: 'CHARGE', // optional, defaults to charge.
					amount_base: amountToPay, // the amount they should of paid.
					amount_change: amountOfChangeDue, // the amount of money owed back to the customer.
					amount_tender: amountOfCashCollected // the amount of money collected.
				},
				partial: !isLastPayment,
				till_id: tillID ?? null // used for tracking cash payments
			}
		} ) );
	}

	public completeOrder( appContext: InterfaceAppContext, smartTermDocletID: string, actorProfileID: string, orderID: string, profileID: string | null, orderedItems: InterfaceOWAPIOrderItems[], promoCode: string, emailReceipt?: boolean ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b38a1fe32a107779a3e3d1', JSON.stringify( {
			data: {
				pos_profile_id: actorProfileID,
				order_id: orderID,
				location_id: smartTermDocletID,
				profile_id: profileID,
				email_receipt: !!emailReceipt,
				items: orderedItems,
				promo_code: promoCode.length > 0 ? promoCode : undefined
			}
		} ) );
	}

	public cancelTransaction( appContext: InterfaceAppContext, transactionID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b39cade32a107779adabef', JSON.stringify( {
			data: {
				transaction_id: transactionID
			}
		} ) );
	}

	public searchForPurchaserByEmail( appContext: InterfaceAppContext, email: string, realm_id?: string ): Observable<InterfaceHTTPGateway> {
		// .data.items = [ { "profile_id: string, "consumer_id": string, "consumer": Doclet } ]
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b3b890e32a107779bb5701', JSON.stringify( {
			data: {
				realm_id: realm_id ?? appContext.realmID,
				email: email.toLowerCase()
			}
		} ) );
	}

	public scanTicket( appContext: InterfaceAppContext, actorProfileID: string, ticketID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b4f1ace32a1077792b6790', JSON.stringify( {
			data: {
				doclet_id: ticketID,
				pos_profile_id: actorProfileID
			}
		} ) );
	}

	public activateTicket( appContext: InterfaceAppContext, ticketID: string, firstName: string, lastName: string, dob?: string | Date ): Observable<InterfaceHTTPGateway> {
		// SPH tickets are status:active
		// once activated, status is set to activated
		// bought tickets are status:pending
		//
		// for SPH, ticketID is supposed to be the family members doclet_id, not the ticket's ID.
		// for non season pass holders, ticketID needs to be the ticket_id
		// ...not sure why
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b55434e32a1077795492e2', JSON.stringify( {
			data: {
				doclet_id: ticketID,
				first_name: firstName,
				last_name: lastName,
				dob: ''
			}
		} ) );
	}

	public linkWristBand( appContext: InterfaceAppContext, actorProfileID: string, deviceID: string, ticketID: string, isSPH: boolean ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b5546ee32a10777954aa33', JSON.stringify( {
			data: {
				pos_profile_id: actorProfileID,
				device_id: deviceID,
				doclet_id: ticketID,
				sph: isSPH
			}
		} ) );
	}

	public unlinkWristBand( appContext: InterfaceAppContext, actorProfileID: string, deviceID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b5548ee32a10777954b995', JSON.stringify( {
			data: {
				pos_profile_id: actorProfileID,
				device_id: deviceID
			}
		} ) );
	}

	public refundTransaction( appContext: InterfaceAppContext, smartTermDocletID: string, smartTermAPITerminalID: string, actorProfileID: string, newOrderID: string, oldOrderID: string, amountToRefund: number, orderedItems: InterfaceOWAPIOrderItems[], refundReason?: string ): Observable<InterfaceHTTPGateway> {
		// pool up the refund amount.
		// if the user wants to buy more tickets, etc. it'll eat away at the pool to refund.
		// if the amount has any remaining, send it up as the refund amount, etc.
		// otherwise, it turns into a charge, because the customer owes money to the <something>.
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b78d1fe32a1077792911ca', JSON.stringify( {
			data: {
				refund_reason: refundReason,
				pos_profile_id: actorProfileID,
				order_id: newOrderID,
				parent_order_id: oldOrderID,
				items: orderedItems, // if the item is to be refunded, then set the "type" to be "credit" on it.
				terminal_id: smartTermAPITerminalID,
				location_id: smartTermDocletID,
				payment_info: {
					action: 'REFUND', // optional, defaults to charge.
					amount_base: amountToRefund,
					// TODO: future flag for HOW things are being refunded. Cash, CC, Terminal CC, etc.
				}
			}
		} ) );
	}

	public getOrderHistoryByOrderID( appContext: InterfaceAppContext, orderID: string ): Observable<InterfaceHTTPGateway> {
		if ( orderID.length !== 24 ) {
			return this.gateway.requestDenied();
		}
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b8c834e32a107779aa6e29', JSON.stringify( {
			data: {
				order_id: orderID
			}
		} ) ); // { data: { items: [0] => { cabana, daily_tickets, order, parking } } }
	}

	public getOrderHistoryByTicketID( appContext: InterfaceAppContext, ticketID: string ): Observable<InterfaceHTTPGateway> {
		if ( ticketID.length !== 24 ) {
			return this.gateway.requestDenied();
		}
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b8c834e32a107779aa6e29', JSON.stringify( {
			data: {
				doclet_id: ticketID
			}
		} ) ); // { data: { items: [0] => { cabana, daily_tickets, order, parking } } }
	}

	public getOrderHistoryByConsumerID( appContext: InterfaceAppContext, docletID: string ): Observable<InterfaceHTTPGateway> {
		if ( docletID.length !== 24 ) {
			return this.gateway.requestDenied();
		}
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b8c834e32a107779aa6e29', JSON.stringify( {
			data: {
				consumer_id: docletID
			}
		} ), false, {
			limit: 9999
		} ); // { data: { items: [0] => { cabana, daily_tickets, order, parking } } }
	}

	public getOrderHistoryByConsumerCardLast4( appContext: InterfaceAppContext, cardLast4: string ): Observable<InterfaceHTTPGateway> {
		if ( cardLast4.length !== 4 ) {
			return this.gateway.requestDenied();
		}
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b8c834e32a107779aa6e29', JSON.stringify( {
			data: {
				last_four_cc: cardLast4
			}
		} ), false, {
			limit: 9999
		} ); // { data: { items: [0] => { cabana, daily_tickets, order, parking } } }
	}

	public getOrderHistoryByRandomID( appContext: InterfaceAppContext, randomID: string ): Observable<InterfaceHTTPGateway> {
		if ( randomID.length !== 24 ) {
			return this.gateway.requestDenied();
		}
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b8c834e32a107779aa6e29', JSON.stringify( {
			data: {
				consumer_id: randomID,
				doclet_id: randomID,
				order_id: randomID
			}
		} ), false, {
			limit: 9999
		} ); // { data: { items: [0] => { cabana, daily_tickets, order, parking } } }
	}

	public getDeviceInformation( appContext: InterfaceAppContext, deviceID: string ): Observable<InterfaceHTTPGateway<InterfaceOWAPIResponseData_T<InterfaceEventReservation | InterfaceUnassignedWristbandResponse>>> { // scan a wristband
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/62b55a03e32a107779564ca6', JSON.stringify( {
			data: {
				device_id: deviceID // if the device is not found, then the apiResponse.data will have unassigned_device = true
			}
		} ) );
	}

	public addCashToTill( appContext: InterfaceAppContext, cashIn: number, tillID: string | undefined, adminProfileID: string ): Observable<InterfaceHTTPGateway> {
		// Cash In
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/6438a0433796540a7ea6926c', JSON.stringify( {
			data: {
				admin_profile_id: adminProfileID,
				staff_profile_id: this.auth.getProfileID() as string,
				cash_in: cashIn.toFixed( 2 ), // the amount added to the till.
				till_id: tillID ?? null // the name of the cash registers drawer. doesn't have to be a mongo ID...
			}
		} ) ); // returns the affected Till doclet, similar to fetchDoclets. { data: { items: [ { till: <doclet> } ] } } // doclet = (response).data?.items?.pop?.()?.till
	}

	public removeCashFromTill( appContext: InterfaceAppContext, type: 'deposit' | 'cashout', cashOut: number, denominations: { [dollarSizeAndQty: string]: number; }, tillID: string | undefined, adminProfileID: string, overShortBy: number, trackingID: string ): Observable<InterfaceHTTPGateway> {
		// Cash Out
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/6438a0523796540a7ea6926d', JSON.stringify( {
			data: {
				admin_profile_id: adminProfileID,
				staff_profile_id: this.auth.getProfileID() as string,
				type: type, // Deposit vs Cash Out
				cash_out: cashOut.toFixed( 2 ), // the amount removed from the till.
				denominations: denominations, // { unit: qty } // { "20": 42, "10": 8, "5": 0, "1": 15 }
				till_id: tillID ?? null, // the name of the cash registers drawer. doesn't have to be a mongo ID...
				over_short: overShortBy.toFixed( 2 ), // over_short is negative if the user does not have enough cash. (till had 10, expecting 20, so the value to send is '-10.00')
				tracking: trackingID
			}
		} ) );
	}

	public arbitraryRefund( appContext: InterfaceAppContext, smartTermDocletID: string, smartTermAPITerminalID: string, actorProfileID: string, orderID: string, amountToRefund: number, itemType: string, refundReason: string, email?: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/action/64562c3a3796540a7ea692b4', JSON.stringify( {
			data: {
				// action: 'REFUND'
				staff_profile_id: actorProfileID,
				refund_reason: refundReason,
				type: itemType,
				refund_amount: amountToRefund,
				order_id: orderID,
				terminal_id: smartTermAPITerminalID,
				location_id: smartTermDocletID,
				email: email
			}
		} ) ); // returns the users updated Till
	}
}
