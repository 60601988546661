import {Component, EventEmitter, Input, Output} from '@angular/core';
//
@Component( {
	selector: 'app-digit-picker',
	templateUrl: './digit-picker.html',
	styleUrls: [
		'./digit-picker.less'
	]
} )
export class ComponentDigitPicker {
	private static readonly digitTest: RegExp = /^[0123456789]$/;
	public displayDigits: number = 0;
	@Input() public digits: string = '';
	@Input() public submitIcon: 'add' | 'check' = 'add';
	@Output() public submitted: EventEmitter<string> = new EventEmitter<string>();
	//
	public constructor() {
		//
	}

	public reset(): void {
		this.digits = '';
		this.displayDigits = 0;
	}

	public append( digit: string ): void {
		if ( ComponentDigitPicker.digitTest.test( digit ) ) {
			this.digits += digit; // string builder
			this.displayDigits = Number( Number( Number( this.digits ) / 100 ).toFixed( 2 ) );
		}
	}

	public save(): void {
		this.submitted.emit( this.digits ); // output is a string in whole numbers
		this.reset();
	}
}
