import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// ===== Interfaces ===== //
interface InterfaceFirstLastName {
	firstName: string;
	lastName: string;
}
// ===== Services ===== //
import {ServiceRegex} from '../../../services/regex';
//
@Component( {
	selector: 'app-first-last-name',
	templateUrl: './first-last-name.html',
	styleUrls: [
		'./first-last-name.less'
	]
} )
export class ComponentFirstLastName implements OnInit {
	@Input() public firstName: string = '';
	@Input() public lastName: string = '';
	@Input() public submitText: string = 'Continue';
	@Output() private submitted: EventEmitter<InterfaceFirstLastName> = new EventEmitter<InterfaceFirstLastName>();
	//
	public formFirstName: string = '';
	public formLastName: string = '';
	//
	public constructor() {
		//
	}

	public ngOnInit(): void {
		this.formFirstName = this.firstName;
		this.formLastName = this.lastName;
	}

	public submit(): void {
		this.submitted.emit( {
			firstName: this.formFirstName.replace( ServiceRegex.trimRegExp, '' ),
			lastName: this.formLastName.replace( ServiceRegex.trimRegExp, '' )
		} );
	}

	public blurTrim( key: keyof InterfaceFirstLastName ): void {
		switch ( key ) {
			case 'firstName': {
				this.formFirstName = this.formFirstName.replace( ServiceRegex.trimRegExp, '' );
				break;
			}
			case 'lastName': {
				this.formLastName = this.formLastName.replace( ServiceRegex.trimRegExp, '' );
				break;
			}
		}
	}
}
