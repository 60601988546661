// ===== Modules ===== //
import {NgModule} from '@angular/core';
// ===== Pipes ===== //
import {PipeDateFromYYYYMM1DD} from './YYYYMMDD';
import {PipePassFilterSPH} from './passFilterSPH';
import {PipePassPriceTime} from './passPriceTime';
import {PipePassShortCodes} from './passShortCodes';
import {PipeScannedTicketAssignedName} from './scannedTicketAssignedName';
import {PipeScannedTicketConsumerName} from './scannedTicketConsumerName';
import {PipeTicketAdmissionTime} from './ticketAdmissionTime';
import {PipeTicketAdmissionType} from './ticketAdmissionType';
import {PipeTransactionAction} from './transactionAction';
import {PipeTransactionStatus} from './transactionStatus';
import {PipeTrim} from './trim';
//
const pipes: any[] = [
	PipeDateFromYYYYMM1DD,
	PipePassFilterSPH,
	PipePassPriceTime,
	PipePassShortCodes,
	PipeScannedTicketAssignedName,
	PipeScannedTicketConsumerName,
	PipeTicketAdmissionTime,
	PipeTicketAdmissionType,
	PipeTransactionAction,
	PipeTransactionStatus,
	PipeTrim
];
//
@NgModule( {
	declarations: pipes,
	exports: pipes,
	imports: [],
	providers: []
} )
export class ModulePipes {}
