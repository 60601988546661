import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
// ===== App ===== //
import {AppRouterLinks} from '../../app.router-links';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
//
@Component( {
	selector: 'page-thank-you',
	templateUrl: './thank-you.html',
	styleUrls: [
		'./thank-you.less'
	]
} )
export class PageThankYou {
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public isSignedIn: boolean = false;
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly nav: ServiceNavigate,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
		this.isSignedIn = this.auth.isSignedIn();
	}

	public goDashboard(): void {
		this.nav.toURL( '/' + this.routes.dashboard );
	}
}
