import {Injectable} from '@angular/core';
import {ServiceRegex} from "../services/regex";
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerText {
	//
	public constructor() {
		//
	}

	public static numOrdinal( num: number ): string {  // 11th, 1st, 22nd, 12th, etc.
		let ord: string = 'th'
		if ( num > 3 && num < 21 ) {
			return num + ord;
		}
		if ( num < -3 && num > -21 ) { // -11th, -1st, -22nd, -12th, etc.
			return num + ord; // -12nd, -13rd lol
		}
		switch ( num % 10 ) {
			case 0: case 4: case 5: case 6: case 7: case 8: case 9: { ord = 'th'; break; }
			case 1: { ord = 'st'; break; }
			case 2: { ord = 'nd'; break; }
			case 3: { ord = 'rd'; break; }
		}
		return num + ord;
	}

	public static getCombinedStringsOrUndefined( str1: string, str2: string, separator: string = ' ' ): string | undefined {
		str1 = str1.replace( ServiceRegex.trimRegExp, '' );
		str2 = str2.replace( ServiceRegex.trimRegExp, '' );
		if ( str1.length > 0 ) {
			if ( str2.length > 0 ) {
				return str1 + separator + str2;
			}
			return str1;
		} else if ( str2.length > 0 ) {
			return str2;
		}
		return undefined;
	}
}
