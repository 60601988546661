<div class="calendar-listing">
	<div
		[ngClass]="{
			'calendar-wrapper': true,
			'size-large': largeUI,
			'size-small': !largeUI
		}"
		*ngFor="let calIdx of arrCalendarDisplayIdx"
	>
		<div class="month-label">{{ monthLabels[ (calendarData[calIdx].month1 - 1 + calIdx) % 12 ] + ', ' + selectedYear }}</div>
		<ul class="weeks calendar-days" *ngIf="calendarData.length > calIdx">
			<li class="day-of-week">S</li>
			<li class="day-of-week">M</li>
			<li class="day-of-week">T</li>
			<li class="day-of-week">W</li>
			<li class="day-of-week">T</li>
			<li class="day-of-week">F</li>
			<li class="day-of-week">S</li>
			<li class="day empty" *ngFor="let leadingBlank of calendarData[calIdx].leadingBlanks;"></li>
			<li
				[ngClass]="{
					'day': true,
					'past': !(calendarData[calIdx].year > thisYear
						|| (calendarData[calIdx].year === thisYear && calendarData[calIdx].month1 > thisMonth1)
						|| (calendarData[calIdx].year === thisYear && calendarData[calIdx].month1 > thisMonth1 && day > thisDay)
					),
					'today': calendarData[calIdx].year === thisYear && calendarData[calIdx].month1 === thisMonth1 && day === thisDay,
					'unavailable': calendarData[calIdx].blockedDays[day],
					'selected': !calendarData[calIdx].blockedDays[day] && selectedYear === calendarData[calIdx].year && selectedMonth1 === calendarData[calIdx].month1 && selectedDay === day
				}"
				*ngFor="let day of calendarData[calIdx].daysInMonth;"
				(click)="selectDay( calendarData[calIdx].year, calendarData[calIdx].month1, day );"
			><b>{{ day }}</b><span
				*ngIf="passForSale"
				[ngClass]="{
					'high logic-if-ticket-sold-count-is-on-last-tier': false,
					'low logic-if-ticket-sold-count-is-on-first-tier': false
				}"
				>{{ calendarData[calIdx].blockedDays[day] ? '' : getEventPassPrice( calendarData[calIdx].year, calendarData[calIdx].month1, day ) | currency : 'USD':'symbol-narrow':'1.0-0' }}</span></li>
			<li class="day empty" *ngFor="let trailingBlank of calendarData[calIdx].trailingBlanks;"></li>
		</ul>
	</div>
</div>
