import {Component, EventEmitter, Output} from '@angular/core';
// ===== Interfaces ===== //
export interface InterfaceComponentManualCCData {
	number: string;
	expMM: string;
	expYY: string;
	cvv: string;
	zip: string;
}
export interface InterfaceComponentManualCCErrors {
	number: boolean;
	expMM: boolean;
	expYY: boolean;
	cvv: boolean;
	zip: boolean;
}
export interface InterfaceComponentManualCCOutput {
	data: InterfaceComponentManualCCData;
	isValid: boolean;
}
//
@Component({
	selector: 'app-manual-cc',
	templateUrl: './manual-cc.html',
	styleUrls: [
		'./manual-cc.less'
	]
})
export class ComponentManualCC {
	public ccManualEntryData: InterfaceComponentManualCCData = {
		number: '',
		expMM: '',
		expYY: '',
		cvv: '',
		zip: ''
	};
	public ccManualEntryErrors: InterfaceComponentManualCCErrors = {
		number: false,
		expMM: false,
		expYY: false,
		cvv: false,
		zip: false
	}
	//
	@Output()
	public formChanged: EventEmitter<InterfaceComponentManualCCOutput> = new EventEmitter<InterfaceComponentManualCCOutput>();
	//
	public constructor() {
		//
	}

	private validateCCNum( ccNumber: string | number ): boolean {
		const ccNum: string = String( ccNumber );
		let ccType: string = '';
		let isValid: boolean = false;
		if ( ccNum.length > 14 ) {
			switch ( ccNum.charAt( 0 ) ) {
				// all credit card info here: http://www.iinbase.com/
				case '3': { // American Express
					if ( ccNum.charAt( 1 ) === '4' || ccNum.charAt( 1 ) === '7' ) { // 34 or 37
						ccType = 'American Express';
						isValid = ccNum.length === 16 || ccNum.length === 15; // Amex has both 15 and 16 digit card numbers.
					} // system identifier, type, currency, account number, a check digit
					break; // AMEX: SSTCAAAAAAAAAAC
				}
				case '4': { // Visa
					ccType = 'Visa';
					isValid = ccNum.length == 16; // All 3 other cards, use the 16 digit schema
					break; // SSTCAAAAAAAAAAC
				} // system identifier, issuer/bank identifier, bank number, account number, a check digit
				case '5': { // Master Card
					if ( ccNum.charAt( 1 ) > '0' && ccNum.charAt( 1 ) < '6' ) { // 51 to 55
						ccType = 'Master Card';
						isValid = ccNum.length === 16;
					}
					break;
				}
				case '6': { // Discover Card
					if ( ccNum.charAt( 1 ) === '0' && ccNum.charAt( 2 ) === '1' && ccNum.charAt( 3 ) === '1' ) { // 6011
						ccType = 'Discover Card';
						isValid = ccNum.length === 16;
					} // see: https://www.discovernetworkvar.com/common/pdf/var/10-1_VAR_ALERT_April_2010.pdf
					break;
				}
			}
		} // end if CC length is at least 15.
		return isValid;
	}

	public outputState(): void {
		this.formChanged.emit( {
			data: this.ccManualEntryData,
			isValid: this.isCCNumberIsValid() && this.isExpMonthValid() && this.isExpYearValid() && this.isCVVValid() && this.isZipValid()
		} );
	}

	private isCCNumberIsValid(): boolean {
		return this.validateCCNum( this.ccManualEntryData.number );
	}

	public validateCCNumber(): void {
		this.ccManualEntryErrors.number = !this.isCCNumberIsValid();
	}

	private isExpMonthValid(): boolean {
		if ( /^\d\d$/.test( this.ccManualEntryData.expMM ) ) {
			const intMM: number = parseInt( this.ccManualEntryData.expMM, 10 );
			return !Number.isNaN( intMM ) && intMM > 0 && intMM < 13; // must be 1 through 12.
		}
		return false;
	}

	public validateExpMonth(): void {
		this.ccManualEntryErrors.expMM = !this.isExpMonthValid();
		if ( this.ccManualEntryData.expYY.length > 0 && !this.ccManualEntryErrors.expMM ) {
			// if the Month is okay, and the Year is at least populated...
			const intMM: number = parseInt( this.ccManualEntryData.expMM, 10 );
			const _now: Date = new Date();
			let intYYYY: number = parseInt( this.ccManualEntryData.expYY, 10 );
			if ( intYYYY < 100 ) {
				intYYYY += Math.floor( _now.getFullYear() / 100 ) * 100; // floor(2377/100) = 23. 23 * 100 = 2300.
			}
			this.ccManualEntryErrors.expMM = Number.isNaN( intMM ) || Number.isNaN( intYYYY ) || intYYYY < _now.getFullYear() || intYYYY === _now.getFullYear() && intMM < _now.getMonth() + 1;
			this.ccManualEntryErrors.expYY = this.ccManualEntryErrors.expMM; // if expired, they're both invalid.
		}
	}

	private isExpYearValid(): boolean {
		const _now: Date = new Date();
		let intYYYY: number = parseInt( this.ccManualEntryData.expYY, 10 );
		if ( intYYYY < 100 ) {
			intYYYY += Math.floor( _now.getFullYear() / 100 ) * 100; // floor(2377/100) = 23. 23 * 100 = 2300.
		}
		return /^\d\d$|^\d\d\d\d$/.test( this.ccManualEntryData.expYY ) && !Number.isNaN( intYYYY ) && intYYYY >= _now.getFullYear();
	}

	public validateExpYear(): void {
		this.ccManualEntryErrors.expYY = !this.isExpYearValid();
		if ( this.ccManualEntryData.expMM.length > 0 && !this.ccManualEntryErrors.expYY ) {
			const _now: Date = new Date();
			let intYYYY: number = parseInt( this.ccManualEntryData.expYY, 10 );
			if ( intYYYY < 100 ) {
				intYYYY += Math.floor( _now.getFullYear() / 100 ) * 100; // floor(2377/100) = 23. 23 * 100 = 2300.
			}
			if ( !Number.isNaN( intYYYY ) ) {
				const intMM: number = parseInt( this.ccManualEntryData.expMM, 10 );
				// check if the combined MM/YY are okay.
				this.ccManualEntryErrors.expYY = Number.isNaN( intMM ) || Number.isNaN( intYYYY ) || intYYYY < _now.getFullYear() || intYYYY === _now.getFullYear() && intMM < _now.getMonth() + 1;
				this.ccManualEntryErrors.expMM = this.ccManualEntryErrors.expYY; // copy the result.
			}
		}
	}

	private isCVVValid(): boolean {
		return this.ccManualEntryData.cvv.length > 1; // 2 - 4 digits?
	}

	public validateCVV(): void {
		this.ccManualEntryErrors.cvv = !this.isCVVValid();
	}

	private isZipValid(): boolean {
		return /^\d\d\d\d\d$/.test( this.ccManualEntryData.zip );
	}

	public validateZip(): void {
		this.ccManualEntryErrors.zip = !this.isZipValid();
	}

	public validateForm(): void {
		this.validateCCNumber();
		this.validateCVV();
		this.validateExpMonth();
		this.validateExpYear();
		this.validateZip();
	}
}
