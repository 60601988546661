import {Component} from '@angular/core';
// ===== App ===== //
import {AppConfig} from '../../app.config';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPISignInResponse,
	InterfaceOWUserAuth
} from '../../../../../../ow-framework/interfaces/interfaces';
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
//
@Component( {
	selector: 'modal-tiny-sign-in',
	templateUrl: './tiny-sign-in.html',
	styleUrls: [
		'./tiny-sign-in.less'
	]
} )
export class ModalTinySignIn {
	public email: string = '';
	public password: string = '';
	public badEmail: boolean = false;
	public badLogIn: boolean = false;
	public busy: boolean = false;
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly owapi: ServiceOWAPI
	) {
		//
	}

	public checkEmail(): void {
		this.badEmail = !ServiceRegex.emailRegExp.test( this.email );
	}

	public signIn(): void {
		if ( !this.busy ) {
			this.badLogIn = false;
			if ( ServiceRegex.emailRegExp.test( this.email ) && this.password.length > 0 ) {
				this.busy = true;
				const ac: InterfaceAppContext = this.appConfig.getContext();
				this.owapi.account.auth.login( ac, this.email, this.password ).subscribe( (response : InterfaceHTTPGateway): void => {
					this.busy = false;
					if ( response && response.success && response.status === 200 ) {
						const logInResult: InterfaceOWAPISignInResponse = response.data;
						if ( logInResult && logInResult.data ) {
							const authData: InterfaceOWUserAuth = logInResult.data;
							if ( authData && authData.auth_key && authData.user && authData.user._id && authData.user._id.$oid ) {
								this.auth.setTokens( authData.auth_key, authData.user._id.$oid );
								this.colProfiles.cacheUserProfiles( [ authData.user ] );
							}
						}
						if ( this.auth.isSignedIn() ) {
							ServiceAppEvents.broadcast( 'user:re-sync' );
							this.closeModal();
						} else {
							this.badLogIn = true;
						}
					} else {
						this.badLogIn = true;
					}
				} );
			} else {
				this.badLogIn = true;
			}
		}
	}

	public closeModal(): void {
		ServiceAppEvents.broadcast( 'modal:close:tiny-sign-in' );
	}

	public blur( E: Event ): void {
		void (E?.target as HTMLElement)?.blur?.(); // yes, you can use "?." on callables.
	}
}
