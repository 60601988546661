import {Socket} from 'socket.io-client';
// ===== Interfaces ===== //
import {InterfaceAnyObject} from './vanilla-js';
//
export interface InterfaceSocketIOEventBridge {
	'wss:sync': () => void; // this is called when the socket connection is (re)established.
	[topic: string]: (serverData?: any) => any;
}
//
export interface InterfaceSocketIO {
	socket: (Socket & InterfaceAnyObject) | null;
	start: () => void;
	clear: () => void;
	stop: () => void;
	onReconnectAttempt?: (amount: number) => void;
	onError?: (error: any) => void;
	onConnection?: () => void;
	onDisconnection?: (msg: string) => void;
	sendData: (topic: string, data: any) => void;
	eventBridge: InterfaceSocketIOEventBridge;
	init: (ioAPI: string) => void;
}
