import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceScannedTicket} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerEventTicket} from '../transformers/event-ticket';
//
@Pipe( {
	name: 'scannedTicketAssignedName',
	pure: true
} )
export class PipeScannedTicketAssignedName implements PipeTransform { // scannedTicket.ticket.data.assigned_first_name and assigned_last_name
	public transform( scannedTicket: InterfaceScannedTicket ): string {
		return TransformerEventTicket.getAssignedNameFromScannedTicket( scannedTicket );
	}
}
