<nav id="utility">
	<div class="wrapper masthead">
		<a class="logo" target="_blank" [href]="copyMap['eventWebsite']">
			<img alt="" class="workspace-logo" src="assets/img/earthstar_logo_white.png"/>
			<img alt="" class="powered-by" src="assets/img/ryst_logo_powered.png"/>
		</a>
		<ul class="action-menu" *ngIf="!lockdown">
			<li>
				<span (click)="goBuyNow();">Tickets</span>
			</li>
			<li *ngIf="isSignedIn">
				<span class="action" (click)="enabledLinkDashboard && goDashboard();">Dashboard</span>
			</li>
			<li>
				<span class="action" *ngIf="isSignedIn" (click)="signOut();">Sign Out</span>
				<span class="action" *ngIf="!isSignedIn" (click)="goSignIn();">Sign In</span>
			</li>
			<li (click)="toggleCart();" *ngIf="!isOnCheckout">
				<i class="action icon fa-solid fa-cart-shopping"></i>
				<b
					[ngClass]="{
						'cart-count': true,
						'active': cartCount > 0
					}"
				>{{ cartCount }}</b>
			</li>
			<li class="action-mobile" *ngIf="!isOnCheckout" (click)="toggleCart();">
				<i class="fa-solid fa-cart-shopping"></i>
				<b class="cart-count active" *ngIf="cartCount"></b>
			</li>
			<li (click)="toggleMenu();" class="action-mobile">
				<i *ngIf="!isShowingMenu" class="fa-solid fa-bars"></i>
				<i *ngIf="isShowingMenu" class="fa-solid fa-xmark"></i>
			</li>
		</ul>
	</div>
	<div id="mobile-menu" [ngClass]="{active: isShowingMenu}">
		<div class="wrapper">
			<ul>
				<li>
					<span (click)="goBuyNow(); toggleMenu();">Tickets</span>
				</li>
				<li *ngIf="isSignedIn">
					<span class="action" (click)="enabledLinkDashboard && goDashboard(); toggleMenu();">Dashboard</span>
				</li>
				<li *ngIf="isSignedIn">
					<span class="action" (click)="enabledLinkMyAccount && goMyAccount(); toggleMenu();">My Account</span>
				</li>
				<li *ngIf="isSignedIn">
					<span class="action" (click)="enabledLinkDashboard && goOrders(); toggleMenu();">Orders & Billing</span>
				</li>
				<li>
					<span class="action" *ngIf="isSignedIn" (click)="signOut(); toggleMenu();">Sign Out</span>
					<span class="action" *ngIf="!isSignedIn" (click)="goSignIn(); toggleMenu();">Sign In</span>
				</li>
			</ul>
		</div>
	</div>
</nav>

<div id="cart-preview"
	[ngClass]="{
		'wrapper': true,
		'active': isShowingCart && !isOnCheckout
	}"
>
	<div class="flyout"
		(mouseenter)="touchCart();"
		(click)="touchCart();"
	>
		<header>
			<b>Cart</b>
			<span class="close" (click)="hideCart();"><i class="fa-solid fa-xmark"></i></span>
		</header>
		<ul class="item-list">
			<li class="item headers">
				<span class="item-details">Item</span>
				<span class="item-controls">Qty</span>
				<span class="item-price">Price</span>
			</li>
			<li class="item meta"
				*ngIf="displayedCartData.length < 1"
			>
				<span class="nothing-in-cart">Your cart is empty.</span>
			</li>
			<li class="item"
				*ngFor="let cartLineItem of displayedCartData;"
			>
				<span class="item-details">
					<b>{{ cartLineItem.name }}</b>
					<span class="date">{{ cartLineItem.date.display }}</span>
				</span>
				<span class="item-controls">
					<span class="item-controls-qty">
						<span class="control" (click)="reduceQty( cartLineItem );">-</span>
						<span class="qty">{{ cartLineItem.qty }}</span>
						<span class="control" (click)="addQty( cartLineItem );">+</span>
					</span>
					<div class="item-controls-remove" (click)="removeLineItemGroup( cartLineItem );"><i class="fa-solid fa-trash-can"></i> Remove</div>
				</span>
				<span class="item-price">
					<b>{{ cartLineItem.lineItemTotal | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</b>
				</span>
			</li>
			<li class="item total">
				<span class="item-details">Total</span>
				<span class="item-price"><b>{{ totalPrice | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</b></span>
			</li>
			<li>
				<span
					[ngClass]="{
						'button': true,
						'disabled': displayedCartData.length < 1
					}"
					(click)="displayedCartData.length > 0 && goCheckout(); hideCart();"
				>Checkout<i class="fa-solid fa-arrow-right"></i></span>
			</li>
		</ul>
	</div>
</div>
