import {Component} from '@angular/core';
// ===== Interfaces ===== //
interface InterfaceMemberData {
	firstName: string;
	lastName: string;
	email: string;
}
interface InterfaceMemberErrors {
	firstName: boolean;
	lastName: boolean;
	email: boolean;
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
//
@Component( {
	selector: 'modal-invite-group-member',
	templateUrl: './invite-group-member.html',
	styleUrls: [
		'./invite-group-member.less'
	]
} )
export class ModalInviteGroupMember {
	public memberData: InterfaceMemberData = {
		firstName: '',
		lastName: '',
		email: ''
	};
	public memberErrors: InterfaceMemberErrors = {
		firstName: false,
		lastName: false,
		email: false
	};
	//
	public constructor() {
		//
	}

	public validateField( field: keyof InterfaceMemberData ): void {
		this.memberData[field] = this.memberData[field].replace( ServiceRegex.trimRegExp, '' );
		switch ( field ) {
			case 'firstName':
			case 'lastName': {
				if ( this.memberData[field].length < 1 ) {
					this.memberErrors[field] = true;
				}
				break;
			}
			case 'email': {
				this.memberErrors[field ] = !ServiceRegex.emailRegExp.test( this.memberData[field] );
				break;
			}
		}
	}

	public closeModal(): void {
		ServiceAppEvents.broadcast( 'modal:close:invite-group-member' );
	}

	public inviteGroupMember(): void {
		const keys: (keyof InterfaceMemberData)[] = Object.keys( this.memberData ) as (keyof InterfaceMemberData)[];
		for ( let x: number = 0; x < keys.length; ++x ) {
			this.validateField( keys[x] );
		}
		const errors: (keyof InterfaceMemberErrors)[] = Object.keys( this.memberErrors ) as (keyof InterfaceMemberErrors)[];
		let fail: boolean = false;
		for ( let x: number = 0; !fail && x < errors.length; ++x ) {
			fail = fail || this.memberErrors[ errors[x] ];
		}
		if ( fail ) {
			console.log( this.memberErrors );
		} else {
			ServiceAppEvents.broadcast( {
				topic: 'data:invite-group-member',
				data: this.memberData
			} );
			this.closeModal();
		}
	}
}
