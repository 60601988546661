import {InterfaceObjectId} from './mongo/object-id';

export interface InterfaceOWWorkspaceEntry {
	_id: InterfaceObjectId;
	description?: string;
	name: string;
	notes?: string;
	parent: InterfaceObjectId | '0';
	status: '0' | '1' | string;
}

export interface InterfaceOWWorkspaceList {
	activeWorkspace: string; // the _id
	workspaceList: InterfaceOWWorkspaceEntry[];
}

export interface InterfaceOWWorkspaceActionList {
	id: string; // not the (record)._id.$oid -- but just the value off of the $oid
	resource: string; // java class name.
	description: undefined | null; // unknown usage. always null
	title: undefined | null; // unknown usage. always null
}

export interface InterfaceEventWorkspaceChanged {
	workspaceID: string;
}
