import {InterfaceAnyObject} from '../angular/vanilla-js';
import {InterfaceObjectId} from './mongo/object-id';
import {InterfaceOWTemplate} from './templates';
import {InterfaceOWDoclet} from './doclets'; // circular dependency
//
export interface InterfaceOWWeaveWeaves { // old format. the server-side still returns this, instead of the new V2 format: { p_id, c_id, t_id, w_id, data }
	data?: InterfaceAnyObject;
	doclet_id: string;
	doclet?: InterfaceOWDoclet | null;
	template_id: string;
	template?: InterfaceOWTemplate | null;
	weave_id: string;
}

export interface InterfaceOWWeaveVerbose {
	woven_doclet: InterfaceOWDoclet;
	// other fields may end up in here over time...
}

export interface InterfaceOWWeaveV2 {
	_id: InterfaceObjectId;
	p_id: InterfaceObjectId; // the parent doclet that all the children are woven onto.
	c_id: InterfaceObjectId; // the one child (of possibly many)
	w_id: InterfaceObjectId; // weave_id - see weaves_config.findOne( { "_id" : this_id } );
	t_id: InterfaceObjectId; // the template_id of the child doclet.
	data?: InterfaceAnyObject; // custom data between the parent and child.
	verbose?: InterfaceOWWeaveVerbose; // when ?verbose=true then the child doclets are returned.
}

export interface InterfaceOWWeaveConfig {
	ext: InterfaceAnyObject[];
	name: string;
	tid: InterfaceObjectId;
}
