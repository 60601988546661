import {Pipe, PipeTransform} from '@angular/core';
// ===== Services ===== //
import {ServiceRegex} from '../services/regex';
//
@Pipe( {
	name: 'trim',
	pure: true
} )
export class PipeTrim implements PipeTransform {
	public transform( str: string ): string {
		return str.replace( ServiceRegex.trimRegExp, '' );
	}
}
