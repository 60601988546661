import {Injectable} from '@angular/core';
import {io as SocketIO} from 'socket.io-client';
// ===== Interfaces ===== //
import {InterfaceSocketIO} from '../interfaces/interfaces';
//
const jwtName: string = 'auth_key';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceSocketIO {
	public socket: InterfaceSocketIO['socket'] = null;
	public readonly eventBridge: InterfaceSocketIO['eventBridge'] = {
		'wss:sync': (): void => {} // stub
		// 'topic': Function // (data) => { this.myAngularFunction( data ); }
	}
	//
	public constructor() {
		//
	}

	public start(): void {
		if ( this.socket ) {
			if ( this.socket.connected ) {
				console.log( 'IO already connected.' );
			} else if ( typeof this.socket.open === 'function' ) {
				if ( this.socket.io && this.socket.io.opts ) {
					if ( !this.socket.io.opts.query ) {
						this.socket.io.opts.query = {};
					}
					this.socket.io.opts.query['jwt'] = localStorage.getItem( jwtName );
				}
				if ( !this.socket['query'] ) { // this doesn't seem to do anything. seems io.opts.query is needed here.
					this.socket['query'] = {};
				}
				this.socket['query'].jwt = localStorage.getItem( jwtName );
				this.socket.open();
			}
		} else {
			console.log( 'Tried to start the web-socket, but there is no socket to start.' );
		}
	}

	public clear(): void {
		this.stop();
		this.socket = null;
	}

	public stop(): void {
		if ( this.socket ) {
			if ( this.socket['query'] && this.socket['query'].jwt ) {
				delete this.socket['query'].jwt;
			}
			if ( this.socket.io && this.socket.io.opts && (this.socket.io.opts as any).jwt ) {
				delete (this.socket.io.opts as any).jwt;
			}
			if ( typeof this.socket.close === 'function' ) {
				this.socket.close();
			}
		}
	}

	public onReconnectAttempt( _: number ): void { // ( amount: number ): void {
		if ( this.socket ) {
			if ( this.socket.io && this.socket.io.opts ) {
				if ( !this.socket.io.opts.query ) {
					this.socket.io.opts.query = {}; // seems this doesn't do anything. opposite of EventIO.start()
				}
				this.socket.io.opts.query['jwt'] = localStorage.getItem( jwtName );
			}
			if ( !this.socket['query'] ) { // seems this is needed. not io.opts.query
				this.socket['query'] = {};
			}
			this.socket['query']['jwt'] = localStorage.getItem( jwtName );
		}
	}

	public onError( err: any ): void {
		console.log( 'Web-socket error', err );
		setTimeout( (): void => {
			if ( localStorage.getItem( jwtName ) ) {
				this.start();
			}
		}, 2500 );
	}

	public onConnection(): void {
		console.log( 'Web-socket connected' );
	}

	public onDisconnection( msg: string ): void {
		console.log( 'Web-socket disconnected', msg );
		setTimeout( (): void => {
			if ( localStorage.getItem( jwtName ) ) {
				this.start();
			}
		}, 2000 );
	}

	public sendData( topic: string, data: any ): void {
		if ( this.socket && typeof this.socket.emit === 'function' && this.socket.connected ) {
			this.socket.emit( topic, data );
		} else {
			console.log( 'Socket IO - Dropped out-bound message. Socket not connected.', topic, data );
		}
	}

	public init( ioAPI: string ): void {
		if ( this.socket === null ) {
			this.socket = SocketIO( ioAPI, {
				autoConnect: false,
				path: '/io', // must match the 'path' property set on the server-side.
				secure: !!ioAPI.match( /^wss/ ),
				transports: [
					'websocket'
				]
			} );
			if ( this.onError ) {
				this.socket.on( 'error', this.onError );
			}
			if ( this.onReconnectAttempt ) {
				this.socket.on( 'reconnect_attempt', this.onReconnectAttempt );
			}
			if ( this.onConnection ) {
				this.socket.on( 'connect', this.onConnection );
			}
			if ( this.onDisconnection ) {
				this.socket.on( 'disconnect', this.onDisconnection );
			}
			//
			if ( this.eventBridge ) {
				const topics: string[] = Object.keys( this.eventBridge );
				for ( let x: number = 0; x < topics.length; ++x ) {
					if ( typeof topics[x] === 'string' && typeof this.eventBridge[ topics[x] ] === 'function' ) {
						this.socket.on( topics[x], this.eventBridge[ topics[x] ] );
						// console.log( 'WSS -- topic:', topics[x], 'fn:', this.eventBridge[ topics[x] ] );
					}
				}
			}
		} else {
			console.log( 'Skipping web-socket initialization, already exists.' );
		}
	}
}
