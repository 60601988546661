<form>
	<label class="card-number wide">
		<span>Card Number</span>
		<input type="text" name="number" placeholder="•••• •••• •••• ••••" pattern="[0-9]*" inputmode="numeric" maxlength="16"
			[class.error]="ccManualEntryErrors.number"
			[(ngModel)]="ccManualEntryData.number"
			[ngModelOptions]="{ updateOn: 'blur' }"
			(focus)="ccManualEntryErrors.number = false;"
			(blur)="validateCCNumber(); outputState();"
		/>
	</label>
	<label class="expiration">
		<span>Expiration Date</span>
		<div class="input-wrapper">
			<input type="text" name="expMM" placeholder="MM" pattern="[0-9]*" inputmode="numeric" maxlength="2"
				[class.error]="ccManualEntryErrors.expMM"
				[(ngModel)]="ccManualEntryData.expMM"
				[ngModelOptions]="{ updateOn: 'blur' }"
				(focus)="ccManualEntryErrors.expMM = false;"
				(blur)="validateExpMonth(); outputState();"
			/><b>/</b><input type="text" name="expYY" placeholder="YY" pattern="[0-9]*" inputmode="numeric" maxlength="2"
				[class.error]="ccManualEntryErrors.expYY"
				[(ngModel)]="ccManualEntryData.expYY"
				[ngModelOptions]="{ updateOn: 'blur' }"
				(focus)="ccManualEntryErrors.expYY = false;"
				(blur)="validateExpYear(); outputState();"
			/>
		</div>
	</label>
	<label>
		<span>Security Code</span>
		<input type="text" name="cvv" placeholder="•••" pattern="[0-9]*" inputmode="numeric" maxlength="5"
			[class.error]="ccManualEntryErrors.cvv"
			[(ngModel)]="ccManualEntryData.cvv"
			[ngModelOptions]="{ updateOn: 'blur' }"
			(focus)="ccManualEntryErrors.cvv = false;"
			(blur)="validateCVV(); outputState();"
		/>
	</label>
	<label class="wide">
		<span>Postal Code</span>
		<input type="text" name="zip" placeholder="12345" pattern="[0-9]*" inputmode="numeric" maxlength="5"
			[class.error]="ccManualEntryErrors.zip"
			[(ngModel)]="ccManualEntryData.zip"
			[ngModelOptions]="{ updateOn: 'blur' }"
			(focus)="ccManualEntryErrors.zip = false;"
			(blur)="validateZip(); outputState();"
		/>
	</label>
</form>
