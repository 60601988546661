<span class="check-box-wrapper">
	<span [ngClass]="{
		'check-box' : true,
		'checked' : isChecked,
		'error' : error
	}" (click)="toggle();">
		<i class="{{isChecked ? 'fa-solid fa-square-check' : 'fa-light fa-square'}}"></i>
	</span>
	<span class="check-box-display">
		<span><span (click)="toggle();">I have read and agree to the&nbsp;{{ termsAndConditionsURL === null ? 'Terms &amp; Conditions' : '' }}</span><a *ngIf="termsAndConditionsURL !== null" style="color: #007EFA; text-decoration: none;" [href]="termsAndConditionsURL" target="_blank">Terms &amp; Conditions</a>.</span>
	</span>
</span>
