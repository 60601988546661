export interface InterfaceHTTPGateway<T = any> {
	success: boolean;
	status: number;
	data: T; // server-side payload is packed into here, and it usually has a "data" property, too.
	// so usually you'll need use (response.data).data to make use of it.
}

export interface InterfaceHTTPMethods {
	delete: 'delete';
	get: 'get';
	head: 'head';
	options: 'options';
	patch: 'patch';
	post: 'post';
	put: 'put';
}

export interface InterfaceHTTPConfig {
	host: string; // api.omniplatform.com
	port?: number; // 0x0000 to 0xFFFF
	secure: boolean; // true for https:// false for http://
}
