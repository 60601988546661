import {InterfaceOWDoclet} from '../ow/doclets';

export interface InterfaceTableWrapper {
	headers: InterfaceTableColumnConfig[],
	items: InterfaceTableItems[]
}

export interface InterfaceTableItems {
	selected?: boolean;
	cells: (number | string | any | null)[]; // the row of cells.
	doclet?: InterfaceOWDoclet,
	callback?: any
}

export interface InterfaceTableColumnConfig {
	label: string;
	key?: string;
	sortOrder?: 'ASC' | 'DESC'; // default should be ASC, if not defined.
	isSortable?: boolean;
	readonly?:boolean;
	classes?: string; // Extra classes to tack on
	type?: string;
	enum?: string[];
}
