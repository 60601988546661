<div class="month-selector">
	<!-- intended to have a horizontal wheel to drag/rotate, as well as clickable [<]buttons[>]  -->
</div>
<div class="day-selector">
	<!-- ...assuming people give their DOB as month/day/year... -->
</div>
<div class="year-selector">
	<!-- ...so each wheel is positioned in that order. -->
</div>
<div class="actions">
	<span class="button">Clear<!-- removes the DOB value --></span>
	<span class="button primary">Continue</span>
</div>
