import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {QRCodeErrorCorrectionLevel} from 'angularx-qrcode';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAdmissionQRCode,
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceHTTPGateway,
	InterfaceLanguageChangeEvent,
	InterfaceMemberEntitlements,
	InterfaceMemberEntitlementsByEventDateMember,
	InterfaceMemberEntitlementsByMemberAdmission,
	InterfaceMemberEntitlementsConsumerDataByID,
	InterfaceNavMenuItem,
	InterfaceOWAPICardVault,
	InterfaceOWAPICardVaultResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWAPIGetMemberEntitlementsActionResponse,
	InterfaceOWAPIGetUserProfileResponse,
	InterfaceOWDoclet,
	InterfaceOWTemplateConsumer,
	InterfaceOWTemplateVenueEvent,
	InterfaceOWTemplateVenueLocation,
	InterfaceOWUser,
	InterfaceOWUserProfileData
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfacePrimaryAccountUser {
	consumerDocletID: string;
	firstName: string;
	lastName: string;
	photo: string;
	cashlessSpending: boolean;
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
// ===== Transformers ===== //
import {TransformerDate} from '../../../../../../ow-framework/transformers/date';
import {TransformerEventTicket} from '../../../../../../ow-framework/transformers/event-ticket';
//
@Component( {
	selector: 'page-dashboard',
	templateUrl: './dashboard.html',
	styleUrls: [
		'./dashboard.less'
	]
} )
export class PageDashboard implements OnDestroy, OnInit {
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	//
	private subProfilesUpdated: Subscription | null = null;
	private subUserReSync: Subscription | null = null;
	private subLanguageChanged: Subscription | null = null;
	//
	public mastHeading: string = 'Hello'; // 'Hello, firstname'
	public readonly navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family, // TODO: combine this and the manage-group stuff.
			text: 'Manage Family', // stop calling things Family. only call it Group, etc.
			shortText: 'Family',
			locked: true
		}, /*
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		}, */
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		},
		{
			route: '/' + this.routes.myAccount,
			text: 'My Account',
			shortText: 'Account'
		}
	];
	//
	public haveUserInfo: boolean = false;
	public primaryAccountHolder: InterfacePrimaryAccountUser = {
		consumerDocletID: '',
		firstName: '',
		lastName: '',
		photo: '',
		cashlessSpending: false
	};
	private subCardReSync: Subscription | null = null;
	private subAccountReSync: Subscription | null = null;
	public accountDoclet: InterfaceOWDoclet | undefined = undefined;
	private cardsOnFile: InterfaceOWAPICardVault[] = [];
	public haveAccountInfo: boolean = false; // account info is needed to look upon the cashless toggle flag.
	private readonly strAccountTemplateID: string = this.appConfig.getTemplateID( 'Account' );
	//
	public monthLabels: string[] = TransformerDate.getCalendarMonths();
	//
	public consumerEntitlementsLoaded: boolean = false;
	// ===== //
	private memberEntitlements: InterfaceMemberEntitlements[] = [];
	public memberEntitlementsByEventDateMember: InterfaceMemberEntitlementsByEventDateMember = {
		eventIDs: [],
		eventData: {}
	};
	public readonly eventDocletCache: { [eventID: string]: InterfaceOWDoclet<InterfaceOWTemplateVenueEvent>; } = {};
	public readonly venueDocletCache: { [eventID: string]: InterfaceOWDoclet<InterfaceOWTemplateVenueLocation>; } = {};
	private readonly strVenueLocationTemplateID: string = this.appConfig.getTemplateID( 'Venue Location' );
	// ===== QR Codes ===== //
	public readonly qrCorrectionLevel: QRCodeErrorCorrectionLevel = 'H';
	// ===== Single Display QR Code ===== //
	public activeQR: string = ''; // nothing uses this atm. only the multi-list is used.
	public activeQRTitle: string = '';
	// ===== Multiple QR Code Display ===== //
	public viewingAdmissionQRCodes: boolean = false;
	public admissionQRCodesByConsumer: {
		consumerIDs: string[];
		consumerData: {
			[consumerID: string]: InterfaceMemberEntitlementsConsumerDataByID;
		};
		needToSwipe: boolean;
	} = {
		consumerIDs: [],
		consumerData: {},
		needToSwipe: false
	};
	//
	public recycleTB: boolean = true; // toggle button // trying to fix an angular framework issue...
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'RYST Portal' );
		if ( this.auth.isSignedIn() ) {
			this.subAccountReSync = ServiceAppEvents.listen( 'account:re-sync' ).subscribe( (_:InterfaceAppEvent): void => {
				this.fetchAccountInfo();
			} );
			this.subCardReSync = ServiceAppEvents.listen( 'card:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchCardInfo();
			} );
			this.subProfilesUpdated = this.colProfiles.updated.subscribe( (): void => {
				this.fetchUserInfo();
			} );
			this.subUserReSync = ServiceAppEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchUserInfo();
			} );
			this.fetchMemberEntitlements();
		}
		this.subLanguageChanged = ServiceAppEvents.listen( 'language:changed' ).subscribe( (E: InterfaceAppEvent<InterfaceLanguageChangeEvent>): void => {
			const lang: string = E.data?.langCode ?? 'en'; // TODO: this.
			this.monthLabels = TransformerDate.getCalendarMonths( lang );
		} );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchAccountInfo();
			this.fetchCardInfo();
			this.fetchUserInfo();
		} else {
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}

	public ngOnDestroy(): void {
		if ( this.subAccountReSync ) {
			this.subAccountReSync.unsubscribe();
			this.subAccountReSync = null;
		}
		if ( this.subCardReSync ) {
			this.subCardReSync.unsubscribe();
			this.subCardReSync = null;
		}
		if ( this.subProfilesUpdated ) {
			this.subProfilesUpdated.unsubscribe();
			this.subProfilesUpdated = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
		if ( this.subLanguageChanged ) {
			this.subLanguageChanged.unsubscribe();
			this.subLanguageChanged = null;
		}
	}

	private setCashlessToggleBox( b: boolean ): void {
		// angular won't update the child component because the @Input() doesn't see a change.
		// it looks at references rather than values, and the variable's reference didn't change.
		this.primaryAccountHolder.cashlessSpending = b;
		console.log( 'cashless spending is now', this.primaryAccountHolder.cashlessSpending );
		setTimeout( (): void => {
			console.log( 'set cashless to', this.primaryAccountHolder.cashlessSpending );
		}, 1400 );
		this.recycleTB = false;
		setTimeout( (): void => {
			this.recycleTB = true;
		}, 10 );
	}

	private fetchAccountInfo(): void { // this doesn't seem to be working anymore? it never finds it, despite constantly re-creating a new account doclet
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		const ac: InterfaceAppContext = this.appConfig.getContext();
		const portalRealmID: string = this.appConfig.getRealmID( 'Portal' );
		this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
			if ( userProfile && userProfile.account_id ) {
				this.owapi.workspace.doclets.getDocletsByTemplateID( ac, this.strAccountTemplateID, 'realm.aid:{id}' + userProfile.account_id.$oid ).subscribe( (response: InterfaceHTTPGateway): void => {
					if ( response && response.success ) {
						const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
						if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
							this.accountDoclet = apiResponse.data.items[0];
							if ( this.accountDoclet && this.accountDoclet.data && this.accountDoclet.data['cashless_spending'] && this.cardsOnFile.length > 0 ) {
								this.setCashlessToggleBox( true );
							} else {
								this.setCashlessToggleBox( false );
							}
							console.log( 'account doc', this.accountDoclet );
						}
					}
					this.haveAccountInfo = true;
					if ( !this.accountDoclet ) {
						// cheating, this ought to create one..
						this.owapi.workspace.actions.core.getSavedPaymentMethod( ac, profileID, portalRealmID ).subscribe( (r: InterfaceHTTPGateway): void => console.log( 'cache-miss: created account doclet', r ) );
					}
				} );
			} else {
				console.log( 'No user account ID, cannot fetch account data.' );
				// cheating, this ought to create one..
				this.owapi.workspace.actions.core.getSavedPaymentMethod( ac, profileID, portalRealmID ).subscribe( (r: InterfaceHTTPGateway): void => console.log( 'created account doclet', r ) );
				this.haveAccountInfo = true;
			}
		} );
	}

	private fetchCardInfo(): void {
		const ac: InterfaceAppContext = this.appConfig.getContext();
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID !== null ) {
			this.owapi.workspace.actions.core.getSavedPaymentMethod( ac, profileID, this.appConfig.getRealmID( 'Portal' ) ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success ) {
					this.cardsOnFile = [];
					const apiResponse: InterfaceOWAPICardVaultResponse = response.data;
					if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
						this.cardsOnFile = apiResponse.data.items;
						console.log( 'cards on file', this.cardsOnFile );
						if ( this.accountDoclet ) {
							if ( this.cardsOnFile.length > 0 && this.accountDoclet.data['cashless_spending'] ) {
								this.setCashlessToggleBox( true );
							}
							if ( this.cardsOnFile.length < 1 || !this.accountDoclet.data['cashless_spending'] ) {
								this.setCashlessToggleBox( false );
							}
						} else { // cheating.. it may not have existed until we tried to fetch the card info
							console.log( 'cache miss, re-fetching account...' );
							this.fetchAccountInfo();
						}
					}
				}
			} );
		}
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData.data ) {
				this.haveUserInfo = true;
				const templateConsumer: InterfaceOWTemplateConsumer = userData.data as InterfaceOWTemplateConsumer;
				const userProfileData: InterfaceOWUserProfileData | undefined = userData.profile; // why is .profile suddenly missing ?!?
				this.mastHeading = 'Hello, ' + templateConsumer.first_name + ' ' + templateConsumer.last_name;
				this.primaryAccountHolder.consumerDocletID = userData.doclet_id;
				this.primaryAccountHolder.firstName = userProfileData?.first_name ? userProfileData?.first_name : '';
				this.primaryAccountHolder.lastName = userProfileData?.last_name ? userProfileData?.last_name : '';
				this.primaryAccountHolder.photo = templateConsumer.photo ? templateConsumer.photo : '';
				// account holders don't have a serial code. family members do that have a ticket/season-pass.
				// maybe in the future they will... or maybe they'll only create family members... not sure.
			}
		} );
	}

	private fetchMissingEventDoclets(): void {
		const missingEventDoclets: string[] = this.memberEntitlementsByEventDateMember.eventIDs.filter( (eventID: string): boolean => {
			return !(eventID in this.eventDocletCache);
		} );
		for ( let x: number = 0; x < missingEventDoclets.length; ++x ) {
			this.owapi.workspace.doclets.getDocletByID( this.appConfig.getContext(), missingEventDoclets[x] ).subscribe( (responseGetEventDoclet: InterfaceHTTPGateway<InterfaceOWAPIGetDocletResponse<InterfaceOWTemplateVenueEvent>>): void => {
				if ( responseGetEventDoclet.success ) {
					const apiResponseGetEventDoclet: InterfaceOWAPIGetDocletResponse<InterfaceOWTemplateVenueEvent> | undefined = responseGetEventDoclet.data;
					if ( apiResponseGetEventDoclet && apiResponseGetEventDoclet.data._id ) {
						this.eventDocletCache[ apiResponseGetEventDoclet.data._id.$oid ] = apiResponseGetEventDoclet.data;
						// fail - cannot fetch the venue location, starting from the venue event.
						/*
						this.owapi.workspace.doclets.getWeavesByDocletID( this.appConfig.getContext(), apiResponseGetEventDoclet.data._id.$oid, false, false ).subscribe( (responseGetEventWeaves: InterfaceHTTPGateway<InterfaceOWAPIGetWeavesResponse>): void => {
							if ( responseGetEventWeaves.success ) {
								const apiResponseGetEventWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetEventWeaves.data;
								if ( apiResponseGetEventWeaves && Array.isArray( apiResponseGetEventWeaves?.data?.items ) ) {
									const eventWeaves: InterfaceOWWeaveV2[] = apiResponseGetEventWeaves.data.items;
									eventWeaves.filter( (W: InterfaceOWWeaveV2): boolean => {
										return W.t_id.$oid === this.strVenueLocationTemplateID;
									} ).forEach( (W: InterfaceOWWeaveV2): void => {
										// the venue location wasn't a child to the venue event.
										// it was the other way around, fail.
										// this.owapi.workspace.doclets.getDocletByID( ... ).subscribe( ... );
									} );
								}
							}
						} );
						*/
					}
				}
			} );
		} // end for each missing doclet ID
	}

	private buildEntitlementDisplayDataByEvent(): void {
		this.memberEntitlementsByEventDateMember = TransformerEventTicket.memberEntitlementsSortedByEventDateMember( this.memberEntitlements );
		this.fetchMissingEventDoclets();
	}

	private fetchMemberEntitlements(): void {
		this.consumerEntitlementsLoaded = false;
		this.owapi.account.profile.getUserProfile().subscribe( (responseUserProfile: InterfaceHTTPGateway): void => {
			let purchaserConsumerID: string | null = null;
			if ( responseUserProfile.success ) {
				const apiResponse: InterfaceOWAPIGetUserProfileResponse = responseUserProfile.data;
				if ( apiResponse?.data?.doclet_id ) {
					purchaserConsumerID = apiResponse.data.doclet_id;
				}
			}
			if ( purchaserConsumerID === null ) {
				console.error( 'Failed to fetch the users Consumer doclet. Cannot obtain order history. Giving up.' );
				this.consumerEntitlementsLoaded = true;
			} else {
				this.owapi.workspace.actions.core.getMemberEntitlements( this.appConfig.getContext(), purchaserConsumerID ).subscribe( (responseMemberEntitlements: InterfaceHTTPGateway<InterfaceOWAPIGetMemberEntitlementsActionResponse>): void => {
					if ( responseMemberEntitlements.success ) {
						const apiResponse: InterfaceOWAPIGetMemberEntitlementsActionResponse | undefined = responseMemberEntitlements.data;
						if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
							// TODO: is it always an array of length 1?
							this.memberEntitlements = apiResponse.data.items;
							this.buildEntitlementDisplayDataByEvent();
							this.consumerEntitlementsLoaded = true;
							console.log( 'member entitlements', this.memberEntitlements );
						}
					}
				} );
			}
		} );
	}

	public showCCModal(): void {
		ServiceAppEvents.broadcast( 'modal:open:credit-card' );
	}

	public cashlessToggle( b: boolean ): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		const portalRealmID: string = this.appConfig.getRealmID( 'Portal' );
		if ( this.accountDoclet && this.cardsOnFile.length > 0 ) {
			const ac: InterfaceAppContext = this.appConfig.getContext();
			console.log( 'setting cashless toggle to', b );
			this.owapi.workspace.actions.core.toggleCashlessSpending( ac, profileID, b, portalRealmID ).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetDocletsResponse>): void => {
				let failed: boolean = true;
				if ( response && response.success ) {
					const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data; // seems to be a account doclet now, instead of a card vault doclet.
					if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
						failed = false;
						const latestCashlessValue: boolean = apiResponse.data.items?.[0]?.data?.['cashless_spending'] ?? false;
						this.setCashlessToggleBox( latestCashlessValue );
					}
				}
				if ( failed ) {
					this.setCashlessToggleBox( false );
				}
			} );
		} else {
			this.setCashlessToggleBox( false );
			this.showCCModal();
		}
	}

	public initQRCodeDisplay( visitData: InterfaceMemberEntitlementsByMemberAdmission ): void {
		this.admissionQRCodesByConsumer = {
			consumerIDs: visitData.consumerIDs,
			consumerData: visitData.consumerData,
			needToSwipe: false
		};
		let count: number = 0;
		for ( let x: number = 0; x < visitData.consumerIDs.length; ++x ) {
			count += visitData.consumerData[ visitData.consumerIDs[x] ].admissions.length;
		}
		this.admissionQRCodesByConsumer.needToSwipe = count > 1;
		this.viewingAdmissionQRCodes = true;
	}

	public buildAdmissionQRCodeData( admissionQRCode: InterfaceOWDoclet<InterfaceAdmissionQRCode> ): string {
		return JSON.stringify( {
			_id: admissionQRCode._id.$oid
		}, null, 0 );
	}

	public hideQRCode(): void {
		this.activeQR = '';
		this.activeQRTitle = '';
	}
}
