import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceRegex} from '../services/regex';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerDate {
	//
	public constructor() {
		//
	}

	public static getEndOfRelativeMonth( d: Date, offset: number ): Date {
		const output: Date = new Date( d );
		output.setMonth( output.getMonth() + offset );
		output.setDate( 0 ); // this is basically the 1st of the month, but then - 1 day.
		output.setHours( 23, 59, 59, 999 );
		return output;
	}

	public static getEndOfMonth( d: Date ): Date {
		return TransformerDate.getEndOfRelativeMonth( d, 1 );
	}

	public static get1stOfRelativeMonth( d: Date, offset: number ): Date {
		const output: Date = new Date( d );
		output.setMonth( output.getMonth() + offset );
		output.setDate( 1 );
		output.setHours( 0, 0, 0, 0 );
		return output;
	}

	public static get1stOfNextMonth( d: Date ): Date {
		return TransformerDate.get1stOfRelativeMonth( d, 1 );
	}

	public static strYYYYMM1DD( d?: Date ): string {
		const date: Date = d ? d : new Date();
		return String( date.getFullYear() ) + '-' + String( '0' + (1 + date.getMonth()) ).slice( -2 ) + '-' + String( '0' + date.getDate() ).slice( -2 );
	}

	public static strYYYYMMDDFromYMDInts( year: number, month1: number, day: number ): string {
		return String( '0000' + year ).slice( -4 ) + '-' + String( '00' + month1 ).slice( -2 ) + '-' + String( '00' + day ).slice( -2 );
	}

	public static dateFromYYYYMM1DD( YYYYMM1DD: string ): Date {
		const parts: string[] = YYYYMM1DD.split( /-/g );
		const output = new Date( 0 );
		if ( parts.length === 3 ) {
			output.setFullYear( Number.parseInt( parts[0], 10 ), Number.parseInt( parts[1], 10 ) - 1, Number.parseInt( parts[2], 10 ) );
		}
		return output;
	}

	public static MMDDYYFromYYYYMMDD( YYYYMM1DD: string ): string {
		if ( /^\d\d\d\d-\d\d-\d\d$/.test( YYYYMM1DD ) ) {
			const arr: string[] = YYYYMM1DD.split( /-/g );
			return arr[1] + '/' + arr[2] + '/' + arr[0].slice( -2 );
		}
		return '';
	}

	public static strDisplayMDYFromYYYYMM1DD( YYYYMM1DD: string ): string { // seems checkout and the dashboard use this to format large date-string into a small-space
		// values are intended to be in YYYY-MM-DD format.
		// but also can be the value "any".
		if ( YYYYMM1DD === 'any' ) {
			return '--';
		}
		if ( /^\d\d\d\d-\d\d-\d\d$/.test( YYYYMM1DD ) ) {
			const arr: string[] = YYYYMM1DD.split( /-/g );
			return arr[1] + '/' + arr[2] + '/' + arr[0];
		}
		return 'N/A';
	}

	public static strEventDateDisplayFromYYYYMMD1DD( YYYYMM1DD: string ): string {
		// YYYYMM1DD should be one of: 'any', 'event', or the common YYYY-MM-DD format.
		switch ( YYYYMM1DD.toLowerCase() ) {
			case 'any':  {
				return 'Any';
			}
			case 'event': {
				return 'Event';
			}
			default: {
				if ( ServiceRegex.YYYYMMDDExp.test( YYYYMM1DD ) ) {
					const arr: string[] = YYYYMM1DD.split( /-/g );
					return arr[1] + '/' + arr[2] + '/' + arr[0];
				}
				return 'N/A';
			}
		}
	}

	public static getCalendarMonths( lang: string = 'en' ): string[] {
		switch ( lang.toLowerCase() ) {
			default:
			case 'eng':
			case 'en': {
				return [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
			}
		}
	}
}
