import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
//
@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.html',
	styleUrls: [
		'./date-picker.less'
	]
})
export class ComponentDatePicker implements OnInit {
	@Input() public strPreSelectedDate: string = ''; // format is YYYY-MM-DD where the MM is 01 to 12
	@Input() public strBeginDateYYYYMM1DD: string | undefined = undefined;
	@Input() public strEndDateYYYYMM1DD: string | undefined = undefined;
	@Input() public enforceDateBoundaries: boolean = true; // usually false, when doing a demo.
	@Output() public dateChanged: EventEmitter<string | undefined> = new EventEmitter<string | undefined>(); // outputs YYYY-MM-DD where MM is 01 to 12
	// ===== Calendar UI ===== //
	public selectedYear: number = 0;
	public selectedMonth1: number = 1;
	public selectedDay: number = 0;
	public noGoingBack: boolean = true;
	public noGoingForward: boolean = true;
	// ===== Calendar Cells ===== //
	public leadingBlanks: undefined[] = []; // amount of days to leave blank, before the 1st of the month shows up.
	public daysInMonth: undefined[] = [];
	public trailingBlanks: undefined[] = []; // remaining calendar cells after the end of the month.
	//
	private readonly regexpYYYYMMDD: RegExp = /^\d\d\d\d-\d\d-\d\d$/;
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	public boundaryDateStart: number | undefined; // YYYYMMDD as Int
	public boundaryDateEnd: number | undefined; // YYYYMMDD as Int
	//
	public constructor() {
		//
	}

	public ngOnInit(): void {
		if ( typeof this.strBeginDateYYYYMM1DD === 'string' && this.regexpYYYYMMDD.test( this.strBeginDateYYYYMM1DD ) ) {
			const arr: string[] = this.strBeginDateYYYYMM1DD.split( /-/g );
			this.boundaryDateStart = Number( arr[0] ) * 10000 + Number( arr[1] ) * 100 + Number( arr[2] );
			console.log( 'Start Date', this.boundaryDateStart );
		}
		if ( typeof this.strEndDateYYYYMM1DD === 'string' && this.regexpYYYYMMDD.test( this.strEndDateYYYYMM1DD ) ) {
			const arr: string[] = this.strEndDateYYYYMM1DD.split( /-/g );
			this.boundaryDateEnd = Number( arr[0] ) * 10000 + Number( arr[1] ) * 100 + Number( arr[2] );
			console.log( 'End Date', this.boundaryDateEnd );
		}
		if ( this.regexpYYYYMMDD?.test?.( this.strPreSelectedDate ) ) {
			const arr: string[] = this.strPreSelectedDate.split( /-/g );
			this.selectedYear = Number( arr[0] );
			this.selectedMonth1 = Number( arr[1] );
			this.updateCalendar( this.selectedYear, this.selectedMonth1 );
			setTimeout( (): void => { // this forces any parent comp to re-sync itself. if you run it without the setTimeout, it seems to skip change detection because we're already in a change-detection cycle.
				this.setSelectedDay( Number( arr[2] ) );
			}, 0 );
		} else {
			const now: Date = new Date();
			this.updateCalendar( now.getFullYear(), now.getMonth() + 1 );
		}
	}

	public setSelectedDay( day: number ): void {
		if ( day === this.selectedDay ) {
			this.selectedDay = 0;
			this.dateChanged.emit();
		} else {
			this.selectedDay = day;
			this.dateChanged.emit( String( this.selectedYear ) + '-' + String( '0' + this.selectedMonth1 ).slice( -2 ) + '-' + String( '0' + this.selectedDay ).slice( -2 ) );
		}
	}

	public updateCalendar( year: number, month1: number ): void { // month is 1 to 12.
		this.selectedYear = year;
		this.selectedMonth1 = month1;
		this.selectedDay = 0;
		this.leadingBlanks = new Array( new Date( this.selectedYear, this.selectedMonth1 - 1, 1 ).getDay() ); // selectedMonth is 1 to 12, but Date() needs 0 to 11.
		this.daysInMonth = new Array( new Date( this.selectedYear, this.selectedMonth1, 0 ).getDate() ); // next months "last-night"
		this.trailingBlanks = new Array( Math.ceil( (this.leadingBlanks.length + this.daysInMonth.length) / 7 ) * 7 - this.leadingBlanks.length - this.daysInMonth.length );
		if ( this.enforceDateBoundaries ) {
			this.noGoingBack = typeof this.boundaryDateStart === 'number' ? this.selectedYear * 10000 + this.selectedMonth1 * 100 - 1 < this.boundaryDateStart : false;
			if ( typeof this.boundaryDateEnd === 'number' ) {
				if ( this.selectedMonth1 > 11 ) {
					this.noGoingForward = (this.selectedYear + 1) * 10000 > this.boundaryDateEnd;
				} else {
					this.noGoingForward = this.selectedYear * 10000 + (this.selectedMonth1 + 1) * 100 > this.boundaryDateEnd;
				}
			} else { // else there isn't an end-boundary, so they're allowed to go forwards...
				this.noGoingForward = false;
			}
		} else { // else is allowed to travel freely.
			this.noGoingBack = false;
			this.noGoingForward = false;
		}
		setTimeout( (): void => { // this zero-sec timeout solves an NG0100 angular error. changes made after change-detector ran, etc.
			this.dateChanged.emit( undefined ); // basically the parent comp relies upon the value that's emitted to set another var.
		}, 0 ); // and that other var is used to display data, but the change-detector already did it's thing first, causing the NG0100 error.
	}

	public prevReservationCalendarMonth(): void {
		let targetMonth: number = this.selectedMonth1 - 1;
		let targetYear: number = this.selectedYear
		if ( targetMonth < 1 ) {
			--targetYear;
			targetMonth = 12;
		}
		if ( this.enforceDateBoundaries && typeof this.boundaryDateStart === 'number' ) {
			const lastDayOfPriorMonth: number = new Date( targetYear, targetMonth, 0 ).getDate(); // 1 to 31
			if ( targetYear * 10000 + targetMonth * 100 + lastDayOfPriorMonth < this.boundaryDateStart ) {
				return; // attempted to go past the boundary
			}
		}
		this.updateCalendar( targetYear, targetMonth );
	}

	public nextReservationCalendarMonth(): void {
		let targetMonth: number = this.selectedMonth1 + 1; // months are 1 - 12.
		let targetYear: number = this.selectedYear;
		if ( targetMonth > 12 ) {
			++targetYear;
			targetMonth = 1;
		}
		if ( this.enforceDateBoundaries && typeof this.boundaryDateEnd === 'number' ) {
			if ( targetYear * 10000 + targetMonth * 100 + 1 > this.boundaryDateEnd ) {
				return; // attempted to go past the boundary
			}
		}
		this.updateCalendar( targetYear, targetMonth );
	}
}
