import {Component, EventEmitter, Input, Output} from '@angular/core';
//
@Component({
	selector: 'app-location-selector',
	templateUrl: './location-selector.html',
	styleUrls: [
		'./location-selector.less'
	]
})
export class ComponentLocationSelector {
	@Input() public ticketName: string = '';
	@Input() public countTicketsAvailable: number  = -1; // less than zero means "N/A"
	@Input() public locationIDs: string[] = []; // [ "1", "2", "3", ... ]
	@Input() public locationsLoading: boolean = false; // you need to set this to true, if the locationIDs aren't fully populated by the time this comp is shown.
	@Input() public submitButtonText: string = 'Continue';
	@Input() public showBackButton: boolean = false;
	@Output() private closeLocationPicker: EventEmitter<void> = new EventEmitter<void>();
	@Output() private pickedLocation: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
	//
	public selectedLocationID: string | undefined = undefined;
	public isLocationIDPicked: boolean = false;
	//
	public constructor() {
		//
	}

	public pickedTicketLocationID( locationID: string | undefined ): void {
		// it's possible the availability changed before the staff member picked their choice.
		if ( typeof locationID === 'string' && this.locationIDs.filter( (id: string): boolean => locationID === id ).length > 0 ) {
			this.selectedLocationID = locationID;
		} else {
			this.selectedLocationID = undefined;
		}
		this.isLocationIDPicked = typeof this.selectedLocationID !== 'undefined';
	}

	public locationConfirmed(): void {
		this.pickedLocation.emit( this.selectedLocationID );
	}

	public clearLocationPicker(): void {
		this.selectedLocationID = undefined;
		this.isLocationIDPicked = false;
		this.closeLocationPicker.emit();
	}

	protected readonly close = close;
}
