import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// ===== Interfaces ===== //
interface InterfaceHHMMSS {
	hour: number; // 0 - 23
	minutes: number; // 0 - 59
	seconds: number; // 0 - 59
}
//
@Component( {
	selector: 'app-time-picker',
	templateUrl: './time-picker.html',
	styleUrls: [
		'./time-picker.less'
	]
} )
export class ComponentTimePicker implements OnInit {
	@Input() time: string | InterfaceHHMMSS | undefined = undefined; // 'HH:MM:SS' // HH is 00 to 23
	@Input() canUnsetTime: boolean = false;
	@Output() timePicked: EventEmitter<InterfaceHHMMSS | null> = new EventEmitter<InterfaceHHMMSS | null>();
	//
	public hour: number = 0;
	public minutes: number = 0;
	public seconds: number = 0;
	public isPM: boolean = false;
	//
	public constructor() {
		//
	}

	private isIFaceHHMMSS( obj: unknown ): obj is InterfaceHHMMSS { // type narrowing using type predicates.
		return typeof obj === 'object' && obj !== null && 'hour' in obj && 'minutes' in obj && 'seconds' in obj;
	}

	public ngOnInit(): void {
		if ( typeof this.time === 'string' && /^\d\d:\d\d:\d\d?$/.test( this.time ) ) {
			const arr: string[] = this.time.split( /:/g );
			this.seconds = Number( arr[2] ) % 60;
			this.minutes = Number( arr[1] ) % 60;
			this.hour = Number( arr[0] ) % 12;
			this.isPM = Number( arr[0] ) > 11;
			//
		} else if ( this.isIFaceHHMMSS( this.time ) ) {
			this.seconds = this.time.seconds % 60;
			this.minutes = this.time.minutes % 60;
			this.hour = this.time.hour % 12;
			this.isPM = this.time.hour > 11;
		}
	}

	public incHour(): void {
		this.hour = (this.hour + 1) % 12;
	}

	public decHour(): void {
		this.hour = (this.hour + 11) % 12;
	}

	public incMinutes(): void {
		this.minutes = (this.minutes + 1) % 60;
	}

	public decMinutes(): void {
		this.minutes = (this.minutes + 59) % 60;
	}

	public incSeconds(): void {
		this.seconds = (this.seconds + 1) % 60;
	}

	public decSeconds(): void {
		this.seconds = (this.seconds + 59) % 60;
	}

	public togglePM(): void {
		this.isPM = !this.isPM;
	}

	public pickedTime(): void {
		this.timePicked.emit( {
			seconds: this.seconds,
			minutes: this.minutes,
			hour: this.hour + (this.isPM ? 12 : 0)
		} );
	}

	public removeTime(): void {
		this.timePicked.emit( null );
	}
}
