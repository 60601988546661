import {Component} from '@angular/core';
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
//
@Component( {
	selector: 'modal-liability-waiver',
	templateUrl: './liability-waiver.html',
	styleUrls: [
		'./liability-waiver.less'
	]
} )
export class ModalLiabilityWaiver {
	//
	public constructor() {
		//
	}

	public closeModal(): void {
		ServiceAppEvents.broadcast( 'modal:close:liability-waiver' );
	}
}
