import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIBulkRecordRequest,
	InterfaceOWAPIGetTemplatesResponse,
	InterfaceOWAPIPaginationRequest,
	InterfaceOWTemplate
} from '../../../../interfaces/interfaces';
interface InterfaceGetTemplatesParams {
	query? : InterfaceAnyObject;
	withoutAuth?: boolean;
	paginationOptions?: InterfaceOWAPIPaginationRequest
}
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
// ===== Transformers ===== //
import {TransformerOWAPI} from '../../../../transformers/owapi'
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspaceTemplates { // templates belong in workspaces because they cannot stand alone. templates have to be assigned to a workspace ID or they won't function.
	private readonly routePrefix: string = 'template/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Templates ========================= //

	public getTemplates( appContext: InterfaceAppContext, requestParams: InterfaceGetTemplatesParams ): Observable<InterfaceHTTPGateway> {
		// the generic 'do it yourself' function.
		const route: string = 'workspace/' + encodeURIComponent( appContext.workspaceID ) + '/templates';
		const query: string = requestParams.query ? TransformerOWAPI.createFilter( requestParams.query ) : '';
		const payload: undefined = undefined; // GET requests don't use a payload.
		const withoutAuth: boolean = !!requestParams.withoutAuth;
		return this.gateway.fetch( 'get', route + (query.length > 0 ? '?query=' + encodeURIComponent( query ) : ''), payload, withoutAuth, requestParams.paginationOptions );
	}

	private loopFetchTemplates( appContext: InterfaceAppContext, requestParams: InterfaceGetTemplatesParams, cheatyCacheUpdatedByReference: InterfaceOWAPIBulkRecordRequest<InterfaceOWTemplate>, callback: (success: boolean) => void, cursorNext? :InterfaceOWAPIPaginationRequest['cursorNext'] ): void {
		if ( cursorNext ) {
			if ( requestParams.paginationOptions ) {
				requestParams.paginationOptions.cursorNext = cursorNext;
			} else { // you need to always populate your own limit, sort, etc.. otherwise this has to do it for you and strange things can happen.
				console.log( '!! Logic error !! loopFetchTemplates -> requestParams.paginationOptions should always exist' );
				requestParams.paginationOptions = {
					cursorNext: cursorNext,
					cursorPrev: undefined,
					limit: 9999,
					sortDir: -1,
					sortKey: '_id'
				};
			}
		}
		this.getTemplates( appContext, requestParams ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIGetTemplatesResponse = response.data;
				if ( Array.isArray( apiResponse?.data?.items ) ) {
					for ( let x: number = 0; x < apiResponse.data.items.length; ++x ) {
						cheatyCacheUpdatedByReference.records.push( apiResponse.data.items[x] );
					}
					if ( apiResponse.data.have_more ) {
						this.loopFetchTemplates( appContext, requestParams, cheatyCacheUpdatedByReference, callback, apiResponse.data.next );
					} else {
						callback( true );
					}
				} else {
					console.log( 'loopFetchTemplates - Failed to acquire (response).data.items from the API', response );
				}
			} else {
				console.log( 'loopFetchTemplates - Failed to fetch templates', response );
				callback( false );
			}
		} );
	}

	public getAllTemplates( appContext: InterfaceAppContext, requestParams: InterfaceGetTemplatesParams, callback: (output: InterfaceOWAPIBulkRecordRequest<InterfaceOWTemplate>) => void ): void {
		const getTemplateParams: InterfaceGetTemplatesParams = {
			query: requestParams.query,
			withoutAuth: requestParams.withoutAuth,
			paginationOptions: {
				cursorNext: undefined,
				cursorPrev: undefined,
				limit: 9999,
				sortDir: -1,
				sortKey: '_id'
			}
		};
		const output: InterfaceOWAPIBulkRecordRequest<InterfaceOWTemplate> = {
			success: false,
			records: []
		};
		this.loopFetchTemplates( appContext, getTemplateParams, output, (success: boolean): void => {
			output.success = success;
			callback( output );
		} );
	}

	public getTemplateByID( _: InterfaceAppContext, templateId: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', 'template/' + encodeURIComponent( templateId ) );
	}

	// ========================= Weaves ========================= //

	public getWeaveSchema( template: string ): Observable<InterfaceHTTPGateway> {
		// returns an array of weave_configs
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( template ) + '/weaves' );
	}
}
