import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {
	InterfaceAddToCart, InterfaceAppEvent,
	InterfaceDocletIDToTicketProps_T,
	InterfaceEventEntitlementLock,
	InterfaceEventEntitlementUnlock,
	InterfaceEventPassAvailability,
	InterfaceEventPassPriceType,
	InterfaceEventWorkspaceChanged,
	InterfaceHTTPGateway,
	InterfaceOWAPIDailyAdmissionAvailabilityResponseV2,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetEventPassActionResponse,
	InterfaceOWDoclet,
	InterfaceOWDocletEventPassTransformed,
	InterfaceOWDocletWithEntitlement,
	InterfaceOWTemplateEventPassPriceV3,
	InterfaceOWTemplateVenueEvent
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceDocletIDToTicketProps extends InterfaceDocletIDToTicketProps_T<InterfaceEventPassPriceType> {}
interface InterfaceRoles {
	admin: string;
	pos: string;
	staff: string;
	web: string;
}
interface InterfaceYYYYMM1DD {
	year: number;
	month1: number; // 1 though 12
	day: number; // 1 through 31.
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceCart} from '../../../../../../ow-framework/services/cart';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
import {ServiceSorting} from '../../../../../../ow-framework/services/sorting';
// ===== Transformers ===== //
import {TransformerDate} from '../../../../../../ow-framework/transformers/date';
import {TransformerEventPasses} from '../../../../../../ow-framework/transformers/event-passes';
//
const earthStarStartDate: Date = new Date( 2024, 9, 25, 0, 0, 0, 0 ); // EarthStar starts on the 25th of Oct. (Friday)
const earthStarStopDate: Date = new Date( 2024, 9, 27, 0, 0, 0, 0 ); // and ends on the 27th of Oct (Sunday)
const now: Date = new Date();
//
@Component( {
	selector: 'page-tickets',
	templateUrl: './tickets.html',
	styleUrls: [
		'./tickets.less'
	]
} )
export class PageTickets implements OnDestroy, OnInit {
	private readonly selectedEventID: string = '6691aaa4aa498f64b2d2003d'; // Earthstar
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public isSignedIn: boolean = false;
	public busy: boolean = false; // true when we're doing things that need to be in order/synchronous.
	// ===== Text, Strings, Content ===== //
	public readonly strAddToCart: string = 'Select'; // 'Add To Cart';
	public readonly strSelectDate: string = 'Select Date';
	// ===== Ticket Availability ===== //
	public eventPassAvailability: InterfaceEventPassAvailability = {}; // [YYYY-MM-DD] : { location: int } // MM is 01 - 12
	public availabilityLoaded: boolean = false;
	// ===== Template IDs ===== //
	// TODO: make the complex pass obsolete by making many-entitlements on one pass, etc.
	private readonly strComplexProductTemplateID: string = this.appConfig.getTemplateID( 'Complex Product Pass' );
	// ===== Role IDs ===== //
	private readonly strWebRole: string = this.appConfig.getRoleID( 'Web' );
	private readonly roleIDs: InterfaceRoles = {
		admin: this.appConfig.getRoleID( 'Admin' ),
		pos: this.appConfig.getRoleID( 'POS' ),
		staff: this.appConfig.getRoleID( 'Staff' ),
		web: this.appConfig.getRoleID( 'Web' )
	};
	// ===== Passes For Sale ===== //
	public passIDToPassProps: { [passID: string]: InterfaceDocletIDToTicketProps; } = {};
	public multiUsePasses: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3>[] = [];
	public singleUsePasses: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3>[] = [];
	public addOnPasses: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3>[] = [];
	public complexProducts: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3>[] = [];
	// ===== Cart Items, Ticket Selection, Holdings, etc ===== //
	public showAddOverlay: boolean = false;
	public overlayBusy: boolean = false;
	public overlaySelectedPass: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3> | undefined = undefined;
	public overlayTicketSelection: { // this is a temp setup. it doesn't account for unique location IDs or anything else really.
		[passID: string]: number; // { <passID>: <qty> }
	} = {};
	public overlayTicketLocks: { // { <passID>: { "event" : [ id1, id2, ... ], { "any" : [ ... ], "2025-01-01" : [ ... ] } }
		[passID: string]: {
			[strDate: string]: string[];
		};
	} = {};
	public grandTotal: number = 0; // the cart won't hold the price tags of things, just their IDs and quantity.
	public discountAmount: number = 0;
	// ===== Discount Codes ===== //
	private subPromoCodeChanged: Subscription | null = null;
	public promoCode: string | null = null;
	public invalidPromoCode: boolean = false;
	// ===== Calendar ===== //
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	private calStartDate: Date = earthStarStartDate; // new Date( now );
	private calStopDate: Date = earthStarStopDate; // TransformerDate.getEndOfRelativeMonth( now, 2 );
	public calendarRangeStart: InterfaceYYYYMM1DD = {
		year: this.calStartDate.getFullYear(),
		month1: 1 + this.calStartDate.getMonth(),
		day: this.calStartDate.getDate()
	};
	public calendarRangeEnd: InterfaceYYYYMM1DD = {
		year: this.calStopDate.getFullYear(),
		month1: 1 + this.calStopDate.getMonth(),
		day: this.calStopDate.getDate()
	};
	// UI States
	public didPickDate: boolean = true;
	public didPickQty: boolean = false;
	public selectedDate: InterfaceYYYYMM1DD = {
		year: now.getFullYear(),
		month1: 1 + now.getMonth(),
		day: now.getDate()
	};
	// Copy
	public copyMap: {
		[key: string]: string;
	} = {
		header: 'Join Us at Earthstar Festival',
		intro: 'Get ready for an unforgettable weekend under the stars in Joshua Tree, CA! Ticketing questions? Check out our <a href="https://www.earthstarfestival.com/faq" target="_blank">FAQ page</a> or <a href="mailto:customerservice@earthstarfest.com" target="_blank">email our customer service team</a>.',
		passTerms: '',
		addonTerms: '',
		addonLegal: '',
		ticketTerms: '',
		ticketLegal: '',
		pageTitle: 'Earthstar Ticketing'
	};
	private subWorkspaceChanged: Subscription | null = null;
	//
	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.isSignedIn = this.auth.isSignedIn();
		this.title.setTitle( this.copyMap['pageTitle'] );
		this.subPromoCodeChanged = ServiceCart.promoCodeChanged.subscribe( (): void => {
			this.promoCode = ServiceCart.getPromoCode();
			console.log( 'TODO: update displayed prices' ); // TODO: weave.ext.dates : string[]
		} )
		this.subWorkspaceChanged = ServiceAppEvents.listen( 'workspace:changed' ).subscribe( (E: InterfaceAppEvent<InterfaceEventWorkspaceChanged>): void => {
			// TODO: re-fetch a new copyMap and update everything, etc..
			this.title.setTitle( this.copyMap['pageTitle'] );
		} );
		this.selectedDate = {
			year: now.getFullYear(),
			month1: 1 + now.getMonth(),
			day: now.getDate()
		};
		// fetch the selected event, then the event's passes for sale, then the passes availability.
		this.fetchEventData();
	}

	public ngOnInit(): void {
		if ( this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['promo'] === 'string' ) {
			const pCode: string = this.activeRoute.snapshot.params['promo'].replace( ServiceRegex.trimRegExp, '' );
			if ( pCode.length > 0 ) {
				ServiceCart.setPromoCode( this.activeRoute.snapshot.params['promo'] );
				console.log( 'TODO: update prices displayed' ); // but we don't know if we already fetched passes for sale or not.
			}
		}
	}

	public ngOnDestroy(): void {
		if ( this.subPromoCodeChanged ) {
			this.subPromoCodeChanged.unsubscribe();
			this.subPromoCodeChanged = null;
		}
		if ( this.subWorkspaceChanged ) {
			this.subWorkspaceChanged.unsubscribe();
			this.subWorkspaceChanged = null;
		}
	}

	private updateAvailability( passAvailability: InterfaceEventPassAvailability ): void {
		// there is no great way to remove things from the cache.
		// currently you need to just wipe it out -- this.eventPassAvailability = {};
		// and then re-request the date range all over again, which is expensive.
		// ...luckily, you kinda don't need to erase this, other than to be lighter on memory use...
		Object.keys( passAvailability ).forEach( (YYYYMMDD: string): void => {
			if ( !(YYYYMMDD in this.eventPassAvailability) ) {
				this.eventPassAvailability[YYYYMMDD] = {};
			}
			Object.keys( passAvailability[YYYYMMDD] ).forEach( (passID: string): void => {
				this.eventPassAvailability[YYYYMMDD][passID] = passAvailability[YYYYMMDD][passID];
			} );
		} );
	}

	private fetchPassAvailability( passDoclets: InterfaceOWDoclet[], startDate: Date, stopDate: Date ): void {
		const arrPassIDs: string[] = passDoclets.map( (doclet) => doclet._id.$oid );
		// cannot fetch weaves to grab capacity settings. it must come from an action.
		this.owapi.workspace.actions.core.getDailyAdmissionAvailabilityFromDateRangeV2(
			this.appConfig.getContext(),
			arrPassIDs,
			startDate,
			stopDate,
			this.selectedEventID,
			this.strWebRole
		).subscribe( (response: InterfaceHTTPGateway): void => { // InterfaceHTTPGateway<InterfaceOWAPIDailyAdmissionAvailabilityResponseV2> // is correct, but fails
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIDailyAdmissionAvailabilityResponseV2 | undefined = response.data;
				if ( (apiResponse?.data?.items ?? []).length < 1 ) {
					console.log( 'No availability from the API. Nothing to sell' );
				}
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) && apiResponse.data.items.length > 0 ) {
					// .data.items comes from a paginated setup, but there is always/only ever 1 element in the array.
					const availabilityByDate: InterfaceEventPassAvailability = apiResponse.data.items.pop() as InterfaceEventPassAvailability;
					this.updateAvailability( availabilityByDate );
					this.availabilityLoaded = true;
					console.log( 'Availability', this.eventPassAvailability );
				}
			}
		} );
	}

	private processPassDoclets( passDoclets: InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventPassPriceV3>[] ): void {
		// converts InterfaceOWDoclet into InterfaceOWDocletEventPassTransformed
		for ( let x: number = 0; x < passDoclets.length; ++x ) {
			if ( passDoclets[x].data['status'] !== 'active' ) {
				console.log( 'Found an invalid pass for sale', passDoclets[x] );
				continue;
			}
			const passID: string = passDoclets[x]._id.$oid;
			if ( !(passID in this.overlayTicketSelection) ) {
				this.overlayTicketSelection[passID] = 0;
			}
			// fixing missing properties --- BEGIN --- //
			if ( Array.isArray( passDoclets[x].data['blocked_dates'] ) ) {
				passDoclets[x].data['__blocked_dates'] = {};
				passDoclets[x].data['blocked_dates'].forEach( (blockedDate: string): void => {
					passDoclets[x].data['__blocked_dates'][ blockedDate ] = true;
				} );
			} else {
				passDoclets[x].data['blocked_dates'] = [];
				passDoclets[x].data['__blocked_dates'] = {};
			}
			if ( Array.isArray( passDoclets[x].data['dates_valid'] ) && passDoclets[x].data['dates_valid'].length > 0 ) {
				passDoclets[x].data['__datesValid'] = {}; // if __datesValid exists, it triggers the calendar to block out ALL dates, except what's in this object.
				for ( let y: number = 0; y < passDoclets[x].data['dates_valid'].length; ++y ) {
					const YYYYMMDD1: string = passDoclets[x].data['dates_valid'][y]; // YYYY-MM-DD where MM is 01-12
					passDoclets[x].data['__datesValid'][ YYYYMMDD1 ] = true; // this forces the calendar to only allow 'these' dates to be accessible.
				}
			}
			// fixing missing properties --- END --- //
			// ========================= //
			// processor makes use of .data.price
			this.passIDToPassProps[passID] = TransformerEventPasses.processPassProps( passDoclets[x], this.roleIDs, this.strComplexProductTemplateID );
			// ========================= //
			if ( passDoclets[x].template_id.$oid === this.strComplexProductTemplateID ) {
				this.complexProducts.push( passDoclets[x] as InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3> );
			} else if ( this.passIDToPassProps[passID].isAddOn ) {
				this.addOnPasses.push( passDoclets[x] as InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3> );
			} else if ( this.passIDToPassProps[passID].isAllEvent ) {
				this.multiUsePasses.push( passDoclets[x] as InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3> );
			} else { // not a bundle, add-on, nor multi-use, therefore it has to be the plain old single use.
				this.singleUsePasses.push( passDoclets[x] as InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3> );
			}
		} // end for each doclet to bucket up.
	}

	private fetchedPassesForSale( passDoclets: InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventPassPriceV3>[] ): void {
		this.singleUsePasses = [];
		this.multiUsePasses = [];
		this.addOnPasses = [];
		this.complexProducts = [];
		this.processPassDoclets( passDoclets );
		const eventPasses: InterfaceOWDoclet[][] = [
			this.singleUsePasses,
			this.multiUsePasses,
			this.addOnPasses,
			this.complexProducts
		];
		for ( let x: number = 0; x < eventPasses.length; ++x ) {
			eventPasses[x].sort( (A: InterfaceOWDoclet, B: InterfaceOWDoclet): number => {
				if ( 'sort' in A.data && 'sort' in B.data && A.data['sort'] !== B.data['sort'] ) {
					return A.data['sort'] - B.data['sort'];
				}
				return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
			} );
		}
		this.fetchPassAvailability( passDoclets, this.calStartDate, this.calStopDate );
	}

	private fetchPassesForSale(): void {
		this.owapi.workspace.actions.core.getEventPasses(
			this.appConfig.getContext(),
			this.selectedEventID,
			this.strWebRole
		).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetEventPassActionResponse>): void => {
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIGetEventPassActionResponse | undefined = response?.data;
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
					this.fetchedPassesForSale( apiResponse.data.items );
				}
			}
		} );
	}

	private fetchEventData(): void {
		this.owapi.workspace.doclets.getDocletByID( this.appConfig.getContext(), this.selectedEventID, true ).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetDocletResponse>): void => {
			if ( response.success ) {
				const apiResponse: InterfaceOWAPIGetDocletResponse = response.data;
				if ( apiResponse?.data?._id && apiResponse?.data?.data?.['start_date'] ) {
					const eventDoclet: InterfaceOWDoclet<InterfaceOWTemplateVenueEvent> = apiResponse.data as InterfaceOWDoclet<InterfaceOWTemplateVenueEvent>;
					if ( eventDoclet.data.start_date.match( /^\d\d\d\d-\d\d-\d\d$/ ) ) {
						const arrYMD: string[] = eventDoclet.data.start_date.split( /-/g );
						this.selectedDate = {
							year: Number( arrYMD[0] ),
							month1: Number( arrYMD[1] ), // it's already 1-index based, so no need to (1 + month) here...
							day: Number( arrYMD[2] )
						};
					}
				}
			}
			this.fetchPassesForSale();
		} );
	}

	private getTicketSoldCount( passID: string, year: number, month1: number, day: number ): number {
		const YYYY: string = String( year );
		const MM1: string = ('0' + month1).slice( -2 );
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD1: string = YYYY + '-' + MM1 + '-' + DD;
		return this.eventPassAvailability?.[YYYYMMDD1]?.[passID]?.item_count ?? 0;
	}

	public getEventPassPrice( passID: string, year: number, month1: number, day: number ): number {
		const passForSale: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3> = this.passIDToPassProps[passID].doclet as InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3>;
		if ( passForSale && Array.isArray( passForSale.data['price_matrix'] ) ) {
			const d: Date = new Date();
			const strPurchaseDate: string = TransformerDate.strYYYYMMDDFromYMDInts( d.getFullYear(), 1 + d.getMonth(), d.getDate() );
			const strTargetDate: string = TransformerDate.strYYYYMMDDFromYMDInts( year, month1, day );
			const soldCount: number = this.getTicketSoldCount( passID, year, month1, day );
			return TransformerEventPasses.getEventPassPriceV2( passForSale, strPurchaseDate, strTargetDate, soldCount );
		}
		return 0;
	}

	public daySelected( selectedYMD: InterfaceYYYYMM1DD ): void {
		if ( document.activeElement instanceof HTMLElement ) {
			document.activeElement.blur();
		}
		this.didPickDate = true;
		this.selectedDate = selectedYMD;
	}

	private timerGfx: {
		closeModal: number | null;
	} = {
		closeModal: null
	};
	public addToOverlay( selectedPass: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3> ): void {
		// When they add anything to the cart except a pass(?), if didPickDate is false,
		// Then we will show a date picker in a popup.
		if ( this.timerGfx.closeModal !== null ) {
			clearTimeout( this.timerGfx.closeModal );
		}
		this.overlaySelectedPass = selectedPass;
		this.overlayChangeQty( selectedPass._id.$oid, 1 );
		this.showAddOverlay = true;
		// so long as `this.overlayChangeQty( selectedPass._id.$oid, 1 );` is fired up, you don't need to calc the grand total again.
		// this.grandTotal = this.calculateOverlayTotal();
	}

	private overlayClearSelections(): void {
		const heldIDs: string[] = [];
		Object.keys( this.overlayTicketSelection ).forEach( (passID: string): void => {
			this.overlayTicketSelection[passID] = 0;
			if ( passID in this.overlayTicketLocks ) {
				Object.keys( this.overlayTicketLocks[passID] ).forEach( (strDate: string): void => {
					this.overlayTicketLocks[passID][strDate].forEach( (heldID: string): void => {
						heldIDs.push( heldID );
					} );
				} );
			}
			this.overlayTicketLocks[passID] = {};
		} );
		const unlockData: InterfaceEventEntitlementUnlock = {
			id: heldIDs
		};
		ServiceAppEvents.broadcast( {
			topic: 'entitlement:unlock',
			data: unlockData
		} );
	}

	public overlayBack(): void {
		if ( this.timerGfx.closeModal !== null ) {
			clearTimeout( this.timerGfx.closeModal );
		}
		this.timerGfx.closeModal = setTimeout( (): void => {
			this.overlaySelectedPass = undefined;
		}, 1100 );
		this.showAddOverlay = false;
		this.overlayClearSelections();
	}

	public overlayAddToCart(): void {
		if ( this.overlaySelectedPass ) {
			const yr: number = this.selectedDate.year;
			const m1: number = this.selectedDate.month1;
			const d: number = this.selectedDate.day;
			const YYYYMM1DD: string = TransformerDate.strYYYYMMDDFromYMDInts( yr, m1, d );
			ServiceCart.addToCart( Object.keys( this.overlayTicketSelection ).map( (passID: string): InterfaceAddToCart[] => {
				const output: InterfaceAddToCart[] = [];
				if ( passID in this.passIDToPassProps && this.passIDToPassProps[passID].doclet ) {
					const passForSale: InterfaceOWDocletEventPassTransformed = this.passIDToPassProps[passID].doclet as InterfaceOWDocletEventPassTransformed;
					const price: number = this.getEventPassPrice( passID, yr, m1, d );
					for ( let y: number = 0; y < this.overlayTicketSelection[passID]; ++y ) {
						output.push( {
							passForSale: passForSale,
							eventID: this.selectedEventID,
							price: price,
							spaceID: null, // TODO: this
							ticketID: this.overlayTicketLocks?.[passID]?.[YYYYMM1DD]?.[y] ?? null,
							time: null, // TODO: this?
							visitDate: {
								year: yr,
								month1: m1,
								day: d,
								isAnyDay: this.passIDToPassProps[passID].isAnyDay ?? false,
								isEventLength: this.passIDToPassProps[passID].isAllEvent ?? false
							},
							consumerData: {
								_id: undefined, // TODO: this?
								firstName: null,
								lastName: null,
								dob: null,
								phone: null
							}
						} );
					}
				}
				return output;
			} ).reduce( (output: InterfaceAddToCart[], input: InterfaceAddToCart[]): InterfaceAddToCart[] => {
				for ( let y: number = 0; y < input.length; ++y ) {
					output.push( input[y] );
				}
				return output;
			}, [] ) );
			this.overlaySelectedPass = undefined;
			this.overlayBack(); // to close the modal.
			this.grandTotal = 0;
			this.didPickQty = false;
		}
	}

	public overlayChangeQty( passID: string, qty: -1 | 1 ): void {
		if ( this.overlayBusy ) {
			return;
		}
		if ( !(passID in this.overlayTicketSelection) ) {
			this.overlayTicketSelection[passID] = 0;
		}
		if ( !(passID in this.overlayTicketLocks) ) {
			this.overlayTicketLocks[passID] = {};
		}
		const YYYYMM1DD: string = TransformerDate.strYYYYMMDDFromYMDInts( this.selectedDate.year, this.selectedDate.month1, this.selectedDate.day );
		if ( qty > 0 ) {
			this.overlayBusy = true;
			const entitlementLockData: InterfaceEventEntitlementLock = {
				eventID: this.selectedEventID,
				passID: passID,
				spaceID: null,
				date: this.selectedDate,
				roleID: this.appConfig.getRoleID( 'Web' ),
				callback: (entitlementID: string | false): void => {
					console.log( 'result of entitlement lock', entitlementID );
					if ( typeof entitlementID === 'string' ) {
						this.overlayTicketSelection[passID] = Math.max( 0, this.overlayTicketSelection[passID] + qty );
						if ( !(passID in this.overlayTicketLocks) ) {
							this.overlayTicketLocks[passID] = {};
						}
						if ( !(YYYYMM1DD in this.overlayTicketLocks[passID]) ) {
							this.overlayTicketLocks[passID][YYYYMM1DD] = [];
						}
						this.overlayTicketLocks[passID][YYYYMM1DD].push( entitlementID );
						this.didPickQty = Object.keys( this.overlayTicketSelection ).filter( (pID: string): boolean => {
							return this.overlayTicketSelection[pID] > 0;
						} ).length > 0;
						this.grandTotal = this.calculateOverlayTotal();
					} else {
						console.log( 'No availability for pass ' + passID );
						// tell the user there is no more allowed for that ticket...
						// TODO: fetch availability to refresh it.
					}
					this.overlayBusy = false;
				}
			};
			ServiceAppEvents.broadcast( {
				topic: 'entitlement:lock',
				data: entitlementLockData
			} );
		} else {
			const entitlementID: string | undefined = this.overlayTicketLocks[passID][YYYYMM1DD].shift();
			this.overlayTicketSelection[passID] = Math.max( 0, this.overlayTicketSelection[passID] + qty );
			this.didPickQty = Object.keys( this.overlayTicketSelection ).filter( (pID: string): boolean => {
				return this.overlayTicketSelection[pID] > 0;
			} ).length > 0;
			this.grandTotal = this.calculateOverlayTotal();
			if ( entitlementID !== undefined ) {
				const unlockData: InterfaceEventEntitlementUnlock = {
					id: entitlementID
				};
				ServiceAppEvents.broadcast( {
					topic: 'entitlement:unlock',
					data: unlockData
				} );
			}
		}
	}

	public calculateOverlayTotal( precision: number = 2 ): number {
		const y: number = this.selectedDate.year;
		const m1: number = this.selectedDate.month1;
		const d: number = this.selectedDate.day;
		return Number( Object.keys( this.overlayTicketSelection ).map( (passID: string): number => {
			const price: number = this.getEventPassPrice( passID, y, m1, d );
			return price * this.overlayTicketSelection[passID];
		} ).reduce( (output, input): number => {
			return output + input;
		}, 0 ).toFixed( precision ) );
	}
}
