import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceScannedTicket} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerEventTicket} from '../transformers/event-ticket';
//
@Pipe( {
	name: 'scannedTicketConsumerName',
	pure: true
} )
export class PipeScannedTicketConsumerName implements PipeTransform { // scannedTicket.consumer.data.first_name and last_name
	public transform( scannedTicket: InterfaceScannedTicket ): string {
		return TransformerEventTicket.getConsumerNameFromScannedTicket( scannedTicket );
	}
}
