<modal-back-drop id="modal-liability-waiver-wrapper">
	<span id="modal-liability-waiver" class="modal-body">
		<span class="content-wrapper">
			<span class="scroll-wrapper">
				<span class="last-updated"></span>
				<span class="segment-chapter"></span>
				<span class="segment-header"></span>
				<span class="segment-body"></span>
				<span class="segment-body">
					<ul>
						<li>1)</li>
						<li>2)</li>
						<li>3)</li>
					</ul>
				</span>
				<span class="segment-body"></span>
				<span class="actions">
					<span class="action button" (click)="closeModal();">Close</span>
				</span>
			</span>
		</span>
	</span>
</modal-back-drop>
