import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from './app.config';
import {AppRouterLinks} from './app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceEventEntitlementLock,
	InterfaceEventEntitlementUnlock,
	InterfaceEventWorkspaceChanged,
	InterfaceHTTPGateway,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWAPIGetUserProfileResponse,
	InterfaceOWAPIReserveItemResponse,
	InterfaceOWDoclet,
	InterfaceOWUser
} from '../../../../ow-framework/interfaces/interfaces';
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../ow-framework/services/navigate';
import {ServiceOWAPI} from '../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../ow-framework/services/regex';
//
@Component( {
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [
		'./app.component.less'
	],
	encapsulation: ViewEncapsulation.None
} )
export class AppComponent implements OnDestroy, OnInit {
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public isSignedIn: boolean = false;
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly router: Router
	) {
		// TODO: have an AppEvent that sends/recv's whether or not the app is loaded. (whether or not the workspace_id was successfully picked up)
		const domain: string = window.location.hostname;
		this.owapi.realm.realms.getRealmDocletsByKVP( this.appConfig.getContext().appRealmID, 'fqdn', domain, true ).subscribe( (response: InterfaceHTTPGateway<InterfaceOWAPIGetDocletsResponse>): void => {
			if ( response.success ) {
				const apiResponse: InterfaceOWAPIGetDocletsResponse | undefined = response.data;
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
					for ( let x: number = 0; x < apiResponse.data.items.length; ++x ) {
						if ( apiResponse.data.items[x]?.data?.['fqdn'] === domain ) {
							console.log( 'Changing workspace to ' + apiResponse.data.items[x].workspace_id.$oid );
							const workspaceChanged: InterfaceEventWorkspaceChanged = {
								workspaceID: apiResponse.data.items[x].workspace_id.$oid
							};
							this.appConfig.setWorkspaceID( workspaceChanged.workspaceID );
							ServiceAppEvents.broadcast( {
								topic: 'workspace:changed',
								data: workspaceChanged
							} );
							break;
						}
					}
				}
			}
		} );
		this.initModalCreditCard();
		this.initModalInviteGroupMember();
		this.initModalEditGroupMember();
		this.initModalLiabilityWaiver();
		this.initModalTinySignIn();
		this.initTicketLocks();
		this.initUserSignedIn();
	}

	public ngOnInit(): void {
		this.auth.deserialize();
		if ( this.auth.isSignedIn() ) {
			this.owapi.account.profile.getUserProfile().subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success && response.status === 200 ) {
					const apiResponse: InterfaceOWAPIGetUserProfileResponse = response.data;
					if ( apiResponse && apiResponse.data ) {
						this.colProfiles.cacheUserProfiles( [ apiResponse.data ] );
					}
					this.runAtSignIn();
				} else {
					this.auth.clear();
					this.nav.toURL( '/' + this.routes.signIn );
				}
			} );
		} else {
			// ===== //
			//  BUG  //
			// ===== //
			// Angular's this.router.url is only created once the app settles.
			// the documentation says it's the current URL, but this is false.
			// it's always "/" because the root component (where we are right now) hasn't finished yet.
			// ...once navigating ends (it's somewhere after the root comp is created), then this.router.url is useful.
			// you can't use any of the life-cycle hooks, either. ...because the URL is set long after all of these.
			// ===== //
			// so far, the solution is to make every single page kick you out, if you're not signed in, or need to verify your email.
			// console.log( 'ngOnInit -- Browser says URL is: "' + window.location.pathname + '"  -- vs --  Angular: "' + this.router.url + '"' );
		}
	}

	// ===== Credit Card Modal ===== //
	public showCreditCard: boolean = false;
	private subModalOpenCreditCard: Subscription | null = null;
	private subModalCloseCreditCard: Subscription | null = null;
	private initModalCreditCard(): void {
		this.subModalOpenCreditCard = ServiceAppEvents.listen( 'modal:open:credit-card' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showCreditCard = true;
		} );
		this.subModalCloseCreditCard = ServiceAppEvents.listen( 'modal:close:credit-card' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showCreditCard = false;
		} );
	}

	// ===== Invite Group Member ===== //
	public showInviteGroupMember: boolean = false;
	private subModalOpenInviteGroupMember: Subscription | null = null;
	private subModalCloseInviteGroupMember: Subscription | null = null;
	private initModalInviteGroupMember(): void {
		this.subModalOpenInviteGroupMember = ServiceAppEvents.listen( 'modal:open:invite-group-member' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showInviteGroupMember = true;
		} );
		this.subModalCloseInviteGroupMember = ServiceAppEvents.listen( 'modal:close:invite-group-member' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showInviteGroupMember = false;
		} );
	}

	// ===== Edit Group Member ===== //
	public showEditGroupMember: boolean = false;
	public editGroupMemberDoclet: InterfaceOWDoclet | null = null;
	private subModalOpenEditGroupMember: Subscription | null = null;
	private subModalCloseEditGroupMember: Subscription | null = null;
	private initModalEditGroupMember(): void {
		this.subModalOpenEditGroupMember = ServiceAppEvents.listen( 'modal:open:edit-group-member' ).subscribe( (event: InterfaceAppEvent): void => {
			if ( event.data && event.data.memberDoclet && event.data.memberDoclet._id ) {
				this.editGroupMemberDoclet = event.data.memberDoclet;
			}
			this.showEditGroupMember = true;
		} );
		this.subModalCloseEditGroupMember = ServiceAppEvents.listen( 'modal:close:edit-group-member' ).subscribe( (_: InterfaceAppEvent): void => {
			this.editGroupMemberDoclet = null;
			this.showEditGroupMember = false;
		} );
	}

	// ===== Liability Waiver Modal ===== //
	public showLiabilityWaiver: boolean = false;
	private subModalOpenLiabilityWaiver: Subscription | null = null;
	private subModalCloseLiabilityWaiver: Subscription | null = null;
	private initModalLiabilityWaiver(): void {
		this.subModalOpenLiabilityWaiver = ServiceAppEvents.listen( 'modal:open:liability-waiver' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showLiabilityWaiver = true;
		} );
		this.subModalCloseLiabilityWaiver = ServiceAppEvents.listen( 'modal:close:liability-waiver' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showLiabilityWaiver = false;
		} );
	}

	// ===== Tiny Sign-In Modal ===== //
	public showTinySignIn: boolean = false;
	private subModalOpenTinySignIn: Subscription | null = null;
	private subModalCloseTinySignIn: Subscription | null = null;
	private initModalTinySignIn(): void {
		this.subModalOpenTinySignIn = ServiceAppEvents.listen( 'modal:open:tiny-sign-in' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showTinySignIn = true;
		} );
		this.subModalCloseTinySignIn = ServiceAppEvents.listen( 'modal:close:tiny-sign-in' ).subscribe( (_: InterfaceAppEvent): void => {
			this.showTinySignIn = false;
		} );
	}

	// ===== User Logged-in ===== //
	private subUserSignedIn: Subscription | null = null;
	private subUserSignedOut: Subscription | null = null;
	private initUserSignedIn(): void {
		this.subUserSignedIn = ServiceAppEvents.listen( 'user:signed-in' ).subscribe( (_: InterfaceAppEvent): void => {
			console.log( 'User signed in.' );
			this.runAtSignIn();
		} );
		this.subUserSignedOut = ServiceAppEvents.listen( 'user:signed-out' ).subscribe( (_: InterfaceAppEvent): void => {
			console.log( 'User signed out.' );
			this.runAtSignOut();
		} );
	}

	// ===== Ticket Locks ===== //
	private subEntitlementLock: Subscription | null = null;
	private subEntitlementUnlock: Subscription | null = null;
	private initTicketLocks(): void {
		this.subEntitlementLock = ServiceAppEvents.listen( 'entitlement:lock' ).subscribe( (E: InterfaceAppEvent<InterfaceEventEntitlementLock>): void => {
			const lockData: InterfaceEventEntitlementLock | undefined = E.data;
			if ( lockData ) {
				const ac: InterfaceAppContext = this.appConfig.getContext();
				let y: number = 0;
				let m1: number = 0;
				let d: number = 0;
				if ( typeof lockData.date === 'string' ) {
					if ( ServiceRegex.YYYYMMDDExp.test( lockData.date ) ) {
						const arrYMD: string[] = lockData.date.split( /-/i );
						y = Number.parseInt( arrYMD[0], 10 );
						m1 = Number.parseInt( arrYMD[1], 10 );
						d = Number.parseInt( arrYMD[2], 10 );
					} else if ( lockData.date === 'event' ) {
						//
					} else if ( lockData.date === 'any' ) {
						//
					} else {
						console.trace( 'Unknown date format used on getTicketLock', lockData.date );
						return lockData.callback( false );
					}
				} else { // else is an object
					y = lockData.date.year;
					m1 = lockData.date.month1;
					d = lockData.date.day;
				}
				this.owapi.workspace.actions.core.reserveTicket( // 6 params
					ac,
					lockData.eventID,
					lockData.passID,
					lockData.spaceID ?? null,
					lockData.roleID,
					{ year: y, month1: m1, day: d }
				).subscribe( (response: InterfaceHTTPGateway): void => {
					if ( response.success ) {
						const apiResponse: InterfaceOWAPIReserveItemResponse | undefined = response.data;
						if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
							// if it's in stock, it'll be a string, otherwise it'll be false.
							return lockData.callback( apiResponse.data.items.pop()?.item_id ?? false );
						}
					}
					return lockData.callback( false );
				} );
			} else {
				console.error( 'Entitlement Lock was invoked, but no data exists.', E );
			}
		} );
		this.subEntitlementUnlock = ServiceAppEvents.listen( 'entitlement:unlock' ).subscribe( (E: InterfaceAppEvent<InterfaceEventEntitlementUnlock>): void => {
			const unlockData: InterfaceEventEntitlementUnlock | undefined = E.data;
			if ( unlockData ) {
				const entitlementIDs: string[] = Array.isArray( unlockData.id ) ? unlockData.id : [ unlockData.id ];
				void this.owapi.workspace.actions.core.removeTicketReservation( this.appConfig.getContext(), entitlementIDs ).subscribe( (_): void => {} );
			} else {
				console.log( 'Entitlement Unlock was invoked, but no data exits.', E );
			}
		} );
	}

	public ngOnDestroy(): void {
		// ===== Credit Card Modal ===== //
		if ( this.subModalOpenCreditCard ) {
			this.subModalOpenCreditCard.unsubscribe();
			this.subModalOpenCreditCard = null;
		}
		if ( this.subModalCloseCreditCard ) {
			this.subModalCloseCreditCard.unsubscribe();
			this.subModalCloseCreditCard = null;
		}
		// ===== Edit Group Member ===== //
		if ( this.subModalOpenEditGroupMember ) {
			this.subModalOpenEditGroupMember.unsubscribe();
			this.subModalOpenEditGroupMember = null;
		}
		if ( this.subModalCloseEditGroupMember ) {
			this.subModalCloseEditGroupMember.unsubscribe();
			this.subModalCloseEditGroupMember = null;
		}
		// ===== Invite Group Member ===== //
		if ( this.subModalOpenInviteGroupMember ) {
			this.subModalOpenInviteGroupMember.unsubscribe();
			this.subModalOpenInviteGroupMember = null;
		}
		if ( this.subModalCloseInviteGroupMember ) {
			this.subModalCloseInviteGroupMember.unsubscribe();
			this.subModalCloseInviteGroupMember = null;
		}
		// ===== Liability Waiver Modal ===== //
		if ( this.subModalOpenLiabilityWaiver ) {
			this.subModalOpenLiabilityWaiver.unsubscribe();
			this.subModalCloseLiabilityWaiver = null;
		}
		if ( this.subModalCloseLiabilityWaiver ) {
			this.subModalCloseLiabilityWaiver.unsubscribe();
			this.subModalCloseLiabilityWaiver = null;
		}
		// ===== Tiny Sign-In Modal ===== //
		if ( this.subModalOpenTinySignIn ) {
			this.subModalOpenTinySignIn.unsubscribe();
			this.subModalCloseTinySignIn = null;
		}
		if ( this.subModalCloseTinySignIn ) {
			this.subModalCloseTinySignIn.unsubscribe();
			this.subModalCloseTinySignIn = null;
		}
		// ===== User Signed-in ===== //
		if ( this.subUserSignedIn ) {
			this.subUserSignedIn.unsubscribe();
			this.subUserSignedIn = null;
		}
		if ( this.subUserSignedOut ) {
			this.subUserSignedOut.unsubscribe();
			this.subUserSignedOut = null;
		}
		// ===== Entitlement Locks ===== //
		if ( this.subEntitlementLock ) {
			this.subEntitlementLock.unsubscribe();
			this.subEntitlementLock = null;
		}
		if ( this.subEntitlementUnlock ) {
			this.subEntitlementUnlock.unsubscribe();
			this.subEntitlementUnlock = null;
		}
	}

	private runAtSignIn(): void {
		this.isSignedIn = true;
		ServiceAppEvents.broadcast( 'user:re-sync' ); // tell various places to go update their cache's, if any.
		this.colProfiles.getMyUserProfile( (user: InterfaceOWUser | null): void => {
			// console.log( 'runAtSignIn A -- Browser: "' + window.location.pathname + '"  -- vs --  Angular: "' + this.router.url + '"' );
			if ( user ) {
				if ( !user.confirmed ) {
					setTimeout( (): void => { // forcing the "enough time has passed" to cause the router.url logic to behave.
						// console.log( 'runAtSignIn B -- Browser: "' + window.location.pathname + '"  -- vs --  Angular: "' + this.router.url + '"' );
						// they need to travel to /registration/check-email, but not if they're already there...
						if ( !this.router.url.match( /^\/registration\/check-email/ ) ) {
							// normally this.router.url is just "/", but enough time has passed due to making a network request, so now angular has the correct router.url for its value.
							this.nav.toURL( '/' + this.routes.checkEmail );
						}
					}, 0 );
				}
			} // else the user cannot be signed in then o.O
		} );
		//
	}

	private runAtSignOut(): void {
		this.auth.clear();
		this.isSignedIn = false;
		this.nav.toURL( '/' + this.routes.signIn );
		this.colProfiles.clear();
		ServiceAppEvents.broadcast( 'user:re-sync' );
	}
}
