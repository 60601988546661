import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceEventPassPriceType} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerEventTicket} from '../transformers/event-ticket';
//
@Pipe( {
	name: 'passPriceTime',
	pure: true
} )
export class PipePassPriceTime implements PipeTransform {
	// passDoclet.data['price'] | passPriceTime : '2023-06-06'
	public transform( ticketPricing: InterfaceEventPassPriceType | undefined, selectedYYYYMMDD1?: string ): string {
		return TransformerEventTicket.getTicketTimeDisplayFromPriceType( ticketPricing, selectedYYYYMMDD1 );
	}
}
