import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
// ===== Configs ===== //
import {ConfigOWEnvironmentIDs} from '../../../environment-ids/ow-live-ids';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceObjectDate,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWDoclet,
	InterfaceOWWeaveV2
} from '../../../../ow-framework/interfaces/interfaces';
interface InterfaceOrderHistoryTree {
	orderIDs: string[];
	orders: {
		[orderID: string]: {
			transactionIDs: string[];
			transactions: {
				[transactionID: string]: InterfaceOWTransaction;
			};
		};
	};
}
interface InterfaceOWTransactionDataItems {
	item: string; // Annual Adventure Pass
	price: number; // 69.42
	discount_applied?: boolean;
	date: string; // YYYY-MM-DD
}
interface InterfaceOWTransaction extends InterfaceOWDoclet {
	data: {
		ts: InterfaceObjectDate;
		action: 'CHARGE' | 'REFUND';
		card_number?: string; // 4 char's // refunds won't have this, nor will cash transaction.
		amount_base: string; // "520.14"
		amount_tender?: string; // total cash collected, not the cost of things. the existence of this field proves they paid with cash, but still use amount_base for the grand total.
		items: InterfaceOWTransactionDataItems[];
		response_status: 'approved' | 'declined';
	}
}
// ===== Services ===== //
import {ServiceOWAPI} from '../../../services/ow-api';
import {ServiceSorting} from '../../../services/sorting';
//
@Component( {
	selector: 'app-order-history',
	templateUrl: './order-history.html',
	styleUrls: [
		'./order-history.less'
	]
} )
export class ComponentOrderHistory implements OnChanges {
	@Input() purchaserDocletID: string | undefined = undefined;
	@Input() appContext: InterfaceAppContext | undefined = undefined;
	private readonly strTemplateOrder: string = this.owEnv.getTemplateID( 'Order' );
	private readonly strTemplateTransaction: string = this.owEnv.getTemplateID( 'Transaction' );
	public orderHistoryTree: InterfaceOrderHistoryTree = {
		orderIDs: [],
		orders: {}
	};
	public busy: boolean = false;
	//
	public constructor(
		private owEnv: ConfigOWEnvironmentIDs,
		private owapi: ServiceOWAPI
	) {
		//
	}

	private generateStackCookie(): string {
		return String( new Date().getTime() ) + '_' + String( Math.random() );
	}

	private clear(): void {
		this.stackCookie = this.generateStackCookie();
		this.orderHistoryTree = {
			orderIDs: [],
			orders: {}
		};
		this.busy = false;
	}

	private sortOrderHistoryTree(): void {
		// don't have the order doclet to really know when the real time the order was placed.
		this.orderHistoryTree.orderIDs.sort( ServiceSorting.naturalSort );
		for ( let x: number = 0; x < this.orderHistoryTree.orderIDs.length; ++x ) {
			const orderID: string = this.orderHistoryTree.orderIDs[x];
			this.orderHistoryTree.orders[orderID].transactionIDs.sort( (A: string, B: string): number => {
				const trxA: InterfaceOWDoclet | undefined = this.orderHistoryTree.orders[orderID].transactions[A];
				const trxB: InterfaceOWDoclet | undefined = this.orderHistoryTree.orders[orderID].transactions[B];
				if ( !trxA ) {
					return 1; // A goes after B...
				}
				if ( !trxB ) {
					return -1; // A goes before B...
				}
				return (trxA?.tc_raw?.$date ?? 0) - (trxB?.tc_raw?.$date ?? 0);
			} );
		}
	}

	private orderHistoryReloaded( orderHistory: InterfaceOrderHistoryTree ): void {
		this.orderHistoryTree = orderHistory;
		this.sortOrderHistoryTree();
	}

	private stackCookie: string = ''; // requestID, eventID, whatever.
	private reloadOrderHistory( purchaserID: string ): void {
		this.stackCookie = this.generateStackCookie();
		this.busy = true;
		const cookie: string = String( this.stackCookie ); // this ensures the async operation is for the correct/same originating consumer ID.
		const orderHistory: InterfaceOrderHistoryTree = {
			orderIDs: [],
			orders: {}
		};
		if ( !this.appContext?.workspaceID ) {
			return
		}
		const ac: InterfaceAppContext = this.appContext;
		this.owapi.workspace.doclets.getWeavesByDocletID( ac, purchaserID ).subscribe( (purchaserWeavesResponse: InterfaceHTTPGateway): void => {
			if ( cookie !== this.stackCookie ) { return; }
			if ( purchaserWeavesResponse?.success ) {
				const apiResponsePWR: InterfaceOWAPIGetWeavesResponse | undefined = purchaserWeavesResponse?.data;
				if ( apiResponsePWR && Array.isArray( apiResponsePWR?.data?.items ) ) {
					const userWeaves: InterfaceOWWeaveV2[] = apiResponsePWR.data.items;
					for ( let x: number = 0; x < userWeaves.length; ++x ) {
						switch ( userWeaves[x].t_id.$oid ) {
							case this.strTemplateOrder: {
								orderHistory.orderIDs.push( userWeaves[x].c_id.$oid );
								break;
							}
						}
					}
					// don't have the order doclet to really know when the real time the order was placed.
					orderHistory.orderIDs.sort( ServiceSorting.naturalSort );
					let remainingOrderIDs: number = orderHistory.orderIDs.length;
					if ( remainingOrderIDs < 1 ) {
						this.clear();
					}
					for ( let x: number = 0; x < orderHistory.orderIDs.length; ++x ) {
						const orderID: string = orderHistory.orderIDs[x];
						orderHistory.orders[orderID] = {
							transactionIDs: [],
							transactions: {}
						};
						this.owapi.workspace.doclets.getWeavesByDocletID( ac, orderID ).subscribe( (orderWeavesResponse: InterfaceHTTPGateway): void => {
							if ( cookie !== this.stackCookie ) { return; }
							if ( orderWeavesResponse?.success ) {
								const apiResponseOrderWeaves: InterfaceOWAPIGetWeavesResponse | undefined = orderWeavesResponse?.data;
								if ( apiResponseOrderWeaves && Array.isArray( apiResponseOrderWeaves?.data?.items ) ) {
									const orderWeaves: InterfaceOWWeaveV2[] = apiResponseOrderWeaves.data.items;
									for ( let y: number = 0; y < orderWeaves.length; ++y ) {
										switch ( orderWeaves[y].t_id.$oid ) {
											case this.strTemplateTransaction: {
												orderHistory.orders[orderID].transactionIDs.push( orderWeaves[y].c_id.$oid );
												break;
											}
										}
									}
								}
							}
							let remainingTransactionsIDs: number = orderHistory.orders[orderID].transactionIDs.length;
							if ( remainingTransactionsIDs < 1 ) { // nothing to do?
								--remainingOrderIDs; // then mark this order as done loading...
								if ( remainingOrderIDs < 1 ) { // finished with all orders?
									this.orderHistoryReloaded( orderHistory ); // then sort and prepare them for display...
									this.busy = false
								}
							}
							for ( let y: number = 0; y < orderHistory.orders[orderID].transactionIDs.length; ++y ) {
								const transactionID: string = orderHistory.orders[orderID].transactionIDs[y];
								this.owapi.workspace.doclets.getDocletByID( ac, transactionID ).subscribe( (transactionDocletResponse: InterfaceHTTPGateway): void => {
									if ( transactionDocletResponse?.success ) {
										const apiResponseTransactionDoclet: InterfaceOWAPIGetDocletResponse | undefined = transactionDocletResponse?.data;
										if ( apiResponseTransactionDoclet && apiResponseTransactionDoclet?.data?._id?.$oid ) {
											orderHistory.orders[orderID].transactions[transactionID] = apiResponseTransactionDoclet.data as InterfaceOWTransaction;
										}
									}
									if ( --remainingTransactionsIDs < 1 ) {
										if ( --remainingOrderIDs < 1 ) {
											this.orderHistoryReloaded( orderHistory );
											this.busy = false
										}
									}
								} );
							}
						} );
					}
				}
			}
		} );
	}

	private lastKnownPID: string | undefined = undefined;
	public ngOnChanges( changes: SimpleChanges ): void {
		if ( changes.hasOwnProperty( 'purchaserDocletID' ) ) {
			if ( typeof changes['purchaserDocletID'].currentValue === 'string' ) {
				if ( changes['purchaserDocletID'].currentValue !== changes['purchaserDocletID'].previousValue ) {
					this.reloadOrderHistory( changes['purchaserDocletID'].currentValue );
				}
			} else {
				this.clear();
			}
		}
	}
}
