import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceDocletIDToTicketProps,
	InterfaceEventPassPriceType,
	InterfaceEventPassPricing,
	InterfaceObjectId,
	InterfaceOWAPITicketPriceByDate,
	InterfaceOWAPITicketPriceByDatePriceTier,
	InterfaceOWDoclet,
	InterfaceOWDocletEventPassTransformed,
	InterfaceOWDocletWithEntitlement,
	InterfaceOWTemplateEventPassPriceV3,
	InterfacePriceMatrix
} from '../interfaces/interfaces';
interface InterfaceRoles {
	admin: string;
	pos: string;
	staff: string;
	web: string;
}
// ===== Services ===== //
import {ServiceRegex} from '../services/regex';
// ===== Transformers ===== //
import {TransformerDate} from './date';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerEventPasses { // intended to be used on the passes for sale, not the sold tickets.
	//
	public constructor() {
		//
	}

	public static processPassProps( eventPass: InterfaceOWDocletWithEntitlement<InterfaceOWTemplateEventPassPriceV3>, roleIDs: InterfaceRoles, strComplexProductTemplateID?: string ): InterfaceDocletIDToTicketProps {
		let roleAdmin: boolean = false;
		let rolePOS: boolean = false;
		let roleStaff: boolean = false;
		let roleWeb: boolean = false;
		(eventPass?.ow_roles ?? []).forEach( (owRole: InterfaceObjectId): void => {
			switch ( owRole.$oid ) {
				case roleIDs.admin: {
					roleAdmin = true;
					break;
				}
				case roleIDs.pos: {
					rolePOS = true;
					break;
				}
				case roleIDs.staff: {
					roleStaff = true;
					break;
				}
				case roleIDs.web: {
					roleWeb = true;
					break;
				}
			}
		} );
		return {
			name: eventPass.data['name'],
			price: eventPass.data['price'], // obsolete
			sort: eventPass.data['sort'],
			isAnyDay: undefined,
			isAllEvent: eventPass.data['is_event_length'],
			isPrimary: eventPass?.entitlement_type_data?.primary ?? false,
			isAddOn: eventPass.data['is_addon'] ?? false,
			isComplexBundle: eventPass.template_id.$oid === strComplexProductTemplateID,
			skipCapacityCheck: !eventPass.data['require_capacity'] || eventPass.data['is_any_day'],
			isPromoted: eventPass.data?.['is_promoted'] ?? false,
			isLimitPerOrder: (eventPass.data?.['limit_per_order'] ?? 0) > 0,
			role: {
				admin: roleAdmin,
				pos: rolePOS,
				staff: roleStaff,
				web: roleWeb
			},
			doclet: eventPass
		};
	}

	public static passPricesToYYYYMMDDPrice( docletData: InterfaceOWAPITicketPriceByDate[] ): InterfaceEventPassPriceType {
		// the use of this fn is now obsolete since the prices changed to a price matrix. all code referencing this will now crash.
		console.trace( 'Obsolete use of transforming .data.price into { date : ... }' ); debugger;
		const output: InterfaceEventPassPriceType = {}; // { YYYYMMDD : InterfaceEventPassPricing }
		for ( let x: number = 0; x < docletData.length; ++x ) {
			output[ docletData[x].date ] = {
				default: Number( docletData[x].price ),
				time: docletData[x].time,
				// TODO: remove sph/sphRestriction
				sph: docletData[x].hasOwnProperty( 'sph_price' ) ? Number( docletData[x].sph_price ) : null,
				sphRestriction: Array.isArray( docletData[x].sph_price_restriction )
					? docletData[x].sph_price_restriction!.reduce(
						(out: InterfaceEventPassPricing['sphRestriction'], year: string): InterfaceEventPassPricing['sphRestriction'] => {
							out[ year ] = true; // { "2022" : true }
							return out;
						}, {}
					)
					: {}, // else sph_price_restriction does not exist, so the value just defaults to {}
				priceTier: Array.isArray( docletData[x].price_tier )
					? docletData[x].price_tier!.sort( (A: InterfaceOWAPITicketPriceByDatePriceTier, B: InterfaceOWAPITicketPriceByDatePriceTier): number => {
						return A.count - B.count; // sort ASC, smallest first.
					} )
					: []
			};
		}
		return output;
	}

	public static getEventPassPrice( passForSale: InterfaceOWDocletEventPassTransformed, year: number, month1: number, day: number, soldCount: number = 0 ): number {
		// using this fn will crash things because the .data.price was moved/changed into a price matrix
		console.trace( 'Obsolete use of getting transformed prices by date' ); debugger;
		// the doclet to pass into the 1st param, must have it's (doclet).data.price transformed into the `{ "YYYY-MM-DD" : { ... } }` format.
		let passPrice: number = 0;
		const YYYY: string = String( year );
		const MM1: string = ('0' + month1).slice( -2 ); // month needs to be 01 to 12
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD1: string = YYYY + '-' + MM1 + '-' + DD;
		const dateKey: string = YYYYMMDD1 in passForSale.data.price ? YYYYMMDD1 : 'default';
		let useDefaultPrice: boolean = true;
		if ( Array.isArray( passForSale.data.price[dateKey].priceTier ) ) {
			// tiered pricing is assumed to be pre-sorted.
			const PT: InterfaceOWAPITicketPriceByDatePriceTier[] = passForSale.data.price[dateKey].priceTier as InterfaceOWAPITicketPriceByDatePriceTier[];
			for ( let x: number = 0; x < PT.length; ++x ) {
				if ( soldCount <= PT[x].count ) {
					useDefaultPrice = false;
					passPrice = Number( PT[x].price );
					break;
				}
			}
		}
		if ( useDefaultPrice ) {
			passPrice = passForSale.data.price[dateKey].default;
		}
		return passPrice;
	}

	private static getEventPassPriceFromPriceMatrix( priceMatrix: InterfacePriceMatrix, targetDateYYYYMM1DD: 'default' | string, soldCount: number ): number {
		let output: number = 0;
		if ( Array.isArray( priceMatrix.calendar_price ) ) {
			const pricesByDate: InterfaceOWTemplateEventPassPriceV3['price_matrix'][number]['calendar_price'] = priceMatrix.calendar_price;
			let targetDateFound: boolean = false;
			let theDefaultCalendarPrice: InterfaceOWTemplateEventPassPriceV3['price_matrix'][number]['calendar_price'][number] | null = null;
			for ( let y: number = 0; y < pricesByDate.length; ++y ) {
				if ( pricesByDate[y].date === 'default' ) {
					theDefaultCalendarPrice = pricesByDate[y];
				}
				if ( targetDateYYYYMM1DD === pricesByDate[y].date ) {
					targetDateFound = true;
					output = pricesByDate[y].price; // using the default date unless overridden by the price tiers.
					if ( Array.isArray( pricesByDate[y].price_tier ) ) {
						const priceTiers: InterfaceOWAPITicketPriceByDatePriceTier[] = pricesByDate[y].price_tier;
						// tiered pricing is assumed to be pre-sorted.
						for ( let z: number = 0; z < priceTiers.length; ++z ) {
							if ( priceTiers[z].count <= soldCount  ) {
								output = priceTiers[z].price;
							}
						} // end for each price tier.
					} // end if the price_tiers exist.
				} // end if we found the target date.
			} // end for each calendar date
			if ( !targetDateFound && theDefaultCalendarPrice !== null ) {
				output = theDefaultCalendarPrice.price;
			}
		}
		return output;
	}

	public static getEventPassPriceV2( passForSale: InterfaceOWDoclet<InterfaceOWTemplateEventPassPriceV3>, purchaseDateYYYYMM1DD: string, targetDateYYYYMM1DD: string, soldCount: number = 0 ): number {
		let output: number = 0;
		const pDate: Date | null = ServiceRegex.YYYYMMDDExp.test( purchaseDateYYYYMM1DD ) ? TransformerDate.dateFromYYYYMM1DD( purchaseDateYYYYMM1DD ) : null;
		if ( pDate === null ) {
			return output;
		} else {
			pDate.setHours( 11, 0, 0, 0 ); // noon
		}
		if ( Array.isArray( passForSale.data.price_matrix ) ) {
			const priceMatrix: InterfaceOWTemplateEventPassPriceV3['price_matrix'] = passForSale.data.price_matrix;
			let purchaseDateFound: boolean = false;
			let theDefaultPurchaseDateMatrix: InterfaceOWTemplateEventPassPriceV3['price_matrix'][number] | null = null;
			for ( let x = 0; x < priceMatrix.length; ++x ) {
				if ( priceMatrix[x].purchase_date === 'default' ) {
					theDefaultPurchaseDateMatrix = priceMatrix[x]; // we'll need this later in-case we don't find a match...
				} else {
					const arrPurchaseDateRange: string[] = priceMatrix[x].purchase_date.split( /:/g );
					const startDate: Date | null = arrPurchaseDateRange.length > 0 && ServiceRegex.YYYYMMDDExp.test( arrPurchaseDateRange[0] )
						? TransformerDate.dateFromYYYYMM1DD( arrPurchaseDateRange[0] )
						: null;
					let stopDate: Date | null = null;
					if ( arrPurchaseDateRange.length > 1 ) { // if there is a stop date and it wasn't just an array of length 1...
						if ( ServiceRegex.YYYYMMDDExp.test( arrPurchaseDateRange[1] ) ) {
							stopDate = TransformerDate.dateFromYYYYMM1DD( arrPurchaseDateRange[1] );
						}
					} else {
						if ( startDate !== null ) {
							stopDate = new Date( startDate );
						}
					}
					if ( startDate === null || stopDate === null ) {
						console.log( 'Discovered an invalid purchase_date on an (Event Pass).data.price_matrix[' + x + '].purchase_date => ', priceMatrix[x].purchase_date, passForSale );
						continue;
					} else {
						startDate.setHours( 0, 0, 0, 0 );
						// pDate is set to noon, so if the start & stop dates are the same Y/M/D, then pDate will still be in between start & stop dates.
						stopDate.setHours( 23, 59, 59, 999 );
					}
					let purchaseDateWithinRange: boolean = startDate.getTime() < pDate.getTime() && stopDate.getTime() > pDate.getTime();
					if ( purchaseDateWithinRange ) {
						purchaseDateFound = true;
						output = TransformerEventPasses.getEventPassPriceFromPriceMatrix( priceMatrix[x], targetDateYYYYMM1DD, soldCount );
					} // end if the purchase date is within the range of dates on the pass's purchase_date.
				} // end else we did not stumble upon the default price matrix case.
			} // end for each price matrix
			if ( !purchaseDateFound && theDefaultPurchaseDateMatrix !== null ) {
				// we didn't find a matching purchase_date and had to use the default.
				output = TransformerEventPasses.getEventPassPriceFromPriceMatrix( theDefaultPurchaseDateMatrix, targetDateYYYYMM1DD, soldCount );
			}
		} // end if the price matrix exists. this should always be true.
		return output;
	}
}
