// ===== Interfaces ===== //
import {InterfaceAnyObject} from '../angular/vanilla-js';
//
export interface InterfaceOWInviteUserInvites {
	auth: {
		email: string;
	};
	template_id: string; // the template's ID for when server-side takes the template's field's keys.
	template: InterfaceAnyObject; // { first_name: val, last_name: val, ... } // must match the template's field's keys.
}

export interface InterfaceOWInviteUser {
	profile_id: string; // the currently signed-in user's profile ID
	realm_id: string;
	workspace_id: string;
	custom_message?: string; // plain text, not HTML
	invites: InterfaceOWInviteUserInvites[];
}

export interface InterfaceOWAPIRequestCompleteInvite {
	auth: {
		password: string;
	};
	profile_id: string; // TODO: use a token instead of profile_id
	realm_id: string;
	template_id: string;
	template: InterfaceAnyObject; // { first_name: value, last_name: value, ... }
	workspace_id: string;
}

export interface InterfaceOWAPIRegisterAccount {
	auth: {
		email: string;
		password: string;
	};
	profile?: InterfaceAnyObject; // { first_name: value, last_name: value, ... }
	realm_id: string;
	template_id: string;
	template: InterfaceAnyObject; // { photo: value, zip_code: value, ... }
	app_key: string; // app_key is the workspace_id of the parent app. (sort of like the theme or app from the catalog)
	workspace_id?: string; // instance_id is the workspace_id of the instance of the app (each venue)
}
