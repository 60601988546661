// Passes are the things for sale on the portal. They're content/cosmetic.
// They're not tickets. Tickets are created when a pass is sold.
import {InterfaceAnyObject} from '../angular/vanilla-js';
import {InterfaceOWTemplateEntitlementType} from './entitlement';
import {InterfaceOWDoclet} from '../ow/doclets';

export type TypeRequiredFieldFlags = 'Required' | 'Optional' | 'Never';

export interface InterfaceOWAPITicketPriceByDatePriceTier {
	count: number; // how many tickets must be sold...
	price: number; // ...before this price tag is used.
}

export interface InterfacePriceMatrixPrice {
	date: 'default' | string; // 'YYYY-MM-DD' // this date is used to override what the calendar is showing for that day.
	price: number; // the cost to purchase an Event Pass, unless overriden by the price_tier system.
	price_tier: InterfaceOWAPITicketPriceByDatePriceTier[];
}

export interface InterfacePriceMatrix {
	purchase_date: 'default' | string; // when purchasing on this date, we should be using the calendar_price data.
	// purchase_date is one of: 'YYYY-MM-DD:YYYY-MM-DD', 'YYYY-MM-DD', 'default'
	calendar_price: InterfacePriceMatrixPrice[]; // only use this if the purchase_date matches, (or when there is no match && is the default case)
}

export interface InterfaceOWTemplateEventPass {
	blocked_dates: string[]; // [ YYYY-MM-DD, YYYY-MM-DD, ... ]
	cannot_exchange: boolean;
	dates_valid: string[]; // [ YYYY-MM-DD, YYYY-MM-DD, ... ]
	exchange_only: boolean;
	is_addon: boolean;
	is_any_day: boolean; // i forget if this is obsolete, or not, but this is where the field would originate and be described for all other interfaces, so..
	is_event_length: boolean; // i forget if this is obsolete already, or not.
	is_promoted: boolean;
	limit_per_order: number;
	name: string;
	price_matrix: InterfacePriceMatrix[];
	require_dob: TypeRequiredFieldFlags | string;
	require_fn_ln: TypeRequiredFieldFlags | string;
	sort: number;
	status: 'active' | 'hidden' | string;
}

export interface InterfaceOWDocletWithEntitlement<T = InterfaceAnyObject> extends InterfaceOWDoclet<T> {
	entitlement_type_data?: InterfaceOWTemplateEntitlementType; // it only exists when the entitlement's data has `{ "primary": true }`
}

export interface InterfaceDocletIDToTicketProps<DocDataType = InterfaceAnyObject> {
	doclet: InterfaceOWDocletWithEntitlement<DocDataType>;
	name: string; // pass for sale's name.
	isAddOn?: boolean; // if true, it requires a Daily Admission pass to be in the cart, first.
	isAllEvent?: boolean; // if true, the sold ticket can be reused for the duration of the event.
	isAnyDay?: boolean; // if true, the date this ticket is valid, should be any normal operating day...
	isComplexBundle?: boolean; // bundles have passes for sale woven to it, and then it's the same for what's woven to each pass.
	isLimitPerOrder?: boolean; // if true, a (larger than zero) value exists for .data.limit_per_order, and users cannot add more than X to the cart.
	isPrimary?: boolean; // these generate QR codes and add-on tickets generally need a primary to be purchased first.
	isPromoted?: boolean; // if true, it appears above the list of (whatever type it is)
	priceMatrix: InterfacePriceMatrix[];
	role: {
		admin: boolean;
		pos: boolean;
		staff: boolean;
		web: boolean;
	};
	skipCapacityCheck: boolean; // if true, this pass does not obey the normal capacity/date logic for daily admissions.
	sort: number;
	/*
"price_matrix" : [
	{
		"purchase_date" : "default", // when buying a pass, on this date, the calendar_price rules apply.
		"calendar_price" : [
			{
				"date" : "default", // on this selected calendar date, the pass price is overridden with these details:
				"price" : 25,
				"price_tier" : [
					{ "count" : 200, "price" : 32 },
					{ "count" : 400, "price" : 37 },
					{ "count" : 1400, "price" : 42 }
				]
			},
			{
				"date" : "2024-09-04",
				"price" : 26,
				"price_tier" : [
					{ "count" : 200, "price" : 34 },
					{ "count" : 400, "price" : 40 },
					{ "count" : 1400, "price" : 50 }
				]
			}
		]
	},
	{
		"purchase_date" : "2024-09-05", // if buying (checking-out) on 9/5, and trying to obtain a pass for the event on 9/6, it'll cost $36
		"calendar_price" : [
			{
				"date" : "default", // if calendar_price[x].date has no match, it should use the default date.
				"price" : 26,
				"price_tier" : [
					{ "count" : 200, "price" : 34 },
					{ "count" : 400, "price" : 40 },
					{ "count" : 1400, "price" : 50 }
				]
			},
			{
				"date" : "2024-09-06",
				"price" : 36,
				"price_tier" : [
					{ "count" : 200, "price" : 38 },
					{ "count" : 400, "price" : 44 },
					{ "count" : 1400, "price" : 55 }
				]
			}
		]
	}
]
*/
}

export interface InterfacePassCapacityByDateByRoleCapacityRoles {
	capacity: number;
	seating?: string[];
}

export interface InterfacePassCapacityByDateByRolePassIDDate {
	capacity: number;
	capacityRoles?: {
		[roleID: string]: InterfacePassCapacityByDateByRoleCapacityRoles;
	};
	seating?: string[];
}

export interface InterfacePassCapacityByDateByRolePassID {
	[date: string]: InterfacePassCapacityByDateByRolePassIDDate;
}

export interface InterfacePassCapacityByDateByRole { // the transformed/processed version of InterfaceOWTemplateCapacitySettings
	/*
	<passID>: {
		<date>: {
			capacity: number,
			capacityRoles: {
				<roleID>: {
					capacity: number;
					seating: [1, 2, 3, 4]
				}
			},
			seating: [1, 2, 3]
		}
	}
	*/
	[passID: string]: InterfacePassCapacityByDateByRolePassID;
}

