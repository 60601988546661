import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppEvent, InterfaceEventWorkspaceChanged} from '../../../../../../ow-framework/interfaces/interfaces';
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
//
@Component( {
	selector: 'app-footer',
	templateUrl: './app-footer.html',
	styleUrls: [
		'./app-footer.less'
	]
} )
export class ComponentAppFooter implements OnDestroy {
	@Input()
	public privacyPolicyURL: string = ''; // this should be the one for RYST, not for a workspace or an event or something that changes....
	//
	private subWorkspaceChanged: Subscription | null = null;
	//
	public constructor() {
		this.subWorkspaceChanged = ServiceAppEvents.listen( 'workspace:changed' ).subscribe( (E: InterfaceAppEvent<InterfaceEventWorkspaceChanged>): void => {
			switch ( E.data?.workspaceID ) {
				default: {
					this.privacyPolicyURL = 'https://app.termly.io/policy-viewer/policy.html?policyUUID=5507a051-8f26-4c0b-9243-7c74295f96c0'; // Earthstar??
					break;
				}
			}
		} );
	}

	public ngOnDestroy(): void {
		if ( this.subWorkspaceChanged ) {
			this.subWorkspaceChanged.unsubscribe();
			this.subWorkspaceChanged = null;
		}
	}
}
