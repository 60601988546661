import {Injectable} from '@angular/core';
//
// MongoDB's ObjectId is a 12-byte value.
//
// first 4 bytes is the timestamp, in seconds.
// next 5 bytes is the machine ID, a randomly generated value, set once at start-up.
// last 3 bytes is an auto-incrementing counter, initialized to a random value.
let randomSerial: number = Math.floor( Math.random() * 0xFFFFFF ) + 1;
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerMongoObjectId {
	public constructor(
		//
	) {
		//
	}

	public getDate( objectId: string ): Date | null { // 4 bytes
		if ( objectId.length === 24 ) {
			// the timestamp is in seconds, but JS needs it in milliseconds.
			return new Date( parseInt( objectId.slice( 0, 8 ), 16 ) * 1000 );
		}
		return null;
	}

	public getMachineID( objectId: string ): number | null { // 5 bytes
		if ( objectId.length === 24 ) {
			return parseInt( objectId.slice( 8, 18 ), 16 );
		}
		return null;
	}

	public getCounter( objectId: string ): number | null { // 3 bytes
		if ( objectId.length === 24 ) {
			return parseInt( objectId.slice( 18, 24 ), 16 );
		}
		return null;
	}

	public newStrObjectID(): string {
		const date_4Bytes: string = Math.floor( +new Date() / 1000 ).toString( 16 );
		const randomVal_5Bytes: string = String( '0000000000' + (Math.floor( Math.random() * 0xFFFFFFFFFF ) + 1).toString( 16 ) ).slice( -10 );
		++randomSerial;
		randomSerial %= 0xFFFFFF;
		const counter_3Bytes: string = String( '000000' + (randomSerial).toString( 16 ) ).slice( -6 );
		return date_4Bytes + randomVal_5Bytes + counter_3Bytes;
	}
}
