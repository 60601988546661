<div class="locations-loading" *ngIf="locationsLoading"><span>Loading Availability...</span></div>
<div class="cabana-location-details" *ngIf="!locationsLoading">
	<div class="ticket-location-meta">
		<div class="name">
			<div class="meta">Ticket Name</div>
			<div class="text">{{ ticketName }}</div>
		</div>
		<div class="areas-available">
			<div class="meta">Tickets Available</div>
			<div class="text">{{ countTicketsAvailable < 1 ? 'N/A' : countTicketsAvailable }}</div>
		</div>
	</div>
	<div class="cabana-locations-wrapper">
		<div class="nothing-to-show" *ngIf="locationIDs.length < 1">There are no locations to choose.</div>
		<div class="cabana-locations">
			<span class="ticket-location-id go-back"
				*ngIf="showBackButton"
				(click)="clearLocationPicker();"
			>
				<i class="fa-solid fa-arrow-left"></i>
				<span class="label">Back</span>
			</span>
			<span
				*ngFor="let clID of locationIDs;"
				[ngClass]="{
					'ticket-location-id': true,
					'selected': clID === selectedLocationID
				}"
				(click)="pickedTicketLocationID( clID );"
			>{{ clID }}</span>
		</div>
	</div>
	<div class="actions">
		<div
			[ngClass]="{
				'button': true,
				'primary': true,
				'disabled': !isLocationIDPicked
			}"
			(click)="isLocationIDPicked && locationConfirmed();"
		>{{ submitButtonText }}</div>
		<div
			*ngIf="showBackButton && locationIDs.length < 1"
			(click)="clearLocationPicker();"
		>Close</div>
	</div>
</div>
