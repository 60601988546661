export interface InterfaceForgotPasswordData {
	email: string;
}

export interface InterfaceForgotPasswordErrors {
	email: boolean;
}

export interface InterfaceSignInData {
	email: string;
	password: string;
}

export interface InterfaceSignInErrors {
	email: boolean;
	password: boolean;
}

export interface InterfaceSignUpData {
	firstName: string;
	lastName: string;
	email: string;
	company?: string;
	password1: string;
	password2: string;
}

export interface InterfaceSignUpErrors {
	firstName: boolean;
	lastName: boolean;
	email: boolean;
	company?: boolean;
	password1: boolean;
	password2: boolean;
}
