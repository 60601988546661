import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
// ===== Services ===== //
import {ServiceRegex} from '../../../services/regex';
//
@Component( {
	selector: 'app-input',
	templateUrl: './input.html',
	styleUrls: [
		'./input.less'
	]
} )
export class ComponentInput implements OnInit {
	// =================================== //
	// don't use this. it's not ready yet. //
	// =================================== //
	@ViewChild( 'inp' )
	public input: ElementRef | undefined = undefined;
	//
	@Input()
	public type: string = 'text';
	//
	@Input()
	public validator: (() => boolean) | undefined = undefined; // optional function
	// ===== //
	// in order to do two-way ([binding]), you need to name the emitter with a suffix of 'Change'.
	// so it become whateverChange. then the parent component needs simply do ([value])="someVar".
	@Input()
	public value: any; // the data-type depends on the input's this.type
	//
	@Output()
	public valueChange: EventEmitter<any> = new EventEmitter<any>();
	// ===== //
	@Input()
	public error: boolean = false;
	//
	@Output()
	public errorChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	// ===== //
	@Output()
	public keyUpEnter: EventEmitter<void> = new EventEmitter<void>();
	//
	public constructor() {
		//
	}

	public ngOnInit(): void {
		if ( !(this.validator instanceof Function) ) {
			switch ( this.type ) {
				case 'password':
				case 'text': {
					this.validator = (): boolean => {
						return (this.value as string).length > 0;
					};
					break;
				}
				// number, date, color...
			}
		}
	}

	public validate(): void {
		let isOK: boolean = true;
		if ( this.validator ) {
			isOK = this.validator();
		}
		this.errorChange.emit( !isOK );
	}

	public processChange(): void {
		if ( !this.input ) {
			return;
		}
		this.value = this.input.nativeElement.value;
		switch ( this.type ) {
			case 'text': {
				this.value = (this.value as string).replace( ServiceRegex.trimRegExp, '' );
				break;
			}
		}
		this.validate();
		if ( this.validator ) {
			this.validator();
		}
		if ( this.input ) {
			this.input.nativeElement.value = this.value;
		}
		this.valueChange.emit( this.value );
	}

	public keyUpReturn( E: Event ): void {
		if ( E && E.target ) {
			(E.target as HTMLElement).blur();
		}
		this.keyUpEnter.emit();
	}
}
