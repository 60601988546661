// ===== App ===== //
import {AppRouterLinks} from './app.router-links';
// ===== Collections ===== //
//
// ===== Components ===== //
//
// ===== Modals ===== //
//
// ===== Modules ===== //
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// ===== Pages ===== //
import {PageCheckEmail} from './pages/check-email/check-email';
import {PageCheckout} from './pages/checkout/checkout';
import {PageCompleteAccount} from './pages/complete-account/complete-account';
import {PageCreateGroup} from './pages/create-group/create-group';
import {PageDashboard} from './pages/dashboard/dashboard';
import {PageEditFamily} from './pages/edit-family/edit-family';
import {PageEditGroup} from './pages/edit-group/edit-group';
import {PageFamily} from './pages/family/family';
import {PageMyAccount} from './pages/my-account/my-account';
import {PageOrders} from './pages/orders/orders';
import {PageResetPassword} from './pages/reset-password/reset-password';
import {PageSignIn} from './pages/sign-in/sign-in';
import {PageThankYou} from './pages/thank-you/thank-you';
import {PageTickets} from './pages/tickets/tickets';
// ===== Services ===== //
//
// ===== Transformers ===== //
//
const routes: Routes = [
	{
		component: PageTickets,
		path: '',
		pathMatch: 'full'
	},
	{
		component: PageDashboard,
		path: AppRouterLinks.dashboard,
		pathMatch: 'full'
	},
	{
		component: PageSignIn,
		path: AppRouterLinks.signIn,
		pathMatch: 'full'
	},
	{
		component: PageTickets,
		path: AppRouterLinks.tickets,
		pathMatch: 'full'
	},
	{
		component: PageTickets,
		path: AppRouterLinks.tickets + '/promo/:promo', // this route probably just should be erased, and force clients to use the "?promo=abc" style
		pathMatch: 'full'
	},
	{
		component: PageCheckout,
		path: AppRouterLinks.checkout,
		pathMatch: 'full'
	},
	{
		component: PageThankYou,
		path: AppRouterLinks.thankYou,
		pathMatch: 'full'
	},
	{
		component: PageCheckEmail, // /registration/check-email?id=code
		path: AppRouterLinks.checkEmail,
		pathMatch: 'full'
	},
	{
		component: PageCheckEmail, // /registration/check-email/code
		path: AppRouterLinks.checkEmail + '/:id',
		pathMatch: 'prefix'
	},
	{
		component: PageResetPassword, // /reset-password?t=token
		path: AppRouterLinks.resetPassword,
		pathMatch: 'full'
	},
	{
		component: PageResetPassword, // /reset-password/token
		path: AppRouterLinks.resetPassword + '/:t',
		pathMatch: 'prefix'
	},
	{
		component: PageMyAccount,
		path: AppRouterLinks.myAccount,
		pathMatch: 'full'
	},
	{
		component: PageFamily,
		path: AppRouterLinks.family,
		pathMatch: 'full'
	},
	{
		component: PageEditFamily,
		path: AppRouterLinks.editFamily + '/:id',
		pathMatch: 'full'
	},
	{
		component: PageCreateGroup,
		path: AppRouterLinks.createGroup,
		pathMatch: 'full'
	},
	{
		component: PageEditGroup,
		path: AppRouterLinks.editGroup,
		pathMatch: 'full'
	},
	{
		component: PageOrders,
		path: AppRouterLinks.orders,
		pathMatch: 'full'
	},
	{
		component: PageCompleteAccount,
		path: AppRouterLinks.completeAccount, // /registration/complete?token=code
		pathMatch: 'full'
	},
	{
		component: PageCompleteAccount,
		path: AppRouterLinks.completeAccount + '/:token', // /registration/complete/token
		pathMatch: 'full'
	},
	{
		path: '**',
		redirectTo: ''
	}
];
//
@NgModule( {
	declarations: [],
	exports: [
		RouterModule
	],
	imports: [
		RouterModule.forRoot( routes )
	],
	providers: []
} )
export class AppRoutingModule {}
