import {Component, Input} from '@angular/core';
// ===== Components ===== //
import {ComponentCheckBoxActionLink} from '../action-linked';
//
@Component( {
	selector: 'ui-check-box-terms-of-use',
	templateUrl: './terms-of-use.html',
	styleUrls: [
		'../../check-box.less',
		'../action-linked.less'
	]
} )
export class ComponentCheckBoxTermsOfUse extends ComponentCheckBoxActionLink {
	@Input()
	public termsAndConditionsURL: string | null = null;
	//
	public constructor() {
		super();
	}
}
