// Passes are the things for sale on the portal. They're content/cosmetic.
// They're not tickets. Tickets are created when a pass is sold.
import {InterfaceAnyObject} from '../angular/vanilla-js';
import {InterfaceOWTemplateEntitlementType} from './entitlement';
import {InterfaceOWDoclet} from '../ow/doclets';
import {InterfaceObjectId} from '../ow/mongo/object-id';

export interface InterfaceOWAPITicketPriceByDatePriceTier {
	count: number; // how many tickets must be sold...
	price: number; // ...before this price tag is used.
}

export interface InterfaceOWAPITicketPriceByDate {
	date: string; // 'YYYY-MM-DD' or the word 'default' to use, if your YYYYMMDD doesn't find a match.
	time?: string; // HH:MM:SS format. used for the evening logic.
	price: number;
	sph_price?: number;
	sph_price_restriction?: string[]; // an array of years
	price_tier?: InterfaceOWAPITicketPriceByDatePriceTier[];
}

interface InterfaceOWTemplateEventPassBase {
	name: string;
	status: 'active' | 'hidden' | string;
	target: InterfaceObjectId; // obsolete
	cannot_exchange: boolean;
	exchange_only: boolean;
	limit_per_order: number;
	is_addon: boolean;
	is_promoted: boolean;
	dates_valid: string[]; // [ YYYY-MM-DD, YYYY-MM-DD, YYYY-MM-DD, ... ]
	blocked_dates: string[];
	require_capacity: boolean; // obsolete
	is_sap: boolean; // obsolete
	is_spp: boolean; // obsolete
	// TODO: re-verify the Event Pass template's fields with what exists here.
}

export interface InterfaceEventPassPricing { // one of two <PriceType>
	default: number; // the price tag of a ticket or pass, if the user is not a season pass holder...
	time?: string; // HH:MM:SS format, for when the ticket is allowed to be activated for the day. (evening logic)
	sph: number | null; // the price tag if the user is a season pass holder, but may be restricted to certain prior seasons.
	sphRestriction: { // if the values (a year) exists, then sph price is only available, if the user has a season admission from an enabled year.
		[YYYY: string]: boolean; // "2022": true
	};
	priceTier?: InterfaceOWAPITicketPriceByDatePriceTier[]; // an array of rules, basically if the number of tickets sold reaches a threshold, the prices change.
}

export interface InterfaceEventPassPriceType { // two of two <PriceType>
	[YYYYMMDD: string]: InterfaceEventPassPricing;
}

export interface InterfaceOWTemplateEventPassOriginal extends InterfaceOWTemplateEventPassBase {
	price: InterfaceOWAPITicketPriceByDate[];
}

interface InterfaceOWTemplateEventPassTransformed extends InterfaceOWTemplateEventPassBase {
	price: InterfaceEventPassPriceType;
}

export interface InterfaceOWDocletWithEntitlement<T = InterfaceAnyObject> extends InterfaceOWDoclet<T> {
	entitlement_type_data?: InterfaceOWTemplateEntitlementType; // it only exists when the entitlement's data has `{ "primary": true }`
}

export interface InterfaceOWDocletEventPassTransformed extends InterfaceOWDocletWithEntitlement { // obsolete
	// the portal wants the data.price to be an object instead of an array. where the keys are the dates, and the values are the original value.
	// { "default" : { "price" : 199, "time" : "08:30:00", "price_tier" : [ { "count" : 50, "price" : 249 }, { "count" : 80, "price" : 349 } ] } }
	data: InterfaceOWTemplateEventPassTransformed;
}

export interface InterfaceDocletIDToTicketProps_T<PriceType, DocDataType = InterfaceAnyObject> {
	name: string; // pass for sale's name.
	price: PriceType; // POS and Web price-formats have diverged. the Web is transforming them, so that it's easier on the HTML to access it. (calendar)
	sort: number;
	isAnyDay?: boolean; // if true, the date this ticket is valid, should be any normal operating day...
	isAllEvent?: boolean; // if true, the sold ticket can be reused for the duration of the event.
	isPrimary?: boolean; // these generate QR codes and add-on tickets generally need a primary to be purchased first.
	isAddOn?: boolean; // if true, it requires a Daily Admission pass to be in the cart, first.
	isComplexBundle?: boolean; // bundles have passes for sale woven to it, and then it's the same for what's woven to each pass.
	skipCapacityCheck: boolean; // if true, this pass does not obey the normal capacity/date logic for daily admissions.
	isPromoted?: boolean; // if true, it appears above the list of (whatever type it is)
	isLimitPerOrder?: boolean; // if true, a (larger than zero) value exists for .data.limit_per_order, and users cannot add more than X to the cart.
	role: {
		admin: boolean;
		pos: boolean;
		staff: boolean;
		web: boolean;
	};
	doclet: InterfaceOWDocletWithEntitlement<DocDataType>;
}

export interface InterfaceDocletIDToTicketProps extends InterfaceDocletIDToTicketProps_T<InterfaceEventPassPriceType, InterfaceOWTemplateEventPassPriceV3> {}

export interface InterfacePassCapacityByDateByRoleCapacityRoles {
	capacity: number;
	seating?: string[];
}

export interface InterfacePassCapacityByDateByRolePassIDDate {
	capacity: number;
	capacityRoles?: {
		[roleID: string]: InterfacePassCapacityByDateByRoleCapacityRoles;
	};
	seating?: string[];
}

export interface InterfacePassCapacityByDateByRolePassID {
	[date: string]: InterfacePassCapacityByDateByRolePassIDDate;
}

export interface InterfacePassCapacityByDateByRole { // the transformed/processed version of InterfaceOWTemplateCapacitySettings
	/*
	<passID>: {
		<date>: {
			capacity: number,
			capacityRoles: {
				<roleID>: {
					capacity: number;
					seating: [1, 2, 3, 4]
				}
			},
			seating: [1, 2, 3]
		}
	}
	*/
	[passID: string]: InterfacePassCapacityByDateByRolePassID;
}

export interface InterfacePriceMatrixPrice {
	date: 'default' | string; // 'YYYY-MM-DD' // this date is used to override what the calendar is showing for that day.
	price: number; // the cost to purchase an Event Pass, unless overriden by the price_tier system.
	price_tier: InterfaceOWAPITicketPriceByDatePriceTier[];
}

export interface InterfacePriceMatrix {
	purchase_date: 'default' | string; // when purchasing on this date, we should be using the calendar_price data.
	// purchase_date is one of: 'YYYY-MM-DD:YYYY-MM-DD', 'YYYY-MM-DD', 'default'
	calendar_price: InterfacePriceMatrixPrice[]; // only use this if the purchase_date matches, (or when there is no match && is the default case)
}

export interface InterfaceOWTemplateEventPassPriceV3 extends InterfaceOWTemplateEventPassBase {
	price_matrix: InterfacePriceMatrix[];
/*
"price_matrix" : [
	{
		"purchase_date" : "default", // when buying a pass, on this date, the calendar_price rules apply.
		"calendar_price" : [
			{
				"date" : "default", // on this selected calendar date, the pass price is overridden with these details:
				"price" : 25,
				"price_tier" : [
					{ "count" : 200, "price" : 32 },
					{ "count" : 400, "price" : 37 },
					{ "count" : 1400, "price" : 42 }
				]
			},
			{
				"date" : "2024-09-04",
				"price" : 26,
				"price_tier" : [
					{ "count" : 200, "price" : 34 },
					{ "count" : 400, "price" : 40 },
					{ "count" : 1400, "price" : 50 }
				]
			}
		]
	},
	{
		"purchase_date" : "2024-09-05", // if buying (checking-out) on 9/5, and trying to obtain a pass for the event on 9/6, it'll cost $36
		"calendar_price" : [
			{
				"date" : "default", // if calendar_price[x].date has no match, it should use the default date.
				"price" : 26,
				"price_tier" : [
					{ "count" : 200, "price" : 34 },
					{ "count" : 400, "price" : 40 },
					{ "count" : 1400, "price" : 50 }
				]
			},
			{
				"date" : "2024-09-06",
				"price" : 36,
				"price_tier" : [
					{ "count" : 200, "price" : 38 },
					{ "count" : 400, "price" : 44 },
					{ "count" : 1400, "price" : 55 }
				]
			}
		]
	}
]
*/
}
