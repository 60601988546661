import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppEvent} from '../interfaces/interfaces';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceAppEvents {
	private static readonly listeners: {
		[topic: string]: EventEmitter<InterfaceAppEvent>;
	} = {};

	public static broadcast( event: InterfaceAppEvent | string ): void {
		if ( typeof event === 'string' ) {
			if ( ServiceAppEvents.listeners.hasOwnProperty( event ) ) {
				ServiceAppEvents.listeners[event].emit( {
					topic: event
				} );
			}
			// else it must be the interface.
		} else if ( ServiceAppEvents.listeners.hasOwnProperty( event.topic ) ) {
			( ServiceAppEvents.listeners[event.topic] as EventEmitter<InterfaceAppEvent> ).emit( event );
		}
	}

	public static listen( topic: string ): Observable<InterfaceAppEvent> {
		// make sure you .unsubscribe(), from any observables your subscribed onto, or it'll leak memory.
		if ( !ServiceAppEvents.listeners.hasOwnProperty( topic ) ) {
			ServiceAppEvents.listeners[topic] = new EventEmitter<InterfaceAppEvent>();
		}
		return ServiceAppEvents.listeners[topic];
	}
}
