import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
// ===== App ===== //
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../../../../../ow-framework/collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceNavMenuItem,
	InterfaceOWTemplateConsumer,
	InterfaceOWUser, InterfaceOWUserProfileData
} from '../../../../../../ow-framework/interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceNavigate} from '../../../../../../ow-framework/services/navigate';
//
@Component( {
	selector: 'page-family',
	templateUrl: './family.html',
	styleUrls: [
		'./family.less'
	]
} )
export class PageFamily implements OnInit {
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	//
	public mastHeading: string = 'Hello';
	//
	public serialCode: string = '';
	//
	public navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family',
			locked: true
		}, /*
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		}, */
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		}
	];
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly title: Title
	) {
		this.title.setTitle( 'Portal' );
	}

	public haveUserInfo: boolean = false;
	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData.data ) {
				this.haveUserInfo = true;
				// const templateConsumer: InterfaceOWTemplateConsumer = userData.data as InterfaceOWTemplateConsumer;
				const userProfileData: InterfaceOWUserProfileData = userData.profile;
				this.mastHeading = 'Hello, ' + userProfileData.first_name + ' ' + userProfileData.last_name;
			}
		} );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchUserInfo();
		} else {
			console.log( 'not signed in' );
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}
}
