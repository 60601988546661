export interface InterfaceOWTemplateCardVault {
	vault_id: number;
	vault_customer_id: number;
	type: 'CARD' | string;
	account_type: 'VISA' | 'MASTERCARD' | 'AMERICANEXPRESS' | 'DISCOVERCARD' | string;
	last_four: string;
	card_exp_month: string;
	card_exp_year: string;
	status: 'active' | string;
}

export interface InterfaceOWAPICardVault { // not too sure, but this may 'be' the template interface above. meaning if one changes, they both do.
	vault_id: number;
	vault_customer_id: number;
	type: 'CARD' | string;
	account_type: 'VISA' | 'MASTERCARD' | 'AMERICANEXPRESS' | 'DISCOVERCARD' | string;
	vault_type: 'vault' | string;
	last_four: string;
	card_exp_month: string;
	card_exp_year: string;
	status: 'active' | string;
}
