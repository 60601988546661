import {Component} from '@angular/core';
// ===== Components ===== //
import {ComponentCheckBoxActionLink} from '../action-linked';
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../services/app-events';
//
@Component( {
	selector: 'ui-check-box-liability-waiver',
	templateUrl: '../action-linked.html',
	styleUrls: [
		'../../check-box.less',
		'../action-linked.less'
	]
} )
export class ComponentCheckBoxLiabilityWaiver extends ComponentCheckBoxActionLink {
	//
	public constructor() {
		super();

		this.actions = [
			{
				text: 'I have read and agree to the'
			},
			{
				text: 'Liability Waiver',
				isLink : true
			},
			{
				text: '.',
				noNBSP: true // no space between the dot '.' and the text.
			}
		];
	}

	public override linkActivated( idx: number ): void {
		if ( idx === 1 ) { // if they clicked on actions[1], where isLink is true.
			ServiceAppEvents.broadcast( 'modal:open:liability-waiver' );
		}
		super.linkActivated( idx );
	}
}
