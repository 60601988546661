import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPIBulkRecordRequest,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWAPIPaginationRequest,
	InterfaceOWDoclet
} from '../../../../interfaces/interfaces';
interface InterfaceGetDocletsParams {
	query? : InterfaceAnyObject;
	withoutAuth?: boolean;
	paginationOptions?: InterfaceOWAPIPaginationRequest
}
interface InterfaceGetDocletsByTemplateParams extends InterfaceGetDocletsParams {
	templateID: string | string[]; // this just adds onto the query param.
}
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
// ===== Transformers ===== //
import {TransformerOWAPI} from '../../../../transformers/owapi';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspaceDoclets {
	private readonly routePrefix: string = 'workspace/';
	//
	public constructor(
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Doclets, Weaves ========================= //

	public createDoclet( appContext: InterfaceAppContext, title: string, data: InterfaceAnyObject, template_id: string, tags?: string[] ): Observable<InterfaceHTTPGateway> {
		// HTTP status of 200 on success.
		// data.results[x].items[y] has the doclet_id but not the entire doclet, i think.
		// { "success" : true, "status" : 200, "data" : { "status" : 200, "results" : [ { "items" : [ ... ] } ] } }
		return this.gateway.fetch( 'put', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet', JSON.stringify( {
			data: data, // { 'key1': 'val1', 'key2': 2, 'key3': [ 'str1', 'str2' ] } // keys come from the template's .fields array.
			tags: Array.isArray( tags ) ? tags : [],
			template_id: template_id,
			title: title
		} ) );
		/* InterfaceHTTPGateway.data =
		{
			"status": 200,
			"data": {
				"doclet_id": "628d580042ed2615904f26a5"
			},
			"messages": {
			"info": [],
				"warnings": [],
				"errors": []
			},
			"meta": {
				"time_processed": "2022-May-24 17:11:12"
			},
			"ts": "2022-May-24 22:11:12"
		}
		*/
	}

	public getDoclets( appContext: InterfaceAppContext, requestParams: InterfaceGetDocletsParams, withWeaves?: boolean ): Observable<InterfaceHTTPGateway> {
		// the generic 'do it yourself' function.
		const route: string = this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclets/';
		const query: string = requestParams.query ? TransformerOWAPI.createFilter( requestParams.query ) : '';
		const payload: undefined = undefined; // GET requests don't use a payload.
		const withoutAuth: boolean = !!requestParams.withoutAuth;
		let verbose: string = '';
		if ( withWeaves ) {
			verbose = 'verbose=true';
		}
		return this.gateway.fetch( 'get', route + (query.length > 0 ? '?query=' + encodeURIComponent( query ) + '&' + verbose : '?' + verbose), payload, withoutAuth, requestParams.paginationOptions );
	}

	public getDocletByID( appContext: InterfaceAppContext, docletId: string | string[], withoutAuth?: boolean ): Observable<InterfaceHTTPGateway> {
		// ### beware ### //
		// if you send in an array of doclet IDs, but only get back one result record,
		// then the api response is InterfaceOWAPIGetDocletResponse (singular)
		// and not the expected InterfaceOWAPIGetDocletsResponse (plural)
		// .data.items won't exist, because .data will be the doclet itself, not an array.
		// you'll be accidentally reaching into the doclet's .items - if it exists.
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( Array.isArray( docletId ) ? docletId.join( ',' ) : docletId ),
			undefined, // no payload for GET requests
			withoutAuth, // the "make a request without an api key" thing kinda sux.
			{ // if you make a request for one doclet by ID, the pagination fields on the response, won't exist. (because the response will be the doclet, not the container of doclets and pagination fields)
				limit: 9999, // you need this, because the default is limit 20, and if you specify more than 20 IDs, strange things happen.
				sortDir: -1,
				sortKey: '_id'
			}
		);
	}

	private loopFetchDocletsByID( appContext: InterfaceAppContext, docletIDs: string[], idxStart: number, batchAmount: number, withoutAuth: boolean, cheatyCacheUpdatedByReference: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>, callback: (success: boolean) => void ): void {
		// putting docletIDs on the url makes it break once we reach the upper limit of how many char's can go into a URL.
		// it's somewhere below 7k characters. it's also a server-side issue, not just a browser issue.
		// IDs are 24 chars. and the comma is another, but a comma is escaped into %2C.
		// so about 27 chars per doclet ID. 2048 bytes / 30 is about 68 IDs.
		const arrDocletIDs: string[] = docletIDs.slice( idxStart, idxStart + batchAmount );
		this.getDocletByID( appContext, arrDocletIDs, withoutAuth ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				// ensure you have the right interface, because there are two..
				const apiResponseMixedType: InterfaceOWAPIGetDocletResponse | InterfaceOWAPIGetDocletsResponse = response.data;
				if ( apiResponseMixedType && apiResponseMixedType.data ) { // .data is either a doclet, or the api response of { data: { items: <T>[] } }
					if ( apiResponseMixedType.data.hasOwnProperty( '_id' ) ) { // is a InterfaceOWAPIGetDocletResponse
						cheatyCacheUpdatedByReference.records.push( apiResponseMixedType.data as unknown as InterfaceOWDoclet );
					} else { // is a InterfaceOWAPIGetDocletsResponse
						const apiResponse: InterfaceOWAPIGetDocletsResponse = apiResponseMixedType as InterfaceOWAPIGetDocletsResponse;
						if ( Array.isArray( apiResponse.data.items ) ) {
							for ( let x: number = 0; x < apiResponse.data.items.length; ++x ) {
								cheatyCacheUpdatedByReference.records.push( apiResponse.data.items[x] );
							}
						}
						if ( apiResponse.data.items.length !== arrDocletIDs.length ) {
							const apiIDs: string[] = apiResponse.data.items.map( (doclet: InterfaceOWDoclet): string => doclet._id.$oid );
							const deltaIDs: string[] = Array.from( new Set( arrDocletIDs.filter( (id: string): boolean => apiIDs.indexOf( id ) < 0 ) ) );
							console.log( 'loopFetchDocletsByID - missing records for requested doclet ID.', deltaIDs );
							// the problem was the pagination's limit, or the URL character limit
						}
					}
					const nextIdxStart: number = idxStart + batchAmount;
					if ( nextIdxStart < docletIDs.length ) {
						this.loopFetchDocletsByID( appContext, docletIDs, nextIdxStart, batchAmount, withoutAuth, cheatyCacheUpdatedByReference, callback );
					} else {
						callback( true ); // probably completed successfully...
					}
				} else { // api fail...
					console.log( 'loopFetchDocletsByID - Failed to acquire (response).data from the API', response );
					callback( false );
				}
			} else { // api fail...
				console.log( 'loopFetchDocletsByID - Failed to fetch doclets by ID', response );
				callback( false );
			}
		} );
	}

	public getAllDocletsByID( appContext: InterfaceAppContext, docletIDs: string[], withoutAuth: boolean, callback: (output: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>) => void ): void {
		// this fn is intended to be used, once pagination is up and running, so that each component doesn't have to reproduce this logic...
		const uniqueIDs: string[] = Array.from( new Set( docletIDs.map( (id: string): string => id ) ) ).sort();
		const output: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet> = {
			success: false,
			records: []
		};
		this.loopFetchDocletsByID( appContext, uniqueIDs, 0, 20, withoutAuth, output, (success: boolean): void => {
			output.success = success;
			callback( output );
		} );
	}

	public getDocletsByTemplateID( appContext: InterfaceAppContext, templateID: string | string[], query?: string | InterfaceAnyObject, tempNoAuth?: boolean, paginationOptions?: InterfaceOWAPIPaginationRequest, withWeaves?: boolean ): Observable<InterfaceHTTPGateway> {
		let qry: string = 'template_id:';
		if ( Array.isArray( templateID ) ) {
			qry += '[{id}' + templateID.join( ',{id}' ) + ']';
		} else {
			qry += '{id}' + templateID;
		}
		if ( query ) {
			switch ( typeof query ) {
				case 'undefined': {
					break;
				}
				case 'string': {
					qry += '|' + query; // 'data.status:active'
					break;
				}
				case 'object': {
					if ( Object.keys( query ).length > 0 ) {
						qry += '|' + TransformerOWAPI.createFilter( query );
					}
					break;
				}
			}
		}
		let verbose: string = '';
		if ( withWeaves ) {
			verbose = '&verbose=true';
		}
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclets/?query=' + encodeURIComponent( qry ) + verbose, undefined, tempNoAuth, paginationOptions );
	}

	private loopFetchDocletsByTemplateID( appContext: InterfaceAppContext, templateIDs: string[], query: string, withWeaves: boolean, withoutAuth: boolean, idxStart: number, batchAmount: number, cheatyCacheUpdatedByReference: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>, callback: (success: boolean) => void, cursorNext?: InterfaceOWAPIPaginationRequest['cursorNext'] ): void {
		//
		const arrTemplateIDs: string[] = templateIDs.slice( idxStart, idxStart + batchAmount );
		const paginationOptions: InterfaceOWAPIPaginationRequest = {
			limit: 9999,
			sortDir: -1,
			sortKey: '_id'
		};
		if ( cursorNext ) {
			paginationOptions.cursorNext = cursorNext;
		}
		this.getDocletsByTemplateID( appContext, arrTemplateIDs, query, withoutAuth, paginationOptions, withWeaves ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					for ( let x: number = 0; x < apiResponse.data.items.length; ++x ) {
						cheatyCacheUpdatedByReference.records.push( apiResponse.data.items[x] );
					}
					if ( apiResponse.data.have_more ) {
						// use the 'next' token, and don't advance the position for which template IDs to use.
						this.loopFetchDocletsByTemplateID( appContext, templateIDs, query, withWeaves, withoutAuth, idxStart, batchAmount, cheatyCacheUpdatedByReference, callback, apiResponse.data.next );
					} else { // else we don't have more doclets to fetch, move onto the next set of templateIDs, if any.
						const nextIdxStart: number = idxStart + batchAmount;
						if ( nextIdxStart < templateIDs.length ) {
							this.loopFetchDocletsByTemplateID( appContext, templateIDs, query, withWeaves, withoutAuth, nextIdxStart, batchAmount, cheatyCacheUpdatedByReference, callback );
						} else { // else we exhausted the templateIDs and have all the records.
							callback( true );
						}
					}
				} else {
					console.log( 'loopFetchDocletsByTemplateID - Failed to acquire (response).data.items from the API', response );
					callback( false );
				}
			} else {
				console.log( 'loopFetchDocletsByTemplateID - Failed to fetch doclets by template ID', response );
				callback( false );
			}
		} );
	}

	public getAllDocletsByTemplateID( appContext: InterfaceAppContext, paramsObject: InterfaceGetDocletsByTemplateParams, withWeaves: boolean, callback: (output: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>) => void ): void {
		// paramsObject.templateID is required (string or string[])
		// the pagination part is ignored.
		const query: string = paramsObject.query ? TransformerOWAPI.createFilter( paramsObject.query ) : '';
		const uniqueTemplateIDs: string[] = Array.from( new Set( (Array.isArray( paramsObject.templateID ) ? paramsObject.templateID : [ paramsObject.templateID ]).map( (id: string): string => id ) ) ).sort();
		const output: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet> = {
			success: false,
			records: []
		};
		this.loopFetchDocletsByTemplateID( appContext, uniqueTemplateIDs, query, withWeaves, paramsObject?.withoutAuth ?? false, 0, 20, output, (success: boolean): void => {
			output.success = success;
			callback( output );
		} );
	}

	public getWeavesByDocletID( appContext: InterfaceAppContext, docletId: string | string[], expandWeaves?: boolean, withoutAuth?: boolean ): Observable<InterfaceHTTPGateway<InterfaceOWAPIGetWeavesResponse>> {
		// get a list of entries that are woven to a doclet.
		// this doesn't get the doclets woven to a doclet. it gets the meta-info, called weaves.
		//
		// although this API call can take in an array of strings for doclet IDs, most functions that make use of this, just don't use arrays of strings (yet?)
		//
		// TODO: do a thing where it sends up a filter for which woven doclets you're interested in, rather than getting the entire list.
		// ..this is useful for when we lazy-load woven entries (doclet_id, weave_id, template_id) and only want to see one template_id related to a record (such as seeing all orders on a customer)
		const route: string = this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( Array.isArray( docletId ) ? docletId.join( ',' ) : docletId ) + '/weaves';
		let qry: string = '?';
		if ( expandWeaves ) {
			if ( qry !== '' ) {
				qry += '&';
			}
			qry += 'verbose=true';
		}
		return this.gateway.fetch( 'get', route + qry, undefined, withoutAuth );
	}

	private getWovenDocletsFromDocletID_filterByTemplate( appContext: InterfaceAppContext, docletId: string, template: string ): Observable<InterfaceHTTPGateway> {
		// grab the records that are woven to a doclet, but only ones that match a template name.
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( docletId ) + '/weaves/doclets?query=' + encodeURIComponent( 'template:' + template ) );
	}

	public getWovenDocletsFromDocletID( appContext: InterfaceAppContext, docletId: string ): Observable<InterfaceHTTPGateway> {
		// grab the records that are woven to a doclet.
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( docletId ) + '/weaves/doclets' );
	}

	public updateDocletData( appContext: InterfaceAppContext, docletId: string, data: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		// data just needs to be { "name of component" : "value", "other name" : "value" }
		// the names come from the template.
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( docletId ), JSON.stringify( {
			data: data
		} ) );
	}

	public addDocletTags( appContext: InterfaceAppContext, docletId: string, tags: string[] ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( docletId ), JSON.stringify( {
			tags: tags // TODO: remove from tags, overwrite tags, add tags, etc.
		} ) );
	}

	public updateDocletTitle( appContext: InterfaceAppContext, docletId: string, title: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( docletId ), JSON.stringify( {
			title: title
		} ) );
	}

	public createWeave( appContext: InterfaceAppContext, parentDocletID: string, data: InterfaceAnyObject, childDocletID: string ): Observable<InterfaceHTTPGateway> {
		// to create a weave, just supply the parent and child doclet IDs, as well as the data object, if any.
		//
		// to update a weave's data, you need to still supply both parent + child doclet ID,
		// as well as the entire key-value pair properties of data {}, not just the one property you want to update.
		// ... the "data" part of a weave is always replaced with whatever is handed through here.
		const payload: {
			data: {
				doclet_id: string;
				data: InterfaceAnyObject;
			}[];
		} = {
			data: [ // an array of objects. can have multiple objects.
				{
					doclet_id: childDocletID,
					data: data // data = { 'role': 'Primary' }
				}
			]
		};
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( appContext.workspaceID ) + '/doclet/' + encodeURIComponent( parentDocletID ) + '/weaves', JSON.stringify( payload ) );
		// returns 200 on success. see InterfaceOWAPICreateWeaveResponse.
	}
}
