import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// ===== Interface ===== //
interface InterfaceDOB {
	year: number;
	month1: number; // 1 - 12
	day: number; // 1 - 31
}
//
@Component( { // TODO: finish the HTML side.
	selector: 'app-dob-picker',
	templateUrl: './dob-picker.html',
	styleUrls: [
		'./dob-picker.less'
	]
} )
export class ComponentDOBPicker implements OnInit {
	@Input() dob: InterfaceDOB | string | undefined = undefined; // YYYY-MM-DD or an object.
	public formYear: number = 2000;
	public formMonth1: number = 1;
	public formDay: number = 1;
	@Output() private dobChange: EventEmitter<InterfaceDOB> = new EventEmitter<InterfaceDOB>();
	//
	public constructor() {
		//
	}

	private isIFaceDOB( obj: unknown ): obj is InterfaceDOB {
		return typeof obj === 'object' && obj !== null && 'year' in obj && 'month1' in obj && 'day' in obj;
	}

	public ngOnInit(): void {
		if ( typeof this.dob === 'string' ) {
			if ( /^\d\d\d\d-\d\d-\d\d$/.test( this.dob ) ) {
				const arr: string[] = this.dob.split( /-/g );
				this.formYear = Number( arr[0] );
				this.formMonth1 = Number( arr[1] );
				this.formDay = Number( arr[2] );
			}
		} else if ( this.isIFaceDOB( this.dob ) ) {
			this.formYear = this.dob.year;
			this.formMonth1 = this.dob.month1;
			this.formDay = this.dob.day;
		}
		if ( !Number.isFinite( this.formYear ) || Number.isNaN( this.formYear ) ) {
			this.formYear = 2000;
		}
		if ( !Number.isFinite( this.formMonth1 ) || Number.isNaN( this.formMonth1 ) || this.formMonth1 < 1 || this.formMonth1 > 12 ) {
			this.formMonth1 = 1;
		}
		if ( !Number.isFinite( this.formDay ) || Number.isNaN( this.formDay ) || this.formDay < 1 || this.formDay > 31 ) {
			this.formDay = 1;
		}
	}

	public incYear( amount: number ): void {
		this.formYear += amount;
	}

	public decYear( amount: number ): void {
		this.formYear = Math.max( 0, this.formYear - amount );
	}

	public incMonth(): void {
		if ( ++this.formMonth1 > 12 ) {
			this.formMonth1 = 1;
		}
		// TODO: enforce day
	}

	public decMonth(): void {
		if ( --this.formMonth1 < 1 ) {
			this.formMonth1 = 12;
		}
		// TODO: enforce day
	}

	public incDay(): void {
		if ( ++this.formDay > 31 ) {
			// TODO: better restrictions
			this.formDay = 1;
		}
	}

	public decDay(): void {
		if ( --this.formDay < 1 ) {
			this.formDay = 31;
			// TODO: better day assignment.
		}
	}

	public submitDOB(): void {
		this.dobChange.emit( {
			year: this.formYear,
			month1: this.formMonth1,
			day: this.formDay
		} );
	}
}
