import {Component} from '@angular/core';
// ===== App ===== //
import {AppConfig} from '../../app.config';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceOWAPICardVaultResponse
} from '../../../../../../ow-framework/interfaces/interfaces';
interface InterfaceCCData {
	ccNum: string;
	ccCVV: string;
	ccExpMM: string;
	ccExpYYYY: string;
	ccZip: string;
}
interface InterfaceCCErrors {
	ccNum: boolean;
	ccCVV: boolean;
	ccExpMM: boolean;
	ccExpYYYY: boolean;
	ccZip: boolean;
}
// ===== Services ===== //
import {ServiceAppEvents} from '../../../../../../ow-framework/services/app-events';
import {ServiceAuthentication} from '../../../../../../ow-framework/services/authentication';
import {ServiceOWAPI} from '../../../../../../ow-framework/services/ow-api';
import {ServiceRegex} from '../../../../../../ow-framework/services/regex';
//
@Component( {
	selector: 'modal-credit-card',
	templateUrl: './credit-card.html',
	styleUrls: [
		'./credit-card.less'
	]
} )
export class ModalCreditCard {
	public busy: boolean = false;
	//
	public ccData: InterfaceCCData = {
		ccNum: '',
		ccCVV: '',
		ccExpMM: '',
		ccExpYYYY: '',
		ccZip: ''
	};
	public ccErrors: InterfaceCCErrors = {
		ccNum: false,
		ccCVV: false,
		ccExpMM: false,
		ccExpYYYY: false,
		ccZip: false
	};
	public cashlessSpending: boolean = false;
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly owapi: ServiceOWAPI
	) {
		//
	}

	public validateField( field: keyof InterfaceCCData ): void {
		this.ccData[field] = this.ccData[field].replace( ServiceRegex.trimRegExp, '' );
		const now: Date = new Date();
		switch ( field ) {
			case 'ccNum': {
				this.ccErrors[field] = !this.validateCCNum( this.ccData[field] );
				break;
			}
			case 'ccExpMM': {
				if ( this.ccData[field].length < 2 ) {
					this.ccData[field] = '0' + this.ccData[field];
				}
				const intMM: number = Number( this.ccData[field] );
				if ( intMM < 1 || intMM > 12 ) {
					this.ccErrors[field] = true;
				} else if ( this.ccData['ccExpYYYY'].length === 4 ) {
					const intYYYY: number = Number( this.ccData['ccExpYYYY'] );
					const intYYYYMM: number = now.getFullYear() * 100 + now.getMonth() + 1;
					if ( intYYYYMM > intYYYY * 100 + intMM + 1 ) {
						this.ccErrors['ccExpMM'] = true;
						this.ccErrors['ccExpYYYY'] = true;
					} else {
						this.ccErrors['ccExpMM'] = false;
						this.ccErrors['ccExpYYYY'] = false;
					}
				}
				break;
			}
			case 'ccExpYYYY': {
				this.ccData[field] = String( now.getFullYear() ).slice( 0, 4 - this.ccData[field].length ) + this.ccData[field];
				const intYYYY: number = Number( this.ccData[field] );
				if ( intYYYY < now.getFullYear() ) {
					this.ccErrors[field] = true;
				} else if ( this.ccData['ccExpMM'].length === 2 ) {
					const intMM: number = Number( this.ccData['ccExpMM'] );
					const intYYYYMM: number = now.getFullYear() * 100 + now.getMonth() + 1;
					if ( intYYYYMM > intYYYY * 100 + intMM + 1 ) {
						this.ccErrors['ccExpMM'] = true;
						this.ccErrors['ccExpYYYY'] = true;
					} else {
						this.ccErrors['ccExpMM'] = false;
						this.ccErrors['ccExpYYYY'] = false;
					}
				}
				break;
			}
			case 'ccCVV': {
				this.ccErrors[field] = !this.ccData[field].match( /^\d\d\d\d?$/ );
				break;
			}
			case 'ccZip': {
				this.ccErrors[field] = this.ccData[field].length < 1;
				break;
			}
		}
	}

	public numbersOnly( E: KeyboardEvent ): void {
		if ( E.key.length === 1 && !E.key.match( /^[0-9]$/ ) ) {
			E.preventDefault();
			E.stopPropagation();
		}
	}

	public validateCCNum( ccNum: string ): boolean {
		let ccType: string = '';
		let isValid: boolean = false;
		if ( ccNum.length > 14 ) {
			switch ( ccNum.charAt( 0 ) ) {
				// all credit card info here: http://www.iinbase.com/
				case '3': { // American Express
					if ( ccNum.charAt( 1 ) === '4' || ccNum.charAt( 1 ) === '7' ) { // 34 or 37
						ccType = 'American Express';
						isValid = ccNum.length === 16 || ccNum.length === 15; // Amex has both 15 and 16 digit card numbers.
					} // system identifier, type, currency, account number, a check digit
					break; // AMEX: SSTCAAAAAAAAAAC
				}
				case '4': { // Visa
					ccType = 'Visa';
					isValid = ccNum.length === 16; // All 3 other cards, use the 16 digit schema
					break; // SSTCAAAAAAAAAAC
				} // system identifier, issuer/bank identifier, bank number, account number, a check digit
				case '5': { // Master Card
					if ( ccNum.charAt( 1 ) > '0' && ccNum.charAt( 1 ) < '6' ) { // 51 to 55
						ccType = 'Master Card';
						isValid = ccNum.length === 16;
					}
					break;
				}
				case '6': { // Discover Card
					if ( ccNum.charAt( 1 ) === '0' && ccNum.charAt( 2 ) === '1' && ccNum.charAt( 3 ) === '1' ) { // 6011
						ccType = 'Discover Card';
						isValid = ccNum.length === 16;
					} // see: https://www.discovernetworkvar.com/common/pdf/var/10-1_VAR_ALERT_April_2010.pdf
					break;
				}
			}
		} // end if CC length is at least 15.
		return isValid;
	}

	public addCC(): void {
		const keys: (keyof InterfaceCCErrors)[] = Object.keys( this.ccErrors ) as (keyof InterfaceCCErrors)[];
		let haveErrors: boolean = false;
		for ( let x: number = 0; x < keys.length; ++x ) {
			this.validateField( keys[x] );
			haveErrors = haveErrors || this.ccErrors[ keys[x] ];
		}
		if ( haveErrors ) {
			return;
		}
		const ac: InterfaceAppContext = this.appConfig.getContext();
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			alert( 'An error occurred. Please try again later.' );
			console.log( 'Missing user profile ID' );
		} else {
			this.owapi.workspace.actions.core.savePaymentMethod(
				ac,
				profileID,
				this.ccData.ccNum,
				this.ccData.ccCVV,
				this.ccData.ccExpMM,
				this.ccData.ccExpYYYY,
				this.ccData.ccZip,
				this.appConfig.getRealmID( 'Portal' )
			).subscribe( (response: InterfaceHTTPGateway): void => {
				let failed: boolean = true;
				if ( response && response.success ) {
					const apiResponse: InterfaceOWAPICardVaultResponse = response.data;
					if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
						failed = false;
					}
				}
				if ( failed ) {
					alert( 'An error occurred. Please try again later.' );
				} else {
					ServiceAppEvents.broadcast( 'card:re-sync' );
					this.closeModal();
				}
			} );
		}
	}

	public closeModal(): void {
		ServiceAppEvents.broadcast( 'modal:close:credit-card' );
	}
}
