import {Component, Input} from '@angular/core';
// ===== Components ===== //
import {ComponentDatePicker} from '../date-picker';
// ===== Interfaces ===== /
import {InterfaceOWDailyAdmissionAvailability} from '../../../../interfaces/interfaces';
//
@Component( {
	selector: 'app-availability-date-picker',
	templateUrl: './availability-date-picker.html',
	styleUrls: [
		'../date-picker.less',
		'./availability-date-picker.less'
	]
} )
export class ComponentAvailabilityDatePicker extends ComponentDatePicker {
	@Input() public availabilityLoaded: boolean = false; // needs to be set to true once dailyAdmissionAvailability is set up and not just {}
	@Input() public dailyAdmissionAvailability: InterfaceOWDailyAdmissionAvailability = {}
	@Input() public enforceAvailabilityRules: boolean = true;
	//
	public strTargetYYYY_MM1: string = ''; // target year and month in "YYYY-MM" format. month is 01 - 12.
	//
	public constructor() {
		super();
	}

	public isThereCapacityToday( YYYYMM1DD: string ): boolean { // YYYY-MM-DD where MM is 01 to 12
		// do not use logic here to filter away dates older than 'today'.
		// you need to make the areas that call this fn, filter away those dates instead.
		if ( !this.enforceAvailabilityRules ) { return true; }
		if ( this.dailyAdmissionAvailability.hasOwnProperty( YYYYMM1DD ) ) {
			let ticketTypes: string[] = Object.keys( this.dailyAdmissionAvailability[YYYYMM1DD] );
			for ( let x: number = 0; x < ticketTypes.length; ++x ) {
				if ( this.dailyAdmissionAvailability[YYYYMM1DD][ ticketTypes[x] ] > 0 ) {
					return true;
				}
			}
		}
		return false;
	}

	public override updateCalendar( year: number, month1: number ): void { // month is 1 to 12.
		this.strTargetYYYY_MM1 = String( year ) + '-' + String( '0' + month1 ).slice( -2 );
		super.updateCalendar( year, month1 );
	}
}
