<div class="time-selector">
	<div class="hours">
		<span class="fa-solid fa-chevron-up" (click)="incHour();"></span>
		<span>{{ ('0' + (hour + 1)).slice( -2 ) }}</span>
		<span class="fa-solid fa-chevron-down" (click)="decHour();"></span>
	</div>
	<div class="divider">
		<span>:</span>
	</div>
	<div class="minutes">
		<span class="fa-solid fa-chevron-up" (click)="incMinutes();"></span>
		<span>{{ ('0' + minutes).slice( -2 ) }}</span>
		<span class="fa-solid fa-chevron-down" (click)="decMinutes();"></span>
	</div>
	<div class="divider">
		<span>:</span>
	</div>
	<div class="seconds">
		<span class="fa-solid fa-chevron-up" (click)="incSeconds();"></span>
		<span>{{ ('0' + seconds).slice( -2 ) }}</span>
		<span class="fa-solid fa-chevron-down" (click)="decSeconds();"></span>
	</div>
	<div class="divider"></div>
	<div class="am-pm">
		<span class="fa-solid fa-chevron-up" (click)="togglePM();"></span>
		<span>{{ isPM ? 'PM' : 'AM' }}</span>
		<span class="fa-solid fa-chevron-down" (click)="togglePM();"></span>
	</div>
</div>
<div class="actions">
	<span class="button" *ngIf="canUnsetTime" (click)="removeTime();">Set to nothing</span>
	<span class="button primary" (click)="pickedTime();">Continue</span>
</div>
