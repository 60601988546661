<div id="page-login" class="page active-{{ activeForm }}">
	<div class="mask">
		<div class="mask-wrapper">
			<h2>
				<span class="sign-in-header">Welcome Back!</span>
				<span class="sign-up-header">Create An Account!</span>
			</h2>
			<div class="sub-heading">
				<span class="sign-in-blurb">Something, something, click the button below to sign in.</span>
				<span class="sign-up-blurb">Need to create your VenuePassport reservation account? Click the button below to get started.</span>
			</div>
			<span class="button">
				<b class="sign-in-button" (click)="showSignIn();">Sign In</b>
				<b class="sign-up-button" (click)="showSignUp();">Sign Up</b>
			</span>
		</div>
	</div>
	<div class="sign-in form-wrapper">
		<div class="form-inner">
			<!-- <img class="logo" alt="" src="assets/img/404.png" /> -->
			<div class="heading">Manage Your Account</div>
			<div class="sub-heading">Invite Family Members, manage your passes, and reserve dates.</div>
			<div>
				<label>
					<input type="text" autocomplete="off" placeholder="Email Address"
						[class.error]="signInErrors.email"
						[(ngModel)]="signInData.email"
						[ngModelOptions]="{ updateOn : 'blur' }"
						(ngModelChange)="validateSignInEmail();"
						(keyup.enter)="blur( $event ); signIn();"
						(focus)="clearSignInErrors();"
					/>
					<span class="frame-label"><i class="fa-solid fa-envelope"></i></span>
				</label>

				<label>
					<input type="password" autocomplete="off" placeholder="Password"
						[class.error]="signInErrors.password"
						[(ngModel)]="signInData.password"
						[ngModelOptions]="{ updateOn : 'blur' }"
						(ngModelChange)="validateSignInPassword();"
						(keyup.enter)="blur( $event ); signIn();"
						(focus)="clearSignInErrors();"
					/>
					<span class="frame-label"><i class="fa-solid fa-lock"></i></span>
				</label>
			</div>

			<div class="actions">
				<span class="button onwards" (click)="signIn();">Sign In</span>
				<span class="action"><a (click)="showForgotPassword();">Forgot your password?</a></span>
			</div>

			<div class="oh-noes" *ngIf="badCredentials">
				<div>Your email and password are incorrect.</div>
			</div>
		</div>
	</div>

	<div class="forgot-password form-wrapper">
		<div class="form-inner">
			<!-- <img class="logo" alt="" src="assets/img/404.png" /> -->
			<div class="heading">Reset Password</div>
			<div>
				<label>
					<input type="text" autocomplete="off" placeholder="Email Address"
						[class.error]="forgotPasswordErrors.email"
						[(ngModel)]="forgotPasswordData.email"
						[ngModelOptions]="{ updateOn : 'blur' }"
						(ngModelChange)="validateForgotPasswordEmail();"
						(keyup.enter)="blur( $event ); forgotPassword();"
					/>
					<span class="frame-label"><i class="fa-solid fa-envelope"></i></span>
				</label>
			</div>
			<br/>
			<div class="actions">
				<span class="button onwards" (click)="forgotPassword();">Submit</span>
				<span class="action"><a (click)="showSignIn();">Sign In instead?</a></span>
			</div>
		</div>
	</div>

	<div class="sign-up form-wrapper">
		<div class="heading">Create A Test Account</div>
		<div class="sub-heading">Something about creating an account so they can do things and stuff.</div>
		<div>
			<label class="half">
				<input type="text" autocomplete="off" placeholder="First Name"
					[class.error]="signUpErrors.firstName"
					[(ngModel)]="signUpData.firstName"
					[ngModelOptions]="{ updateOn : 'blur' }"
					(ngModelChange)="validateSignUpData( 'firstName' );"
					(focus)="clearSignUpError( 'firstName' );"
				/>
				<span class="frame-label"></span>
			</label>

			<label class="half">
				<input type="text" autocomplete="off" placeholder="Last Name"
					[class.error]="signUpErrors.lastName"
					[(ngModel)]="signUpData.lastName"
					[ngModelOptions]="{ updateOn : 'blur' }"
					(ngModelChange)="validateSignUpData( 'lastName' );"
					(focus)="clearSignUpError( 'lastName' );"
				/>
				<span class="frame-label"><i class="fa-solid fa-user-astronaut"></i></span>
			</label>

			<label>
				<input type="text" autocomplete="off" placeholder="Email Address"
					[class.error]="signUpErrors.email"
					[(ngModel)]="signUpData.email"
					[ngModelOptions]="{ updateOn : 'blur' }"
					(ngModelChange)="validateSignUpData( 'email' );"
					(focus)="clearSignUpError( 'email' );"
				/>
				<span class="frame-label"><i class="fa-solid fa-envelope"></i></span>
			</label>

			<label>
				<input type="password" autocomplete="off" placeholder="Password"
					[class.error]="signUpErrors.password1"
					[(ngModel)]="signUpData.password1"
					[ngModelOptions]="{ updateOn : 'blur' }"
					(ngModelChange)="validateSignUpData( 'password1' );"
					(focus)="clearSignUpError( 'password1' );"
				/>
				<span class="frame-label"><i class="fa-solid fa-lock"></i></span>
			</label>

			<label>
				<input type="password" autocomplete="off" placeholder="Retype Password"
					[class.error]="signUpErrors.password2"
					[(ngModel)]="signUpData.password2"
					[ngModelOptions]="{ updateOn : 'blur' }"
					(ngModelChange)="validateSignUpData( 'password2' );"
					(focus)="clearSignUpError( 'password2' );"
				/>
				<span class="frame-label"><i class="fa-solid fa-lock"></i></span>
			</label>
		</div>

		<div class="actions">
			<span class="button onwards" (click)="signUp();">Sign Up</span>
		</div>
	</div>
</div>
