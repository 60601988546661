import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceOWDoclet} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerTransactions} from '../transformers/transactions';
//
@Pipe( {
	name: 'transactionStatus',
	pure: true
} )
export class PipeTransactionStatus implements PipeTransform {
	public transform( transaction: InterfaceOWDoclet ): string {
		return TransformerTransactions.getStatus( transaction );
	}
}
