// ===== Interfaces ===== //
import {InterfaceObjectId} from './mongo/object-id';
import {TypeOWTemplateFields} from './primitives';
import {InterfaceAnyObject} from '../angular/vanilla-js';
//
export interface InterfaceOWFieldConfig {
	key: string;
	label: string;
	sort: number;
	type: TypeOWTemplateFields;
	default: any;
	readonly: boolean;
	enum?: string[];
	object_schema?: {
		[key: string]: TypeOWTemplateFields | InterfaceAnyObject;
	}; // the interface for when it's type: 'object' or type 'object_array'
}

export interface InterfaceOWTemplate {
	_id: InterfaceObjectId;
	name: string;
	fields: InterfaceOWFieldConfig[];
	tags: string[];
	group?: string;
	workspace: InterfaceObjectId[];
}
