<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
></app-masthead>
<div class="wrapper">
	<div class="group-containers" *ngIf="!groupWeaves.loaded">
		<span class="loading-groups" style="display: none">Loading groups...</span>
	</div>
	<div class="group-containers" *ngIf="groupWeaves.loaded">
		<div class="listing-of-groups" *ngFor="let group of groupWeaves.asOwner.groups;">
			<div class="group card" *ngIf="group.groupDoclet">
				<h3>{{ group.groupDoclet.data['name'] ?? group.groupDoclet.title ?? 'Unnamed Group' }} <span
					*ngIf="(group.groupDoclet.data['max_members'] ?? 0) > group.members.length"
					(click)="showInviteModal( group.groupDoclet ? group.groupDocletID : null );"
				>Invite Members</span></h3>
				<div class="group-members">
					<div
						*ngFor="let member of group.members;"
						[ngClass]="{
							'group-member': true,
							'invited': member.data['invited_status'] === 'pending'
						}"
					>
						<span style="display: none;">{{ member._id.$oid }}{{ member?.realm?.pid?.$oid ?? 'N/A' }}</span>
						<div class="member-photo">NA</div>
						<div class="member-name"><span class="first-name">{{ member.data['first_name'] }}</span>&nbsp;<span class="last-name">{{ member.data['last_name'] }}</span></div>
						<span class="action-menu">
							<i class="fa-solid fa-ellipsis"></i>
						</span>
					</div>
				</div>
				<div class="group-usage">
					<div class="header">
						<span>Group Members</span><b>{{ group.members.length }} / {{ group.groupDoclet.data['max_members'] }}</b>
					</div>
					<div
						[ngClass]="{
							'bar': true,
							'full': (group.groupDoclet.data['max_members'] ?? 0) <= group.members.length
						}"
					>
						<b [style.width]="((group.groupDoclet.data['max_members'] ?? 0) < 1) ? '100%' : ((group.members.length / (group.groupDoclet.data['max_members'] ?? 0)) * 100) + '%'"></b>
					</div>
				</div>
			</div>
		</div>
		<!-- needs a thing explaining these are the groups this person belongs in -->
		<div class="listing-of-groups" *ngFor="let group of groupWeaves.asMember.groups">
			<div class="group" *ngIf="group.groupDoclet">
				<div class="group-name">{{ group.groupDoclet.data['name'] ?? group.groupDoclet.title ?? ' Unnamed Group' }}</div>
			</div>
		</div>
	</div>
</div>
