// ===== Components ===== //
import {ComponentAppHeader} from './app-header/app-header';
import {ComponentAppFooter} from './app-footer/app-footer';
import {ComponentCalendarEmbed} from './calendar-embed/calendar-embed';
import {ComponentMasthead} from './masthead/masthead';
// ===== Modules ===== //
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ModulePipes} from '../../../../../ow-framework/pipes/pipes.module';
//
const components: any[] = [
	ComponentAppHeader,
	ComponentAppFooter,
	ComponentCalendarEmbed,
	ComponentMasthead
];
//
@NgModule( {
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ModulePipes
	],
	providers: []
} )
export class ModuleAppComponents {}
