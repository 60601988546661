import {InterfaceAnyObject} from '../angular/vanilla-js';
import {InterfaceOWDoclet} from '../ow/doclets';

export interface InterfaceTicketExchangeDateCompProps {
	boundaryStart: string | undefined;
	boundaryEnd: string | undefined;
	now: string;
	availabilityLoaded: boolean;
	enforceAvailabilityRules: boolean;
	enforceDateBoundaries: boolean;
}

export type TypeTicketExchange = 'spUpgrade' | 'seasonPasses' | 'admission' | 'parking' | 'cabana';

export interface InterfaceTicketExchangeNewTicket {
	date: string | undefined; // YYYY-MM-DD or "any"
	locationID?: string;
	seasonPassYear?: number;
	price: number | undefined;
	dataProps: InterfaceAnyObject | undefined;
	passDoclet: InterfaceOWDoclet | undefined;
	isAdmission: boolean;
	isCabana: boolean;
	isSeasonPass: boolean;
	isSPUpgrade: boolean;
}
