// ===== Interfaces ===== //
import {InterfaceObjectId} from '../ow/mongo/object-id';
import {InterfaceOWDocletEventPassTransformed} from './event-passes';
//
export interface InterfaceCartItemTicket { // obsolete
	passID: string; // doclet_id, not the template_id.
	qty: number; // qty needs to eventually match holds.length
	holds: string[]; // docletIDs of tickets that are temporarily held. the hold expires in 10 mins or so, but isn't required to have at check-out (unless stock is so low that it matters)
	location?: string; // cabanas have these. other things do not.
}

export interface InterfaceCartItemObsolete {
	date: {
		year: number;
		month: number; // 1 to 12 for normal tickets, 1 for season passes and 0 for any-day tickets.
		day: number; // 1 to 31 for normal tickets, 1 for season passes and 0 for any-day tickets.
	};
	tickets: InterfaceCartItemTicket[]; // array of tickets
	hasSAP?: boolean; // each ticket may be in SAP or SPP, but the array may contain neither/some/both...
	hasSPP?: boolean;
	isSeasonPass?: boolean;
	isAnyDay?: boolean;
	isCabana?: boolean;
	isComplexBundle?: boolean;
	discounted?: {
		amount: number;
	};
}

export interface InterfaceCompactCartItems { // obsolete
	d: { // date
		y: number; // year
		m: number; // month, 1 - 12
		d: number; // day
	};
	t: { // tickets
		i: string; // passID
		q: number; // quantity
		h: string[]; // held ticket IDs
		l?: string;
	}[];
}

export interface InterfaceCartData {
	passID: string;
	eventID: string;
	name: string;
	price: number;
	ticketID: string | null; // the reserved _id of the ticket, if any.
	spaceID: string | null; // the seating location ID "MickyMouse-G-22" or the location ID of a cabana, etc.
	visitDate: {
		year: number | null;
		month1: number | null; // month should be 1 to 12. but 0 is allowed for the special "any" date.
		day: number | null;
		isAnyDay?: boolean;
		isEventLength?: boolean;
	};
	entryTime: string | null; // HH:MM:SS // this will fail for multi-day passes with different entry times per day.
	consumer: {
		_id?: InterfaceObjectId;
		firstName: string | null;
		lastName: string | null;
		dob: string | null; // YYYY-MM-DD
		phone: string | null;
	};
	remove?: true; // if set, the service has a fn to pull these out of the array of items.
}

export interface InterfaceAddToCart {
	passForSale: InterfaceOWDocletEventPassTransformed;
	eventID: string;
	visitDate: InterfaceCartData['visitDate'];
	time: string | null; // HH:MM:SS
	ticketID: string | null;
	spaceID: string | null;
	price: number; // price is ultimately determined on server-side.
	consumerData: InterfaceCartData['consumer'];
}

export interface InterfaceCartSerialization {
	pid: string; // the pass ID.
	eid: string; // the event ID for the pass.
	n: string; // pass (ticket) name
	p: string; // price
	id: string | undefined; // the held ticket ID, if any.
	s: string | undefined; // the unique seating/location ID
	v: { // visit date and time
		d: {
			y: number | undefined; // YYYY
			m: number | undefined; // 1 - 12
			d: number | undefined; // 1 - 31, as well as 0 for "any" day.
			a: '0' | '1'; // is any day
			e: '0' | '1'; // is event-length
		};
		t: string | undefined; // HH:MM:SS
	};
	c: { // consumer properties
		id: string | undefined;
		f: string | undefined; // first name
		l: string | undefined; // last name
		d: string | undefined; // date of birth
		p: string | undefined; // phone
	};
}

export interface InterfaceCartDataChanged {
	type: 'added' | 'cleared' | 'removed';
	items?: InterfaceCartData[];
}
